import React from "react";
import Select from "react-select";
// @ts-ignore
import { customStyles, DropdownIndicator } from "../../../constants";
// @ts-ignore
import useAutoCloseOutSideClick from "../../atoms/useAutoCloseOutSideClick";
// @ts-ignore
import FleetNav from "../../user/FleetNav";
import { useSelector } from "react-redux";
import { getFleet } from "../../../reducers/fleet";



const FleetSelection = () => {
  const currentFleet = useSelector(getFleet);
  const { isOpen, setIsOpen, selectRef } = useAutoCloseOutSideClick(false);

  return (
    <>
      <div className="fleet-selection-container">
        <div className="dropdown-container">
          <div
            className="dropdown-label-container"
            onClick={() => setIsOpen((prev:any) => !prev)}
          >
            <div 
              className="dropdown-select"
              data-testid="fleet-selection"            
            >
              <Select
                styles={customStyles({ hidePlaceholder: true })}
                components={{ DropdownIndicator }}
                menuIsOpen={false}
              />
            </div>
            <div
              className="fleet-label"
              style={{
                transform: "translateY(-30px)",
                fontSize: "1rem",
                textTransform: "uppercase",
              }}
            >
              {currentFleet?.id} 
              {/* {includeAllSubfleets && " and subfleets"} */}
              {!currentFleet?.id && "Fleets not available"}
            </div>
          </div>
          <div
            className={"dropdown-content"}
            style={{ display: isOpen ? "block" : "none" }}
            ref={selectRef}
          >
            <FleetNav />
          </div>
        </div>
        <p>Change your fleet by using the drop-down menu.</p>
      </div>
    </>
  );
}

export default FleetSelection;
