import { createSlice } from '@reduxjs/toolkit'

import { IUser } from './interfaces';

export const userSlice = createSlice({
  name: 'user',
  initialState: {
    user: undefined,
    error: undefined,
    loading: true
  },
  reducers: {
   setUser(state, action) {
    state.user = action.payload;
   },
   setLoading(state, action) {
    state.loading = action.payload;
   },
   setError(state, action) {
    state.error = action.payload;
   }
  }
})

export const { setUser, setError, setLoading } = userSlice.actions

export default userSlice.reducer

export const getUser = (state:any):IUser => state.user.user;
export const getError = (state:any):any => state.user.error;
export const getLoading = (state:any):boolean => state.user.loading;
