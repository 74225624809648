import { createSlice } from '@reduxjs/toolkit'


export const modalSlice = createSlice({
  name: 'modals',
  initialState: {
    showHubMoveTool: false,
    location: undefined,
    callback: undefined,
  },
  reducers: {
   setModalLocation(state, action) {
    state.location = action.payload;
   },
   setCallback(state, action) {
    state.callback = action.payload;
   },
   setShowHubMoveTool(state, action) {
    state.showHubMoveTool = action.payload;
   }
  }
})

export const { setModalLocation, setShowHubMoveTool, setCallback } = modalSlice.actions

export default modalSlice.reducer

export const getShowMoveTool = (state:any):boolean => !!state.modals.showHubMoveTool;
export const getModalLocation = (state:any):any => state.modals.location;
export const getCallback = (state:any):any => state.modals.callback;

