function camelizeString(str, delimiter){
  delimiter = typeof delimiter !== 'undefined' ? delimiter : '_';
  return str.split(delimiter).reverse().reduce(
    (a,val,index) => {
      const next = index === 0 ? a : a.replace(/^./, a.charAt(0).toUpperCase());
      return `${val}${next}`;
    },
    ''
  );
}

function camelizeObject(o, delimiter){
  delimiter = typeof delimiter !== 'undefined' ? delimiter : '_';
  const r = {};
  Object.keys(o).forEach(key => {
    const camelizedKey = camelizeString(key, delimiter);
    r[camelizedKey] = r[camelizedKey] || o[key];
  });
  return r;
}

function decamelizeString(str, delimiter){
  delimiter = typeof delimiter !== 'undefined' ? delimiter : '_';
  return str.replace(/[A-Z]/g, (m) => `${delimiter}${m.toLowerCase()}`);
}

function decamelizeObject(o, delimiter){
  delimiter = typeof delimiter !== 'undefined' ? delimiter : '_';
  const r = {};
  Object.keys(o).forEach(key => {
    const decamelizedKey = decamelizeString(key, delimiter);
    r[decamelizedKey] = r[decamelizedKey] || o[key];
  });
  return r;
}

function slugify(str, delimiter='-'){
  return str.replace(/[^a-zA-Z0-9]/gi, delimiter);
}

function removeBracketAndDotNotation(str, dotDelimiter='-'){
  return str.replace(/\[.*\]/ig, '').replace(/\./, dotDelimiter);
}

const GUIDRegex = /[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}/

function isGUID(str=''){
  return GUIDRegex.test(str);
}

function generateRandomAlphaNumeric(targetLength=16){
  var pieces = [];
  var possible = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';

  while(pieces.length < targetLength){
    pieces.push(
      possible.charAt(Math.floor(Math.random() * possible.length))
    );
  }
  return pieces.join('');
}

function safeJSONParse(message){
  try {
    message = JSON.parse(message);
  } catch(_){}
  return message;
}

function safeJSONStringify(obj){
  if(typeof obj === 'object'){
    return JSON.stringify(obj);
  }
  return obj;
}

module.exports = {
  camelizeString,
  camelizeObject,
  decamelizeString,
  decamelizeObject,
  slugify,
  removeBracketAndDotNotation,
  GUIDRegex,
  isGUID,
  generateRandomAlphaNumeric,
  safeJSONParse,
  safeJSONStringify
};
