import React from 'react';
import StatusInline from '../atoms/status-inline';
import PrintError from '../atoms/print-error';
import withPage from './';

function ErrorPage(props){
  const error = {
    message: 'An unknown error has occurred',
    status: 500,
    ...props
  }
  const { status, stack } = error;
  return (    
    <div className="error-page basic-page">
      <h1>Error: { status }</h1>
      <StatusInline type="error">
        <PrintError error={error} pretty={true} />
      </StatusInline>
      { stack && (
        <p>{stack}</p>
      )}
    </div>
  )
}

export default withPage(ErrorPage);