import React from 'react';
import EnergyProjectionsContent from '../energy-projections/';
import EnergyProjections from '../../data-containers/energy-projections';
import withPage from './';

function EnergyProjectionsPage(props){
  return (
    <EnergyProjections.Provider initialState={props}>
      <EnergyProjectionsContent {...props} />
    </EnergyProjections.Provider>
  );
}

const Page = withPage(EnergyProjectionsPage);

export default function(props){
  return <Page className="energy-projections" pageTitle="Solar Assurance" {...props} />;
}