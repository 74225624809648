import React, {useContext} from 'react';
import { withRouter } from 'react-router-dom';
import { Form, Field } from 'react-final-form';
import CurbInput from '../../../../shared/components/atoms/form/input.jsx';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import AutoSave from '../../forms/autosave';
// import Explorer from '../../../data-containers/explorer';
import { minLength } from '../../../lib/validators';
import { useSelector, useDispatch } from 'react-redux';
// import { getSearchTerm, setSearchTerm } from '../../../reducers/dashboard';
import { getFleet } from '../../../reducers/fleet';

import searchContext from '../context';


const SEARCH_FIELD_NAME = 'q';
const MIN_QUERY_LENGTH = 2;

const ExplorerSearchForm = (props) => {
  const { searchTerm, setSearchTerm} = useContext(searchContext);
  // const searchTerm = useSelector(getSearchTerm);
  const fleet = useSelector(getFleet);
  const dispatch = useDispatch();
  const assignSearchTerm = (term) => {
    console.log("ASSIGNING SEARTCH TERM", term);
    setSearchTerm(term)
    // dispatch(setSearchTerm({ fleetId: fleet.id, searchTerm: term }));
  };


  const onSearchSubmit = async (params) => {
    try {
      let q = params[SEARCH_FIELD_NAME];
      if(!q || q.length >= MIN_QUERY_LENGTH){
        assignSearchTerm(q);
      }
    }
    catch(err) {
      console.error(err);
    }
  }

  return <Form
      onSubmit={onSearchSubmit}
      initialValues={{
        [SEARCH_FIELD_NAME]: searchTerm
      }}
      render={
        (renderProps) => {
          const { handleSubmit, form, values, invalid } = renderProps;
          return <form
            data-testid="search-form"
            className="curb-form"
            onSubmit={handleSubmit}>
            <div className="search-input-wrap">
              <FontAwesomeIcon
                className="icon search-icon"
                htmlFor={SEARCH_FIELD_NAME}
                icon="search" />
              <Field
                data-testid="search-input"
                autoFocus={false}
                name={SEARCH_FIELD_NAME}
                type="text"
                className="search"
                placeholder="Search"
                autoComplete="off"
                validate={minLength(MIN_QUERY_LENGTH)}
                render={CurbInput}
                initialValue={searchTerm} />
              { values[SEARCH_FIELD_NAME] && (
                <FontAwesomeIcon
                  icon="times-circle"
                  className="icon search-clear-icon"
                  data-testid="search-clear-icon"
                  onClick={
                    () => {
                      form.reset();
                      assignSearchTerm('');
                    }
                  } />
              )}
            </div>
            {/* <AutoSave
              invalid={invalid}
              keys={[SEARCH_FIELD_NAME]}
              interval={300}
              save={onSearchSubmit}
              values={values}
              render={() => {
                if(!busy){
                  return null;
                }
                return <span className="loading-spinner inline search-busy-indicator"></span>;
              }} /> */}
            {/* <p className="info">Search by location name, location street address, city, state, zip code, user name, email address, or CURB serial number.</p> */}
          </form>;
        }
      }
    />;
}

export default withRouter(ExplorerSearchForm);
