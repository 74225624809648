import { useRef, useEffect, useState } from "react";

function useAutoCloseOutSideClick({ initialState }) {
  const [isOpen, setIsOpen] = useState(initialState);
  const selectRef = useRef(null);

  function outSideClick(event) {
    if (!selectRef.current || selectRef.current?.contains(event.target)) {
      return;
    }
    setIsOpen(false);
  }

  useEffect(() => {
    if (isOpen) {
      document.addEventListener("click", outSideClick);
      return () => document.removeEventListener("click", outSideClick);
    }
  }, [isOpen]);
  return { isOpen, setIsOpen, selectRef };
}

export default useAutoCloseOutSideClick;
