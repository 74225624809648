import React from 'react';
import { useSelector } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
// import Fleets from '../../data-containers/fleets';
import { getFleet } from '../../reducers/fleet';

function FleetLink({fleetId, location, className}){
  const classes = ['fleet-link'];
  if(className){
    classes.push(className);
  }

  const {id} = useSelector(getFleet);

  const getUrl = f => {
    return location.pathname.replace(
      new RegExp(`^/${id}/(all/?)*`, 'ig'),
      `/${f}/`
    );
  }
  return <Link 
    className={classes.join(' ')} 
    title={fleetId} 
    to={getUrl(fleetId)}>
      {fleetId}
    </Link>;
}

export default withRouter(FleetLink);