import { createSlice } from '@reduxjs/toolkit'

import { IUser } from './interfaces';


export interface IUsers {
    [key:string]: IUser
}

const users:IUsers = {};

export const usersSlice = createSlice({
    name: 'users',
    initialState: {
      users
    },
    reducers: {
      setUser(state, action) {
          state.users[action.payload.id] = action.payload;
      }
    }
  })

  export const { setUser } = usersSlice.actions;


export default usersSlice.reducer

export const getUsers = (state:any):IUser[] => Object.values(state.users.users);
