import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import AccountInfoModal from "../modals/account-info";
import ModalManager from "../atoms/modal-manager";
import CurbLogoSrc from "../../../img/curb-logo.png";
import Arrow from "../../../img/arrow-down.png";

import PasswordResetForm from "../forms/password-reset";
import useAutoCloseOutSideClick from "../atoms/useAutoCloseOutSideClick";

const ResetPasswordButton = function ({ close }) {
  const passwordModalProps = {
    className: "change-password-modal",
    title: "RESET PASSWORD",
    render: (modalProps) => <PasswordResetForm {...modalProps} />,
  };
  return (
    <ModalManager
      render={() => <div className="pass-reset">RESET PASSWORD</div>}
      modalProps={passwordModalProps}
      close={close}
    />
  );
};

export default function (props) {
  const { user } = useSelector(state => state.user);
  const { fleet: currentFleet } = useSelector(state => state.fleet);

  // const { includeAllSubfleets } = URLState.useContainer();
  const includeAllSubfleets = false;

  const { pageTitle = "Fleet Administrator" } = props;
  const { isOpen, setIsOpen, selectRef } = useAutoCloseOutSideClick(false);

  const userMenuModalProps = {
    className: "account-info-modal",
    title: "Account info",
    render: function (modalProps) {
      return <AccountInfoModal {...modalProps} />;
    },
  };

  const logoutModalProps = {
    className: "logout-modal",
    render: function ({ close }) {
      return (
        <div>
          <p>Are you sure you want to log out?</p>
          <div className="curb-submit button-list">
            <button data-testid="cancel-logout" onClick={close}>No, return to app</button>
            <Link data-testid="confirm-logout" className="logout button irreversible" to="/logout">
              Yes, Logout
            </Link>
          </div>
        </div>
      );
    },
  };

  return (
    <header className="app-header primary-section">
      <div className="curb-logo-container">
        <img src={CurbLogoSrc} className="curb-logo" title="Curb" alt="Curb" />
        <h2>{pageTitle}</h2>
      </div>
      <nav className="user-nav">
        {isOpen ? (
          <div className="pass-reset-container" ref={selectRef}>
            <div className="pass-reset-main">
              <ResetPasswordButton close={() => setIsOpen(false)} />
              <span className="verticle-pipe">|</span>
              <div>
                <ModalManager
                  render={() => <div data-testid="header-logout-button" className="logout">LOG OUT</div>}
                  modalProps={logoutModalProps}
                  close={() => setIsOpen(false)}
                />
              </div>
            </div>
          </div>
        ) : (
          <>
            <div className="user-menu-link-main">
              {user && (
                <ModalManager
                  render={() => (
                    <span className="link user-menu-link" data-testid="user-email-address" >{user?.email_address}</span>
                  )}
                  modalProps={userMenuModalProps}
                />
              )}{" "}
            </div>
            <div>
              <span className="verticle-pipe">|</span>
              <span className="header-fleet-dropdown">
                <span className="header-fleet-name" data-testid="header-current-fleet-id">
                  {currentFleet?.id} {includeAllSubfleets && "and subfleets"}
                </span>
              </span>
            </div>
          </>
        )}
        <img
          src={Arrow}
          className="arrow-down"
          title="Arrow"
          alt="Arrow"
          data-testid="user-menu-arrow"
          onClick={() => setIsOpen((prevState) => !prevState)}
        />
      </nav>
    </header>
  );
}
