import React, { useState } from 'react';
import { Form, Field } from 'react-final-form';
import { email as emailValid } from '../../../shared/utils/validators';
import CurbInput from '../../../shared/components/atoms/form/input.jsx';
import LoadingSpinner from '../atoms/loading-spinner';
import * as API from '../../lib/api';

const emailValidator = v => {
  const { error } = emailValid(v, { required: true });
  if(error){
    return error;
  }
}

export default function(props){
  const { location, fleetId, refetchLocation } = props;
  const [ busy, setBusy ] = useState(null);
  const [ userEmail, setUserEmail ] = useState(null);
  const [ success, setSuccess ] = useState(null);
  const [ error, setError ] = useState(null);
  const [ mode, setMode ] = useState(null);
  const classes = ['add-user-modal-content'];

  if(busy){
    classes.push('busy');
  }

  const onSubmit = async ({email}) => {
    try {
      setError(null);
      setUserEmail(email);
      setBusy(true);
      setSuccess(null);
      let newUser = null;
      try {
        newUser = await API.fetchUserByEmailAddress(email);
      }
      catch(err){
        // could not find user with email
      }

      if(newUser){
        // user exists, grant access
        setMode('confirmGrantAccess');
      }
      else {
        // user does not exist, send claim
        setMode('confirmSendClaim');
      }
    }
    catch(err) {
      console.error(err);
      setError(err);
    }
    setBusy(false);  
  };

  const renderForm = () => <Form
    onSubmit={onSubmit}
    className="curb-form"
    initialValues={{ email: userEmail }}
    render={
      ({form, handleSubmit}) => {
        const classes = ['curb-form', 'add-user-form'];
        if(busy){
          classes.push('busy');
        }
        return <React.Fragment>
          <h2>Add user to {location.label}</h2>
          <form
            data-testid="add-user-form"
            className={classes.join(' ')}
            onSubmit={handleSubmit}>
            <Field
              data-testid="add-user-email"
              name="email"
              type="email"
              className="add-user-field"
              placeholder="Email address"
              autoComplete="off"
              validate={emailValidator}
              render={CurbInput} />
            <div className="curb-form-element">
              <button data-testid="add-user" type="submit">Add user</button>
            </div>
            { busy && <div className="curb-form-element">
              <LoadingSpinner className="inline" />
            </div> }
          </form> 
          { false && <p className="status-message success">A claim email has been sent to {userEmail}.</p> }
        </React.Fragment>
      }
    } />;

  const renderConfirmGrantAccess = () => {
    const onClickConfirm = async e => {
      e.preventDefault();
      try {
        setBusy(true);
        setError(null);
        setSuccess(null);
        await API.assignUserLocationByEmail(location.uuid, userEmail);
        refetchLocation(location.uuid);
        setSuccess(`"${userEmail}" has been assigned "${location.label}"`);
        setMode(null);
      }
      catch(err){
        setError(err);
        console.error(err);
      }
      setBusy(false);
    };

    return <React.Fragment>
      <p>Give "{userEmail}" access to "{location.label}"?</p>
      <div className="modal-buttons button-list">
        { busy && <LoadingSpinner className="inline" />}
        <button className="passive" data-testid="cancel-give-access" onClick={() => setMode(null)}>Cancel</button>
        <button className="irreversible" data-testid="confirm-give-access" onClick={onClickConfirm}>Confirm</button>
      </div>
    </React.Fragment>;
  }

  const renderConfirmSendClaim = () => {
    const onClickConfirm = async e => {
      e.preventDefault();
      try {
        setBusy(true);
        setError(null);
        setSuccess(null);
        await API.createClaim(userEmail, location.uuid);
        refetchLocation(location.uuid);
        setSuccess(`A claim email has been sent to "${userEmail}" for "${location.label}"`);
        setMode(null);
      }
      catch(err){
        setError(err);
        console.error(err);
      }
      setBusy(false);
    };

    return <React.Fragment>
      <p>An account does not exist for "{userEmail}." Send a claim link to "{location.label}"?</p>
      <div className="modal-buttons button-list">
        { busy && <LoadingSpinner className="inline" />}
        <button className="passive" data-testid="cancel-send-claim" onClick={() => setMode(null)}>Cancel</button>
        <button className="irreversible" data-testid="confirm-send-claim" onClick={onClickConfirm}>Continue</button>
      </div>
    </React.Fragment>;
  }

  const MainContent = () => {
    switch(mode){
      case 'confirmGrantAccess':
        return renderConfirmGrantAccess();
      case 'confirmSendClaim':
        return renderConfirmSendClaim();
      default:
        return renderForm();
    }  
  };

  return <div className={classes.join(' ')}>
    { MainContent() }
    { error && <p className="status-message error">{
      typeof error.message === 'string'
        ? error.message
        : "there was an error"
    }</p> }
    { success && <p className="status-message success">{success}</p> }
  </div>

}