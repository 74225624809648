import React, { useState } from 'react';
import LoadingSpinner from '../../../../atoms/loading-spinner';
import URLState from '../../../../../data-containers/url-state';
import CONFIG from '../../../../../lib/config';
import * as API from '../../../../../lib/api';
import ErrorWithTooltip from '../../../../atoms/error-with-tooltip';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Modal from '../../../../atoms/modal';

export default function(props){
  const { className, children, userId, disabled, loadingSpinnerClassName="inline full", params={} } = props;
  const { DEMO } = CONFIG.values;
  const { fleetId } = URLState.useContainer();
  const [ loading, setLoading ] = useState(null);
  const [ error, setError ] = useState(null);
  const [ impersonationUrl, setImpersonationUrl ] = useState(null);

  const classes = [className];
  if(loading){
    classes.push('loading');
  }

  const impersonationUserId = DEMO
    ? 'auth0|5901263ed786a21a6b69d59c'
    : userId;

  const onClick = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);
      setError(null);
      setImpersonationUrl(null);
      const { url } = await API.impersonate(fleetId, impersonationUserId, params);
      let win = window.open(url, '_blank');
      if(win){
        win.focus();
      }
      else {
        setImpersonationUrl(url);
      }
    }
    catch(err){
      setError(err);
    }
    setLoading(false);    
  };
  return <React.Fragment>
    <a onClick={onClick} target="_blank" rel="noopener noreferrer" disabled={disabled || loading} className={classes.filter(c => c).join(' ')}>
      { children }
      { loading && <LoadingSpinner className={loadingSpinnerClassName} /> }
      { error && <ErrorWithTooltip error={error} /> }    
    </a>
    { impersonationUrl && <Modal
        render={
          ({close}) => {
            const dismiss = function(){
              setImpersonationUrl(null);
              close();
            }
            return <React.Fragment>
              <h2>Impersonate this user in a new tab?</h2>
              <div className="button-list curb-submit"> 
                <button className="passive" onClick={dismiss}>Cancel</button>
                <a 
                  href={impersonationUrl} 
                  target="_blank"
                  rel="noopener noreferrer"
                  className="button" 
                  onClick={dismiss}>Yes, impersonate&nbsp; <FontAwesomeIcon icon="external-link-alt" /></a>
              </div>
            </React.Fragment>            
          }
        } />
    }
  </React.Fragment>;
}