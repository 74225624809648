
import React from "react";
import GooglePlacesAutocomplete from 'react-google-places-autocomplete';

const PlacesAutocomplete = ({setGoogleSearchResult}:{setGoogleSearchResult:Function}) => {
    const [value, updateValue] = React.useState(null);
    const setValue = (value:any) => {
      setGoogleSearchResult(value);
      updateValue(value);
    }
  
    return (
      <div>
        <GooglePlacesAutocomplete
          apiKey={process.env.REACT_APP_TIMEZONE_API_KEY}
          selectProps={{
            value,
            onChange: setValue,
          }}
        />
      </div>
    );
  }

  export default PlacesAutocomplete;
  