// import URL from 'url';
const URL = window.URL || window.webkitURL;

if(typeof URL === 'undefined'){
    global.URL = URL.URL;
}
  
  function getURL(url, base){
    if(!url && typeof window !== 'undefined'){
      url = window.location.href;
    }
    if(!base && typeof window !== 'undefined'){
      base = window.location.origin;
    }
    return url.indexOf('http') === 0
      ? new URL(url)
      : new URL(url, base);
  }
  
  function getQueryString(url, base){
    return getURL(url, base).search;
  }
  
  function parseQueryString(qs){
    if(typeof qs !== 'string') return {};
    qs = qs.replace(/^\?/, '');
    return qs.split('&').reduce(function (prev, curr) {
      var param = curr.split('=');
      prev[param[0]] = decodeURIComponent(param[1]);
      return prev;
    }, {});
  }
  
  function getQueryStringParams(url, base){
    return parseQueryString(getQueryString(url, base));
  }
  
  function serialize(obj){
    if(!obj) return;
    return Object.keys(obj).reduce(
      (acc, key, i) => {
        if(i > 0){
          acc = `${acc}&`;
        }
        acc = `${acc}${key}`;
        if(typeof obj[key] !== 'undefined'){
          acc = `${acc}=${obj[key]}`;
        }
        return acc;
      },
      ''
    );
  }
  
  function toQueryString(obj){
    const serialized = serialize(obj);
    return serialized ? `?${serialized}` : '';
  }
  
  // including qs and hash
  function getFullPath(url=window.location.href, base=window.location.origin){
    if(typeof url === 'string'){
      url = new URL(url, base);
    }
    return url.toString().replace(new RegExp(`^(${url.origin}|about:blank)`),'');
  }
  
  function addParamsToUrl(params={}, url=window.location.href){
    const u = new URL(url.toString());
    for(const [k, v] of Object.entries(params)){
      if(v === null){
        u.searchParams.delete(k);
      }
      else {
        u.searchParams.set(k, v);
      }
    }
    return u;
  }
  

  export default {
    getURL,
    getQueryString,
    parseQueryString,
    getQueryStringParams,
    serialize,
    toQueryString,
    getFullPath,
    addParamsToUrl
  };
  export {
    getURL,
    getQueryString,
    parseQueryString,
    getQueryStringParams,
    serialize,
    toQueryString,
    getFullPath,
    addParamsToUrl
  };