export const checkEnvVars = () => {
    const errors:Error[] = [];
    if(!process.env.REACT_APP_USERS_CONNECTION_NAME) {
      errors.push(new Error('REACT APP USERS CONNECTION NAME not set.'));
    }
    if(!process.env.REACT_APP_FLEETADMIN_CLIENT_ID) {
      errors.push(new Error('Auth0 Client ID not set.'));
    }
    if(!process.env.REACT_APP_AUTH_DOMAIN) {
      errors.push(new Error('Auth0 App Domain not set.'));
    }
    if(!process.env.REACT_APP_PUBLIC_API_IDENTIFIER) {
      errors.push(new Error('Auth0 API Audience not set.'));
    }
    if(!process.env.REACT_APP_PUBLIC_API_ROOT) {
      errors.push(new Error('Hardware Config API Root not set.'));
    }
    if(!process.env.REACT_APP_WEBAPP_URL) {
      errors.push(new Error('Web App URL not set.'));
    }
    if(!process.env.REACT_APP_HUBAPP_URL) {
      errors.push(new Error('Hub App URL not set.'));
    }
    if(!process.env.REACT_APP_TIMEZONE_API_KEY) {
      errors.push(new Error('TIMEZONE_API_KEY not set.'));
    }
    if(errors.length>0) {
      throw new AggregateError(errors, errors.reduce((acc,cur) => {
        acc+=`${cur.message}; `;
        return acc;
      },''));
    }
  }
