import React from 'react';
import TooltipManager from './tooltip-manager';
import PrintError from './print-error';
import StatusInline from './status-inline';

export default function(props){
  const { error, className } = props;
  if(!error){
    return null;
  }

  const classes = ['error-with-tooltip error'];
  if(className){
    classes.push(className);
  }

  return <TooltipManager
    render={
      () => <StatusInline type="error" />
    }
    tooltipProps={{
      className: 'tooltip-error',
      render: () => {
        return <div><PrintError error={error} /></div>
      }
    }}
    {...props}
    className={classes.join(' ')} />;
}