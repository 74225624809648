import React, { Component } from 'react';
import { Field } from 'react-final-form';

export default function(props) {
  const { className, type='radio', disabled, name, label, options } = props;

  const fieldProps = {
    type,
    name,
    disabled
  }

  const classes = [`radio-group-${name}`, 'curb-radio-group', 'curb-form-element'];
  if(className){
    classes.push(className);
  }

  return <div className={classes.join(' ')}>
    { label && <label>{label}</label>}
    <div className="options-wrapper">
      {options.map(
        ({label, value }, index) => {

          const key = `${name}[${index}]`;
          const classes = ['curb-radio', 'curb-group-item', `curb-radio-item-${name}-${value}`];

          return (
            <div className={classes.join(' ')} key={key}>
              <label>
                <Field
                component="input"
                {...fieldProps}
                value={value} />
                <div className="label-content">
                  {label}
                </div>
              </label>
            </div>
          );
        }
      )}
    </div>    
  </div>
}