import React, { useState } from 'react';
import { Form, Field } from 'react-final-form';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import LoadingSpinner from '../atoms/loading-spinner';
import countries from '../../../shared/lib/countries';
import USStates from '../../../shared/lib/us-states';
import { validPostcode } from '../../../shared/utils/validators';
import CurbInput from '../../../shared/components/atoms/form/input.jsx';
import CurbSelect from '../../../shared/components/atoms/form/select.jsx';
import AutoSave from '../forms/autosave';
import Explorer from '../../data-containers/explorer';
import StatusInline from '../atoms/status-inline';
import PrintError from '../atoms/print-error';

export default function({ location }){
  const { updateLocation } = Explorer.useContainer();
  const [ success, setSuccess ] = useState(null);
  const [ isSubmitting, setSubmitting ] = useState(null);
  const [ error, setError ] = useState(null);

  const classes = ['curb-form', 'location-form', 'energy-projections-associated-form'];

  const onSubmit = async l => {
    try {
      setSuccess(null);
      setError(null);
      setSubmitting(true);
      await updateLocation(location.id, l);
      setSuccess(true);
    }
    catch(err) {
      console.error(err);
      setError(err.message);
    }
    setSubmitting(false);
  }

  return <Form
    initialValues={location}
    keepDirtyOnReinitialize={true}
    onSubmit={onSubmit}
    render={
      (renderProps) => {
        const { handleSubmit, form, values, invalid, pristine } = renderProps;  

        return <form 
          className={classes.join(' ')} 
          onSubmit={handleSubmit}>
          <Field
            label="Location Name"
            name="label"
            type="text"
            render={CurbInput} />
          <Field
            label="Address"
            name="address"
            type="text"
            render={CurbInput} />
          <Field
            label="City"
            name="city"
            type="text"
            render={CurbInput} />
          <div className="curb-form-element address-row-2">
            <Field
              label="State"
              name="state"
              type="text"
              render={CurbSelect}>
              <option value="">Not specified</option>
              {
                Object.keys(USStates).map(
                  code => (
                    <option key={code} value={code}>{USStates[code]}</option>
                  )
                )
              }
            </Field>
            <Field
              label="Zip"
              name="postcode"
              type="text"
              validate={validPostcode(values.country)}
              render={CurbInput} />
          </div>
          <div className="curb-form-element">
            <Field
              name="country"
              type="select"
              label="Country"
              component={CurbSelect}>
              <option disabled value="">Not specified</option>
              {countries.map(country => {
                return (
                  <option key={country.iso3} value={country.iso3}>{country.name}</option>
                );
              })}
            </Field>
          </div>

          <div className="curb-form-element curb-submit">
            &nbsp;
            <AutoSave
              invalid={invalid || pristine}
              interval={300}
              save={
                async values => {
                  await onSubmit(values);
                  form.initialize(values);
                }
              }
              values={values}
              render={() => {
                if(!isSubmitting){
                  return null;
                }
                return <LoadingSpinner className="inline" />;
              }} />
              {
                (pristine && success) && <FontAwesomeIcon icon="check" className="success" /> 
              }
              {
                error && <StatusInline type="error">
                  <PrintError error={error} />
                </StatusInline>
              }
          </div>

        </form>
      }
    }    
    />
}