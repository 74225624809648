import React from "react";
// import TabSummary from "./TabSummary";
import FleetSelection from "./FleetSelection";

function FleetSelectionAndTabSummary() {
  return (
    <section className="fleet-selection-and-tab-summary-container">
      <section>
        {/* // @ts-ignore */}
        <FleetSelection />
      </section>
      {/* <section>
        <TabSummary />
      </section> */}
    </section>
  );
}

export default FleetSelectionAndTabSummary;
