import React, { useState } from "react";
import LoadingSpinner from '../atoms/loading-spinner';



import { updateHubLocation, searchFleetHubIdsForLocations, searchHubs } from "../../lib/api";
import { useSelector, useDispatch } from 'react-redux';
import { getCallback, getModalLocation, getShowMoveTool,setShowHubMoveTool } from "../../reducers/modals";

import { getFleet } from "../../reducers/fleet";

const processState = {
    idle: 'idle',
    searchingHubs: 'searchingHubs',
    searchingLocations: 'searchingLocations',
    locationExists: 'locationExists',
    locationDoesNotExist: 'locationDoesNotExist',
    hubNotFound: 'hubNotFound',
    error: 'error',
    updatingHub: 'updatingHub',
    success: 'success',
    chooseHub: 'chooseHub',
    chooseLocation: 'chooseLocation'
}

const MoveHubTool = () => {
    const dispatch = useDispatch();
    const currentFleet = useSelector(getFleet);
    const b:string[] = [];
    const [listOfHubs, setListOfHubs] = useState(b);
    
    const [oldLocation, setOldLocation] = React.useState({
        address: '',
        city: '',
        state: '',
        postal_code: '',
        fleet:'curb',
        label: ''
    });

    const [process, updateProcess] = React.useState(processState.idle);
    const [hubId, setHubId] = React.useState('');
    // @ts-ignore
    const location = useSelector(getModalLocation);
    // @ts-ignore
    const showHubMoveTool  = useSelector(getShowMoveTool);
    const refetchLocation = useSelector(getCallback);
    if(!showHubMoveTool) return null;

    const searchForHubs = async () => {
        updateProcess(processState.searchingHubs);
        console.log("Searching for hubs", hubId);  
        // @ts-ignore
        const hubs:string[] = await searchHubs(hubId);
        if(hubs) {
            setListOfHubs(hubs);
            updateProcess(processState.chooseHub);
        } else {
            updateProcess(processState.hubNotFound);
        }
    };

    const searchForHubLocation = async () => {
        updateProcess(processState.searchingLocations);
        console.log("Searching for hub location", hubId, JSON.stringify(currentFleet));
        const locations = await searchFleetHubIdsForLocations(currentFleet.uuid, hubId, 2, 0);
        // @ts-ignore
        if(locations.length>1) {
            await searchForHubs();
            
        } else if(locations) {
            console.log("Location found", locations);
            // @ts-ignore
            const loc = locations[0];
            if(loc) {
                // @ts-ignore
                setOldLocation(loc);
                updateProcess(processState.locationExists);
            } else {
                updateProcess(processState.locationDoesNotExist);
            }
            updateProcess(processState.locationExists);
        } else {
            updateProcess(processState.locationDoesNotExist);
        }
    }

    const clickUpdateHubLocation = async () => {
        updateProcess(processState.updatingHub);
        const update = await updateHubLocation(hubId, location.uuid);
        if(update) {
            // and refresh the data locally
            updateProcess(processState.success);
            refetchLocation(location.uuid);
            // @ts-ignore
            refetchLocation(oldLocation.uuid);
            setTimeout(()=> {
                setHubId('');
                updateProcess(processState.idle);
                dispatch(setShowHubMoveTool(false))
            }, 2000);
        } else {
            console.error("Error in updating hub location");
        }
    }
    return (
        <div className="generic-modal-wrapper">
            <div className="generic-modal">
                <button className="generic-modal-close" onClick={() => {
                    setHubId('');
                    updateProcess(processState.idle);
                    dispatch(setShowHubMoveTool(false))
                }}>X</button>
                <div className="generic-modal-header">
                    <h2>Move Hub Tool</h2>
                </div>
                <div className="hub-search">            
                    {process === processState.idle && (
                    <div style={{width: '100%', display:'flex', flexDirection:'column'}}>
                        <h3>Enter Hub ID</h3>
                        <input data-testid="hub-id-input" type="text" placeholder="Hub ID" onChange={(e) => {
                            // @ts-ignore
                            setHubId(e.target.value);
                            // @ts-ignore
                            console.log(e.target.value);
                            }}
                        />
                        <p></p>
                        {hubId.length<=1 || hubId.length>10 && <button style={{background:"#959595"}} onClick={() => void(0)}>Enter Hub ID</button>}
                        {hubId.length>1 && hubId.length<10 && <button data-testid="hub-id-submit" onClick={(e) => searchForHubLocation()}>Begin</button>}
                    </div>
                    )}
                    {process === processState.searchingLocations && (
                    <div style={{width: '100%', display:'flex', flexDirection:'column'}}>
                        <h3>Enter Hub ID</h3>
                        <input type="text" placeholder="Hub ID" />
                        <LoadingSpinner className={["inline"]} />
                        <button disabled style={{background:"#959595"}} onClick={void(0)}>Searching Locations</button>
                    </div>
                    )}
                    {process === processState.searchingHubs && (
                        <div style={{width: '100%', display:'flex', flexDirection:'column'}}>
                        <h3>Enter Hub ID</h3>
                        <input type="text" placeholder="Hub ID" />
                        <LoadingSpinner className={["inline"]} />
                        <button disabled style={{background:"#959595"}} onClick={void(0)}>Searching Hubs</button>
                    </div>
                    )}
                    {process === processState.chooseHub && (
                    <div style={{width: '100%', display:'flex', flexDirection:'column'}}>
                        <h3>Choose Hub</h3>
                        <ul className="hubListManager">
                            {listOfHubs.map((hub) => (
                                <li className="hublist" data-testid={`${hub}-select`} key={hub} onClick={() => {
                                    setHubId(hub);
                                    searchForHubLocation();
                                }}>{hub}</li>
                            ))}
                        </ul>
                    </div>
                    )}
                    {process === processState.locationExists && (
                    <div style={{width: '100%', display:'flex', flexDirection:'column'}}>
                        <p>Move Hub from:<br />
                        {oldLocation.label||oldLocation.address}</p>
                        <p>to:</p>
                        <p>{location.label||oldLocation.address}</p>
                        <button data-testid="confirm-move-hub" onClick={clickUpdateHubLocation}>Confirm</button>
                    </div>
                    )}
                    {process === processState.updatingHub && (
                      <div style={{width: '100%', display:'flex', flexDirection:'column'}}>
                        <p>Move Hub from:<br />
                        {oldLocation.label||oldLocation.address}</p>
                        <p>to:</p>
                        <p>{location.label||oldLocation.address}</p>
                        <button style={{background:"#959595"}} onClick={void(0)}>Updating</button>
                      </div>
                    )}
                    {process === processState.success && (
                      <div style={{width: '100%', display:'flex', flexDirection:'column'}}>
                        <h3>Success!</h3>
                      </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default MoveHubTool;
