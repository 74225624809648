import React from 'react';

export default function(props){
  const {
    type='text',
    label,
    id,
    input,
    meta,
    disabled,
    //
    multiple,
    activeError
  } = props;

  const { name } = input;

  const additionalSelectProps = {
    disabled,
    multiple
  };

  const hasError = (meta.touched || activeError) && meta.error;
  let { className } = props;

  if(!className){
    className = `select-${name}`;
  }
  if(!Array.isArray(className)){
    className = [className];
  }
  if(hasError){
    className.push('error');
  }
  className = className.concat(['curb-select', 'curb-form-element']);

  return (
    <div className={className.join(' ')}>
      { label && (
        <label htmlFor={id}>{label}</label>
      )}
      <div className="select-wrap">
        <select
          {...input}
          {...additionalSelectProps}>
          {props.children}
        </select>
      </div>
      {hasError && <div className="error-msg">{meta.error}</div>}
    </div>
  );
}
