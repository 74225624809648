import React, { Component } from 'react';
import Tooltip from '../../../shared/components/atoms/Tooltip';
import eventEmitter from '../../lib/event-emitter';

/* manages the state for a tooltip ( show/hide and target ) */
export default class TooltipManager extends Component {
  constructor(props){
    super(props);
    this.state = {
      show: false,
      sticky: false,
      target: null,
      ...(props.initialState || {})
    }

    this.open = this.open.bind(this);
    this.close = this.close.bind(this);
    this.toggle = this.toggle.bind(this);

    this.container = React.createRef();
    this.tooltip = React.createRef();
  }

  componentDidMount(){
    const { behavior='hover', delay=200, initialState={}, initHook } = this.props;

    // trigger opening and showing based on behavior
    switch(behavior){
      case 'hover':
        this.container.current.addEventListener(
          'mouseenter',
          () => {
            this._isHovering = true;
            this.open();
          }
        );
        this.container.current.addEventListener(
          'mouseleave',
          () => {
            const closeIfNotHovering = () => {
              setTimeout(
                () => {
                  if(!this._isHovering){
                    this.close();
                  }
                },
                delay
              );
            };
            const tooltipMouseEnterHandler = () => {
              this._isHovering = true;
            };
            const tooltipMouseLeaveHandler = () => {
              this._isHovering = false;
              closeIfNotHovering();
            };
            if(this.tooltip.current && this.tooltip.current.container.current){
              this.tooltip.current.container.current.addEventListener('mouseenter', tooltipMouseEnterHandler);
              this.tooltip.current.container.current.addEventListener('mouseleave', tooltipMouseLeaveHandler);
            }
            this._isHovering = false;
            closeIfNotHovering();
          }
        );
        break;
      case 'click':
        this.container.current.addEventListener('click', e => {
          this.toggle();
          e.stopPropagation();
        });
        break;
    }

    // this.bindPageEvents();

    // set target to DOM root of this component if not specified in initialState
    if(!initialState.target){
      this.setState({
        target: this.container.current
      });
    }

    // do something custom here if you like
    if(typeof initHook === 'function'){
      initHook.call(this)
    }

  }

  componentWillUnmount(){
    this.unbindPageEvents();
  }

  open(){
    this.setState({
      target: this.container.current,
      show: true
    });
  }

  close(){
    this.setState({
      show: false
    });
  }

  toggle(){
    this.setState({
      target: this.container.current,
      show: !this.state.show
    });
  }

  componentDidUpdate(){
    if(this.state.show){
      this.bindPageEvents();
    }
    else {
      this.unbindPageEvents();
    }
  }

  bindPageEvents(){
    // document level events
    this.onClick = () => {
      try {
        if(!this.state.sticky){
          this.close();
        }
      }
      catch(err) {
        console.error(err);
      }
    }
    document.addEventListener('click', this.onClick);

    this.onTouchStart = () => { this.documentTap = true; };
    document.addEventListener('touchstart', this.onTouchStart);
    this.onTouchMove = () => { this.documentTap = true; };
    document.addEventListener('touchmove', this.onTouchMove);
    this.onTouchEnd = () => {
      if(this.documentTap){
        this.close();
      }
    };
    document.addEventListener('touchend', this.onTouchEnd);
    window.addEventListener('orientationchange', this.close);
  }

  unbindPageEvents(){
    document.removeEventListener('click', this.onClick);
    document.removeEventListener('touchstart', this.onTouchStart);
    document.removeEventListener('touchmove', this.onTouchMove);
    document.removeEventListener('touchend', this.onTouchEnd);
    window.removeEventListener('orientationchange', this.close);
  }

  render() {
    const { className, render, tooltipProps } = this.props;

    const classes = [
      'tooltip-trigger',
      this.state.show ? 'tooltip-open' : 'tooltip-closed'
    ];

    if(className){
      classes.push(className);
    }

    return (
      <div
        ref={this.container}
        className={classes.join(' ')}>
        { render(this.props, this.state) }
        <Tooltip
          ref={this.tooltip}
          initialState={{
            ...this.state,
            margin: 15,
            className
          }}
          eventEmitter={eventEmitter}
          {...tooltipProps}  />
      </div>
    );

  }
}
