import React from "react";

 export const processState = {
    idle: 'idle',
    gettingDetailsFromMapService: 'gettingDetailsFromMapService',
    checkingIfAddressExists: 'checkingIfAddressExists',
    addressDoesNotExist: 'addressDoesNotExist',
    addressExistsAlready: 'addressExistsAlready',
    error: 'error',
    postingLocation: 'postingLocation'

}

export default ({status}:{status:string}) =>{
    switch(status){
        case processState.idle:
            return <div className="add-location-status green"><small>Ready</small></div>;
        case processState.gettingDetailsFromMapService:
            return <div className="add-location-status gray"><small>Getting details from Map Service</small></div>;
        case processState.checkingIfAddressExists:
            return <div className="add-location-status gray"><small>Checking for duplicate locations</small></div>;
        case processState.addressDoesNotExist:
            return <div className="add-location-status green"><small>Address ready to be added</small></div>;
        case processState.addressExistsAlready:
            return <div className="add-location-status red"><small>Address already in system</small></div>;
        case processState.error:
            return <div className="add-location-status red"><small>Error</small></div>;
        case processState.postingLocation:
            return <div className="add-location-status gray"><small>Adding Location...</small></div>;
    }
    return <div className="add-location-status"></div>;
}