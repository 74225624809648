
import { APICall, enqueueAPICall, TokenManager } from './api-utils';

import { paginationSize } from '../reducers/dashboard';

export const fetchUserState = () => void(0);

export const fetchUserFleets = () => APICall({
  method: 'get',
  url: 'fleet',
});

export const fetchUserByEmailAddress = (emailAddress: string) => APICall({
  method: 'get',
  url: `user_details/${emailAddress}/email`,
});


export const fetchUser = (userId: string = TokenManager.getUserIdFromToken()) => APICall({
  method: 'get',
  url: 'user_details/' + userId,
});

export const assignUserLocationByEmail = (locationId: string, emailAddress: string) => APICall({
  method: 'get',
  url: `location/${locationId}/assign/${emailAddress}/email`,
});

export const createClaim = (recipient:string,objectUuid:string, objectType="location", installer: string = TokenManager.getUserIdFromToken()) => {
  const body = {
    recipient,
    object: objectUuid,
    object_type: objectType,
    installer
  };
  return APICall({
    method: 'post',
    url: 'claim',
    data: body
  });
}


export const createLocation = (payload:any) => {
  console.log("create location payload", JSON.stringify(payload))
  return APICall({
    method: 'post',
    url: 'location',
    data: { ...payload.body}
  });
}

export const searchFleet = (fleet: any, model: any, params: any, options={}) => {
 return void(0);
};


export const getInProcess = (fleet: any, params: any) => void(0)

export const searchFleetHubIdsForLocations = (fleet: any, term: any, page: any, skip: any) => enqueueAPICall(
  {
    method: 'get',
    url: `fleet/${fleet}/hub/location/search/${term}?page=${page}&skip=${skip}`
  },
  {
    cacheDurationSeconds: 60 * 0.5
  
  });

export const searchFleetLocations = ({fleet="curb", page=paginationSize, skip=0, sort="_id", searchTerm=""}) => enqueueAPICall(
  {
    method: 'get',
    url: `fleet/${fleet}/location/search/${searchTerm}?page=${page}&skip=${skip}&sort=${sort}&id=false`
  },
  {
    cacheDurationSeconds: 60 * 0.5
  });


export const getFleetLocations = ({fleet="curb", page=paginationSize, skip=0, sort="_id"}) => enqueueAPICall(
  {
    method: 'get',
    url: `fleet/${fleet}/location?page=${page}&skip=${skip}&sort=${sort}&id=true`
  },
  {
    cacheDurationSeconds: 60 * 0.5
  }
);

export const getLocation = (locationId: string) => enqueueAPICall(
  {
    method: 'get',
    url: `location/${locationId}/all`
  },
  {
    cacheDurationSeconds: 0
  }
);

export const checkLocationExists = (query: string) => enqueueAPICall(
  {
    method: 'get',
    url: `location/exists/${encodeURIComponent(query)}`
  },
  {
    cacheDurationSeconds: 0.1
  }
);

export const searchHubs = (query: string) => enqueueAPICall(
  {
    method: 'get',
    url: `hub/search/${encodeURIComponent(query)}`
  },
  {
    cacheDurationSeconds: 60 * 0.5,
  }
);

export const updateLocation = (fleet: any, locationId: any, data: any) => void(0);

export const updateLocationFleet = (fleetId:string, locationId:string) => {
  return APICall({
    method: 'GET',
    url: `location/${locationId}/fleet/${fleetId}`
  });
}

export const updateHubLocation = (hubId: string, locationId: string) => {
  return APICall({
    method: 'PATCH',
    url: `hub/location/${hubId}`,
    data: { location_uuid: locationId }
  });
};


export const locationListUsersWithAccess = (locationId: string, params={}, options={}) => enqueueAPICall(
  {
    method: 'get',
    url: `/location/${locationId}/users`,
    params
  },
  {
    cacheDurationSeconds: 60 * 0.5,
    ...options
  }
);

export const locationListInstallations = (fleet: any, locationId: any, params: any, options={}) => void(0);
// enqueueAPICall(
//   {
//     method: 'get',
//     url: `/api-app/${fleet}/location/${locationId}/installation`,
//     params
//   },
//   {
//     cacheDurationSeconds: 60 * 0.5,
//     ...options
//   }
// );

export const locationListInstallationsExtended = (fleet: any, locationId: any, params: any, options={}) => enqueueAPICall(
  {
    method: 'get',
    url: `/location/${locationId}/hubs`,
    params
  },
  {
    cacheDurationSeconds: 60 * 5,
    ...options
  }
);

export const locationGetTimeseries = (fleet: any, locationId: any, params: any, options={}) => void(0);
// // enqueueAPICall(
//   {
//     method: 'get',
//     url: `/api-app/${fleet}/location/${locationId}/timeseries`,
//     params
//   },
//   {
//     cacheDurationSeconds: 60 * 60 * 12,
//     allowedStatuses: [404],
//     ...options
//   }
// );

export const locationGetAggregate = (fleet: any, locationId: any, params: any, options={}) => void(0);
// enqueueAPICall(
//   {
//     method: 'get',
//     url: `/api-app/${fleet}/location/${locationId}/aggregate`,
//     params
//   },
//   {
//     cacheDurationSeconds: 60 * 60 * 3,
//     ...options
//   }
// );

export const locationGetAggregateToNearestInterval = (fleet: any, locationId: any, params: any) => void(0);
// enqueueAPICall(
//   {
//     method: 'get',
//     url: `/api-app/${fleet}/location/${locationId}/aggregate_to_nearest_interval`,
//     params
//   },
//   {
//     cacheDurationSeconds: 60 * 60 * 3
//   }
// );

export const locationGetCSV = (fleet: any, locationId: any, params: any) => void(0);
// enqueueAPICall({
//   method: 'get',
//   url: `/api-app/${fleet}/location/${locationId}/csv`,
//   params
// });

export const locationGetMetadata = (fleet: any, locationId: any, params: any, options={}) => void(0);
// enqueueAPICall(
//   {
//     method: 'get',
//     url: `/api-app/${fleet}/location/${locationId}/metadata`,
//     params
//   },
//   {
//     cacheDurationSeconds: 60 * 60,
//     ...options
//   }
// );

export const locationGetRegisters = (fleet: any, locationId: any, params: any) => void(0);
// enqueueAPICall(
//   {
//     method: 'get',
//     url: `/api-app/${fleet}/location/${locationId}/registers`,
//     params
//   }  
// );

export const impersonate = (fleet: any, userId: any, data: any) => void(0)
// APICall(
//   {
//     method: 'post',
//     url: `/api-app/${fleet}/impersonate/${userId}`,
//     data,
//   }
// );

export const removeUserAccess = (fleet: any, id: any) => void(0);
// APICall({
//   method: 'delete',
//   url: `/api-app/${fleet}/user_access/${id}`
// });

export const addUserToLocationByEmail = (fleet: any, locationId: any, email: any) => void(0);
// APICall({
//   method: 'post',
//   url: `/api-app/${fleet}/location/${locationId}/user`,
//   data: {
//     email
//   }
// });

export const locationListUserAccesses = (fleet: any, locationId: any, params: any) => void(0);

export const sendClaim = (fleet: any, locationId: any, data: any) => void(0);
// APICall({
//   method: 'post',
//   url: `/api-app/${fleet}/location/${locationId}/send_claim`,
//   data
// });

export const getUserDetailByEmail = (fleet: any, params: any) => void(0);
// enqueueAPICall({
//   method: 'get',
//   url: `/api-app/${fleet}/user_detail_by_email`,
//   params
// });

export const updateUserDetail = (fleet: any, userId: any, userDetail: any) => void(0);
// APICall({
//   method: 'patch',
//   url: `/api-app/${fleet}/user_detail/${userId}`,
//   data: userDetail
// })

export const getInstallation = (fleet: any, hubSerial: any, params: any) => void(0);
// // enqueueAPICall(
//   {
//     method: 'get',
//     url: `/api-app/${fleet}/installation/${hubSerial}`,
//     params
//   }
// );

export const updateInstallation = (fleet: any, hubSerial: any, data: any) => void(0);
// APICall(
//   {
//     method: 'put',
//     url: `/api-app/${fleet}/installation/${hubSerial}`,
//     data
//   }
// );

export const getHub = (fleet: any, hubSerial: any, params: any, options:any) => void(0);
// enqueueAPICall(
//   {
//     method: 'get',
//     url: `/api-app/${fleet}/hub/${hubSerial}`,
//     params
//   },
//   {
//     cacheDurationSeconds: 60 * 30,
//     ...options
//   }
// );

export const getHubConnectivity = (fleet: any, hubSerial: any, params: any, options={}) => void(0);
// enqueueAPICall(
//   {
//     method: 'get',
//     url: `/api-app/${fleet}/hub_connectivity/${hubSerial}`,
//     params
//   },
//   {
//     cacheDurationSeconds: 60 * 30,
//     ...options
//   }
// );

export const getEnergyProjectionsForHub = (fleet: any, hubSerial: any, params: any) => void(0);
// enqueueAPICall(
//   {
//     method: 'get',
//     url: `/api-app/${fleet}/energy-projections/hub/${hubSerial}`,
//     params
//   }
// );

export const upsertEnergyProjectionsForHub = (fleet: any, hubSerial: any, data: any) => void(0);
// APICall(
//   {
//     method: 'post',
//     url: `/api-app/${fleet}/energy-projections/hub/${hubSerial}`,
//     data
//   }
// );

export const updateAdminUserDetail = (data: any) => void(0);
// APICall(
//   {
//     method: 'patch',
//     url: '/api-app/user-detail',
//     data
//   }
// );

export const updateAdminUser = (data: any) => void(0);
// APICall(
//   {
//     method: 'patch',
//     url: '/api-app/protected/admin-user',
//     data
//   }
// );

export const getLocationBilling = (fleet: any, locationId: any) => void(0);
// // enqueueAPICall(
//   {
//     method: 'get',
//     url: `/api-app/${fleet}/location/${locationId}/billing`
//   }
// );

export const updateLocationBilling = (fleet: any, locationId: any, data: any) => void(0);
// APICall(
//   {
//     method: 'put',
//     url: `/api-app/${fleet}/location/${locationId}/billing`,
//     data
//   }
// );

export const getLocationTariff = (fleet: any, locationId: any) => void(0);
// // enqueueAPICall(
//   {
//     method: 'get',
//     url: `/api-app/${fleet}/location/${locationId}/tariff`
//   }
// );

export const listTariffs = (params: any) => void(0);
// // enqueueAPICall(
//   {
//     method: 'get',
//     url: '/api-app/tariffs',
//     params
//   }
// );

export const updateLocationTariff = (fleet: any, locationId: any, masterTariffId: any) => void(0);
// APICall(
//   {
//     method: 'put',
//     url: `/api-app/${fleet}/location/${locationId}/tariff/${masterTariffId}`
//   }
// );

export const getPermissions = (fleet: any) => void(0);
// APICall(
//   {
//     method: 'get',
//     url:`/api-app/${fleet}/permissions`
//   }
// )