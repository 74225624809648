import React, { useLayoutEffect } from 'react';
import { CellMeasurer } from 'react-virtualized';

const LayoutHelper = function({adjustRowHeight}){
  // recompute row height after a render
  useLayoutEffect(
    () => {
      adjustRowHeight();
    },
    []
  );
  return null;
}

export default function(WrappedComponent){
  return function(props){
    const { parent={}, dataKey, rowIndex, columnIndex } = props;
    if(parent.props){
      const { _cache } = parent.props;
  
      const transferProps = {
        ...props,
        adjustRowHeight: () => {
          _cache.clear(rowIndex, columnIndex);
          // _cache.clearAll();
          parent.recomputeGridSize({rowIndex, columnIndex});
        }
      };
  
      return <CellMeasurer
        cache={_cache}
        key={dataKey}
        columnIndex={0}
        {...props}>
        <div className="column-inner">
          <WrappedComponent {...transferProps} />
        </div>
        <LayoutHelper {...transferProps} />
      </CellMeasurer>;
    }
    return <WrappedComponent {...props} />;  
  }
}