const { getURL } = require('../utils/url');
const axios = require('axios');
const { getAsyncQueue, clearAsyncQueues } = require('../utils/async');

function getAPINamespace({url}){
  url = getURL(url);
  const { hostname, pathname } = url;
  // grab any modifier that looks like `/v{n}/` and use it to make a different namespace
  const namespaceModifier = pathname.match(/^\/v(\d|\.)*\//);
  return `${hostname}${namespaceModifier ? namespaceModifier[0] : ''}`;
}

function enqueueRequest(config){
  return new Promise(
    (resolve, reject) => {
      try {
        const namespace = getAPINamespace(config);
        const queue = getAsyncQueue({namespace});
    
        queue.emit(
          'add',
          async function(){
            try {
              const response = await axios(config);
              resolve(response);
            }
            catch(err){
              reject(err);
            }
          }
        );
      }
      catch(err){
        console.error(err);
        reject(err);
      }

    }
  );
}

function clearQueues(){  
  clearAsyncQueues.apply(null, arguments);
}

module.exports = {
  getAPINamespace,
  enqueueRequest,
  clearQueues
};