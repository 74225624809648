const pick = require('lodash.pick');

const threePhaseModelNumbers = [
  627,
  629,
  631,
  633,
  635,
  637
];

const HUB_TYPES = [
  {
    model_number: '613',
    label: 'Pro'
  },
  {
    model_number: '613.1',
    label: 'Arcadia Pro'
  },
  {
    model_number: '614',
    label: 'Pro Rogowski'
  },
  {
    model_number: '615',
    label: 'Lite 12'
  },
  {
    model_number: '616',
    label: 'Lite 6'
  },
  {
    model_number: '618',
    label: 'Lite 8'
  },
  {
    model_number: '617',
    label: 'Schneider Electric Pro'
  },
  {
    model_number: '619',
    label: 'Schneider Electric Lite'
  },
  {
    model_number: '621',
    label: 'Pro - UL'
  },
  {
    model_number: '623',
    label: 'Lite 12 - UL'
  },
  {
    model_number: '625',
    label: 'Lite 6 - UL'
  },
  {
    model_number: '627',
    label: 'Curb Commercial 18 Channel - UL'
  },
  {
    model_number: '629',
    label: 'Curb Commercial 12 Channel - UL'
  },
  {
    model_number: '631',
    label: 'Curb Commercial 6 Channel - UL'
  },
  {
    model_number: '633',
    label: 'Curb Commercial 18 Channel - TUV'
  },
  {
    model_number: '635',
    label: 'Curb Commercial 12 Channel - TUV'
  },
  {
    model_number: '637',
    label: 'Curb Commercial 6 Channel - TUV'
  },
  {
    model_number: '639',
    label: 'Lite 4 - TUV'
  },
  {
    model_number: '641',
    label: 'Pro 12 - TUV'
  },
  {
    model_number: '643',
    label: 'Pro 6 - TUV'
  },
  {
    model_number: '645',
    label: 'Pro 4 - TUV'
  }
];

const channelsPerBlock = 6;

const getInstallationLabel = inst => `${inst.label ? `${inst.label} - ` : ''}(${inst.hub})`;

const getPhaseForGroup = (group, phaseSystem) => {
  if(phaseSystem === 'three'){
    return ['A','B','C','C'][register.group];
  }
  return register.group % 2 ? 'B' : 'A';
};

const getBlockAndChannelIndex = number => {
  return {
    blockIndex: Math.floor(number / channelsPerBlock),
    channelIndex: number % channelsPerBlock
  };
}

const getPhaseForRegister = (register) => {
  return ['A', 'B', 'C'][getPhaseIndexForRegisterNumber(register.number)];
}

const getPhaseIndexForGroup = (groupIndex, { phaseCount=2 }={}) => {
  return phaseCount === 3
    ? [0, 1, 2, 2][groupIndex]
    : groupIndex % 2
}

const getPhaseIndexForRegisterNumber = (number, phaseCount) => {
  const { blockIndex, channelIndex } = getBlockAndChannelIndex(number);
  return phaseCount !== 3 && blockIndex === 2
    ? Math.floor(channelIndex / channelsPerBlock * 2) // block 3 on two phase
    : blockIndex; // everything else is based only on block  
}

const getPhaseCountForModelNumber = modelNumber => {
    return threePhaseModelNumbers.indexOf(parseInt(modelNumber)) > -1 ? 3 : 2;
};

// TODO - this method is obsolete, use `getPhaseCountForModelNumber`
const getPhaseSystemForInstallation = installation => {
  return threePhaseModelNumbers.indexOf(parseInt(installation.model_number)) > -1 ? 'three' : 'two';
};

const getHubTypeForModelNumber = model_number => {
  model_number = model_number.toString().replace(/^0*/, '');
  return HUB_TYPES.find(ht => ht.model_number === model_number);
}

const getChannelLabelForRegister = register => {
  const { channelIndex } = getBlockAndChannelIndex(register.number);
  return 'ABCDEF'.charAt(channelIndex);
};

const registerIsMain = register => register && register.grid && !register.battery && !register.production && !register.total;
const registerIsProduction = register => register && register.production && !register.total;
const registerIsBattery = register => register && register.battery && !register.total;
const registerIsConsumption = register => register && !register.grid && !register.production && !register.battery && !register.total;
const registerIsConfigured = register => register && (register.label || register.grid || register.production || register.battery);

const getConnectionHealthForHub = hubConnectivity => {
  const { last_post_diagnostics, plc_connection } = hubConnectivity;
  const perMinuteDrop = 0.5;
  const idealPostInterval = 60;
  const secondsSinceLastPost = Math.round(Date.now()/1000) - last_post_diagnostics;
  return Math.max(
    0,
    Math.min(1, (1 - (secondsSinceLastPost-idealPostInterval)/ 60 * perMinuteDrop)) * (plc_connection || 1)
  );
};

const isValidHubSerial = hubSerial => hubSerial && /^([a-z0-9]{8})$/i.test(hubSerial);

const circuitTypes = {
  line_side_production_battery: { grid: true, production: true, battery: true },
  line_side_production: { grid: true, production: true, battery: false },
  line_side_battery: { grid: true, production: false, battery: true},
  breaker_side_production_battery: { grid: false, production: true, battery: true },
  breaker_side_production: { grid: false, production: true, battery: false },
  breaker_side_battery: { grid: false, production: false, battery: true },
  main: { grid: true, production: false, battery: false },
  consumption: { grid: false, production: false, battery: false }
}

const getRegisterCircuitType = register => {
  const { grid, production, battery } = register;
  if(grid && production && battery){
    return 'line_side_production_battery';
  }
  if(grid && production){
    return 'line_side_production';
  }
  if(grid && battery){
    return 'line_side_battery';
  }
  if(grid){
    return 'main';
  }
  if(production && battery){
    return 'breaker_side_production_battery';
  }
  if(production){
    return 'breaker_side_production';
  }
  if(battery){
    return 'breaker_side_battery';
  }
  return 'consumption';
}

const getRegisterCircuitTypeFactors = register => {
  return circuitTypes[register.circuit_type];
}

function getLeanRegister(register){
  return pick(register, 'id', 'label', 'group', 'channel', 'grid', 'production', 'battery', 'other', 'category', 'circuit_type')
}

function getCategoryIdFromRegister(register){
  return register.category || register.circuit_type === 'other'
    ? 'consumption'
    : register.circuit_type;
}

function getAllCategoryIdsForRegisters(registers=[]){
  return registers.reduce(
    (acc, register) => {
      const categoryId = getCategoryIdFromRegister(register);
      if(!acc.includes(categoryId)){
        acc.push(categoryId);
      }
      return acc;
    },
    []
  );
}

module.exports = {
  channelsPerBlock,
  getBlockAndChannelIndex,
  getInstallationLabel,
  getPhaseForGroup,
  getPhaseForRegister,
  getPhaseIndexForRegisterNumber,
  getPhaseCountForModelNumber,
  getPhaseIndexForGroup,
  getPhaseSystemForInstallation,
  getHubTypeForModelNumber,
  getChannelLabelForRegister,
  getRegisterCircuitType,
  getRegisterCircuitTypeFactors,
  registerIsProduction,
  registerIsBattery,
  getConnectionHealthForHub,
  isValidHubSerial,
  registerIsConfigured,
  registerIsConsumption,
  registerIsMain,
  getLeanRegister,
  getCategoryIdFromRegister,
  getAllCategoryIdsForRegisters,
  otherRegister: {
    id: '__OTHER__',
    label: 'Other',
    circuit_type: 'other',
    grid: false,
    production: false,
    battery: false,
    other: true
  }
}