import React from 'react';
import LoadingSpinner from '../../atoms/loading-spinner';
import StatusInline from '../../atoms/status-inline';
import LocationDetails from '../../../data-containers/location-details';
import ValuesForm from './form';

export default function({location}){
  const { getDetail } = LocationDetails.useContainer();
  const { installations=[], installationsLoading } = getDetail(location.id) || {};

  if(installationsLoading){
    return <LoadingSpinner />;
  }

  if(!installations){
    return <StatusInline type="error">No hubs found for this location</StatusInline>;
  }

  return <section className="energy-projections-values primary-section">
    <ValuesForm location={location} />
  </section>;
};