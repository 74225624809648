// https://codesandbox.io/s/98j0v46zj4
import React, { Component } from 'react';
import { FormSpy } from 'react-final-form';
import isEqual from 'lodash.isequal';
import throttle from 'lodash.debounce';
import pick from 'lodash.pick';

export default class AutoSave extends Component {
  constructor(props) {
    super(props);
    this.state = { values: props.values, submitting: false };
    this.save = throttle(this.save, props.interval || 1000);
  }

  componentWillReceiveProps(nextProps) {
    const { values={}, keys=[], invalid } = nextProps;
    if(invalid){
      return;
    }
    const prevValues = keys.length
      ? pick(this.props.values, ...keys)
      : this.props.values || {};

    const nextValues = keys.length
      ? pick(values, ...keys)
      : values;

    if(!isEqual(prevValues, nextValues)){
      this.save(nextValues);
    }
  }

  async save (values) {
    this.setState({
      busy: true,
      values
    });
    await this.props.save(values);
    this.setState({
      busy: false
    });
  };

  render() {
    return <FormSpy render={this.props.render} subscription={{ values: true }} />
  }
}
