import React from "react";
import Draggable from "react-draggable";
import { Table, AutoSizer, CellMeasurerCache, Column } from "react-virtualized";

// scroll up issues
// https://github.com/bvaughn/react-virtualized/issues/803
// https://github.com/bvaughn/react-virtualized/issues/610

class TableWrapper extends React.Component {
  constructor(props) {
    super(props);
    const { columns = [] } = props;
    const specifiedWidthColumns = columns.filter((c) => c.widthPercent);
    const unspecifiedWidthColumns = columns.filter((c) => !c.widthPercent);
    const specifiedColumnWidthTotal = specifiedWidthColumns
      .map((c) => c.widthPercent || 0)
      .reduce((acc, v) => {
        acc += v;
        return acc;
      }, 0);
    const availableColumnWidthTotal = 1 - specifiedColumnWidthTotal;
    const columnWidths = {};

    // populate columnWidths with widths for each column if not defined
    columns.forEach((c) => {
      const { dataKey, widthPercent } = c;
      columnWidths[dataKey] = widthPercent
        ? widthPercent
        : availableColumnWidthTotal / unspecifiedWidthColumns.length;
    });

    this.state = {
      rowCount: 0,
      columnWidths,
    };

    this.resizeRow = this.resizeRow.bind(this);
  }

  setScrollHeight(h){
      // const { setScrollTop } = this.props;
      // setScrollTop(h);
  }

  setRowCount(count) {
    this.setState({ rowCount: count });
  }

  resizeRow({ dataKey, deltaX }) {
    // this 
    return;
    this.setState((prevState) => {
      const { columns, width } = this.props;
      const { columnWidths } = prevState;
      const percentDelta = deltaX / width;

      const itemIndex = columns.indexOf(
        columns.find((c) => c.dataKey === dataKey)
      );
      const adjacentColumn =
        itemIndex < columns.length - 1
          ? columns[itemIndex + 1]
          : columns[itemIndex - 1];

      // recompute table on resize because things might move
      // setTimeout(
      //   () => {
      //     this.props._cache.clearAll();
      //     this.props._resizableTableInstance._resizableTable._table.recomputeGridSize();
      //   },
      //   0
      // );

      return {
        columnWidths: {
          ...columnWidths,
          [dataKey]: columnWidths[dataKey] + percentDelta,
          [adjacentColumn.dataKey]:
            columnWidths[adjacentColumn.dataKey] - percentDelta,
        },
      };
    });
  }

  headerRenderer({ dataKey, label }) {
    return (
      <React.Fragment key={dataKey}>
        <div className="ReactVirtualized__Table__headerTruncatedText">
          {label}
        </div>
        <Draggable
          axis="x"
          defaultClassName="DragHandle"
          defaultClassNameDragging="DragHandleActive"
          onDrag={(event, { deltaX }) => {
            this.resizeRow({
              dataKey,
              deltaX,
            });
          }}
          position={{ x: 0 }}
          zIndex={999}
        >
          <span className="DragHandleIcon"></span>
        </Draggable>
      </React.Fragment>
    );
  }

  _setTableRef(ref) {
    this._table = ref;
  }

  render() {
    const { columns = [], width, scrollTop, rows } = this.props;
    const { columnWidths } = this.state;

    const scrollTopVal = undefined;
    //rows.length != this.state.rowCount ? scrollTop && this.setRowCount(rows.length) : undefined;

    return (
      <Table ref={this._setTableRef.bind(this)}  {...this.props} 
      // scrollTop={scrollTopVal}
      // onScroll={(e)=> {
      //   this.setScrollHeight(e.scrollTop);
      //   console.log("Scrolling",rows.length, e.scrollTop)
        
      //   }}
        >
        {columns.map((c, i) => (
          <Column
            key={i}
            headerRenderer={this.headerRenderer.bind(this)}
            width={width * columnWidths[c.dataKey]}
            {...c}
          />
        ))}
      </Table>
    );
  }
}

export default class ResizableTable extends React.Component {
  constructor(props) {
    super(props);
    // const { cellMeasurerCacheProps = {}, setTableInstance } = props;
    this.state = {
      scrollToIndex: undefined,
      resetScrollToIndex: true,
    };
    // this._cache = new CellMeasurerCache({
    //   fixedWidth: true,
    //   minHeight: 32,
    //   defaultHeight: 32,
    //   ...cellMeasurerCacheProps,
    // });
    // this.resetScrollToIndex = this.resetScrollToIndex.bind(this);

    // if (typeof setTableInstance === "function" && !this.haveSetTableInstance) {
    //   setTimeout(() => setTableInstance(this),0);
    // }
  }

  componentWillReceiveProps(nextProps) {
    // if (
    //   nextProps.selectedMapLocation?.index &&
    //   nextProps.selectedMapLocation?.index !== this.state.scrollToIndex
    // ) {
    //   console.log("RESIZE TABLE SCROLL TO INDEX", nextProps.selectedMapLocation?.index);
    //   this.setState({
    //     scrollToIndex: nextProps.selectedMapLocation?.index,
    //     resetScrollToIndex: false,
    //   });
    // }
  }

  resetScrollToIndex() {
    // this.setState({ resetScrollToIndex: true });
  }

  _setResizableTableRef(ref) {
    this._resizableTable = ref;
  }

  rowGetter({ index }) {
    const { list } = this.props;
    return list[index];
  }

  rowClassName(props) {
    const { index } = props;
    const classes = ["location-row"];
    if (index > -1) {
      const { opened, uuid } = this.rowGetter({ index }) || { opened: false };
      if (opened) {
        classes.push("expanded");
        this.props.selectedMapLocation?.uuid === uuid &&
          classes.push("row-highlight");
      }
      // classes.push(index % 2 ? "even1" : "odd");
    } else {
      classes.push("header-row");
    }
    return classes.join(" ");
  }

  render() {
    const { list, setTableHeight } = this.props;
    const { scrollToIndex, resetScrollToIndex } = this.state;

    return (
      <AutoSizer>
        {({ height, width }) => {
      //     // I believe this causes a looping issue in the ui
      //       // if (typeof setTableHeight === "function") {
      //       //   setTimeout(() => setTableHeight(height),0);
      //       // }

          return (
            <TableWrapper
              headerHeight={40}
              ref={this._setResizableTableRef.bind(this)}
              height={height}
              width={width}
              _resizableTableInstance={this}
              _cache={this._cache}
              rowClassName={this.rowClassName.bind(this)}
              rowHeight={this._cache.rowHeight}
              rowCount={list.length}
              // rowCount={10000}
              rowGetter={this.rowGetter.bind(this)}
              overscanRowCount={5}
              // scrollToIndex={resetScrollToIndex ? undefined : scrollToIndex}
              // onScoll={(event)=> console.log("ONSCROLL", event)}
              // onScroll={this.resetScrollToIndex}
              {...this.props}
            />
          );
        }}
      </AutoSizer>
    );
  }
}
