import React, { useState, useEffect } from 'react';
import { createContainer } from 'unstated-next';
import * as API from '../lib/api';
import CONFIG from '../lib/config';
import Fleets from './fleets';

function useUser(initialState) {
  const [ user, setUser ] = useState(initialState.user);
  const [ userDetail, setUserDetail ] = useState(initialState.userDetail);

  const [ loading, setLoading ] = useState(initialState.loading);
  const [ error, setError ] = useState(null);

  const [ isFetching, setIsFetching ] = useState(null);

  const { setFleets } = Fleets.useContainer();

  const updateAdminUserDetail = async (detail, params={}) => {
    const { local } = params;
    try {
      setUserDetail({
        ...userDetail,
        ...detail
      });
      if(!local){
        await API.updateAdminUserDetail(detail)
      }
    }
    catch(err){
      console.error(err);
    }
  }

  // fetch user state on mount
  const fetch = async () => {
    try {
      setError(null);
      setLoading(true);
      setIsFetching(true);
      const { user, fleets, userDetail, config } = await API.fetchUserState();
      CONFIG.values = config;
      setUser(user);
      setFleets(fleets);
      setUserDetail(userDetail);
    }
    catch(error) {
      setError(error);
    }
    setLoading(false);
    setIsFetching(false);
  };

  useEffect(
    // () => {
    //   if(!isFetching){
    //     fetch();
    //   }
    // },
    // []
  );

  return {
    error,
    loading,
    //
    user,
    userDetail,
    updateAdminUserDetail
  };
}

export default createContainer(useUser);
