import React from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

export default function CurbDatePicker({ input: { onChange, value }, ...rest }){
  const { className, label, meta={} } = rest;
  const { error, touched } = meta;
  const classes = ['curb-input', 'curb-form-element', 'curb-datepicker'];
  if(className){
    classes.push(className);
  }
  if(error){
    classes.push('error');
  }
  return <div className={classes.join(' ')}>
    { label && <label>{label}</label> }
    <DatePicker selected={value} onChange={date => onChange(date)} {...rest} />
    { meta.error && <div className="error error-msg">
      { meta.error }
    </div> }
  </div>;
}