const countries = require('../lib/countries');
const { isValidNumber } = require('libphonenumber-js');
const postCodeRegexs = require('../lib/countries/regexs');
const hardwareAPIUtils = require('./hardware-api');
const { encodeText } = hardwareAPIUtils;

function freeText(str='', {required=false, limit=false}={}){
  if(!str && required){
    return {
      valid: false,
      error: 'Required'
    };
  }
  if(limit && encodeText(str).length > limit){
    return {
      valid: false,
      error: 'Too many characters'
    };
  }
  return {
    valid: true
  };
}

const emailRegex = /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/

function email(str='', { required=false }){
  if(!str && required){
    return {
      valid: false,
      error: 'Required'
    }
  }
  if(!str.match(emailRegex)){
    return {
      valid: false,
      error: 'Not a valid email address'
    };
  }

  return {
    valid: true
  };
}

function nonZeroNumber(params){
  const { err='A non-zero value is required' } = params;
  return (value) => {
    value = Number(value);
    return isNaN(value) || !value
      ? err
      : undefined;  
  }
}

function validPhone(value) {
  return !isValidNumber(value || '') ? 'Invalid phone number' : undefined;
}

const validPostcode = (countryCode='USA') => {
  const { regex } = countries.find(c => c.iso3 === countryCode);
  return value => {
    const regex = postCodeRegexs[countryCode];
    if(!regex){
      return undefined;
    }
    return !new RegExp(`^${regex}$`).test(value)
      ? 'Invalid postcode'
      : undefined;  
  };
};

const URLSlugRegex = /^[a-z0-9]+(?:[-_][a-z0-9]+)*$/

const validURLSlug = value => URLSlugRegex.test(value)
  ? undefined
  : 'Invalid'

const required = value => (value ? undefined : 'Required');

module.exports = {
  freeText,
  emailRegex,
  email,
  nonZeroNumber,
  validPhone,
  validPostcode,
  URLSlugRegex,
  validURLSlug,
  required
};