import React, { useState } from 'react';
import { Form, Field } from 'react-final-form';
import CurbInput from '../../../shared/components/atoms/form/input.jsx';
import CONFIG from '../../lib/config';
import LoadingSpinner from '../atoms/loading-spinner';
import URLState from '../../data-containers/url-state';
import LocationDetails from '../../data-containers/location-details';
import { hubSerialValidator } from '../../lib/validators';
import * as API from '../../lib/api';
import StatusInline from '../atoms/status-inline';
import ImpersonationButton from '../explorer/main/locations/table-cell/impersonation-button';
import Address from '../atoms/address';

export default function(props){
  const { location, close } = props;
  const { fleetId } = URLState.useContainer();
  const { addInstallation, fetchInstallations, getDetail } = LocationDetails.useContainer();
  const [ view, setView ] = useState();
  const [ existingLocation, setExistingLocation ] = useState();
  const [ installation, setInstallation ] = useState();
  const [ busy, setBusy ] = useState(null);
  const [ error, setError ] = useState(null);

  const ErrorMessage = () => error && <StatusInline type="error">{error}</StatusInline>;

  function ConfirmExistingHub(){
    return <React.Fragment>
      <StatusInline type="warning">Hub "{installation.hub}" is already installed in location "{existingLocation.label}". </StatusInline>
      <p>Are you sure you wish to proceed?</p>
      <div className="curb-submit button-list">
        { busy && <LoadingSpinner className="inline" /> }
        <button disabled={busy} className="passive" onClick={close}>Cancel</button>
        <button disabled={busy} onClick={() => setView('compareLocations')}>Yes, move hub</button>
      </div>
      { ErrorMessage() }
    </React.Fragment>;
  };

  function CompareLocations(){
    const displayLocation = (loc) => {
      return <React.Fragment>
        <p className="label">{loc.label}</p>
        <Address {...loc} />
      </React.Fragment>;
    };

    return <React.Fragment>
      <div className="add-hub-compare-locations">
        <div className="previous-location location-item">
          <h3>Current location</h3>
          {displayLocation(existingLocation)}
        </div>
        <div className="new-location location-item">
          <h3>New location</h3>
          {displayLocation(location)}
        </div>
      </div>
      <div className="curb-submit">
        <button disabled={busy} onClick={() => setView('finalConfirmExistingHub')}>Move {installation.hub} to new location</button>
      </div>
    </React.Fragment>;
  };

  function FinalConfirmExistingHub(){
    const classes = ['final-confirm-existing-hub'];
    if(busy){
      classes.push('busy');
    }

    const onClickConfirm = async e => {
      try {
        e.preventDefault();
        setBusy(true);
        await addInstallation(location.id, installation.hub);
        fetchInstallations(location.id, { silent: true, forceFetch: true });
        if(existingLocation){
          fetchInstallations(existingLocation.id, { silent: true, forceFetch: true });
        }
        setView('successHubTransfer');
      }
      catch(err){
        console.error(err);
        setError(err.message);
      }
      setBusy(false);
    };
    
    return <div className={classes.join(' ')}>
      <p>Are you sure you want to move hub "{installation.hub}" from "{existingLocation.label}" to "{location.label}"?</p>
      <StatusInline type="warning">This cannot be undone</StatusInline>
      <div className="curb-submit">
        { busy && <LoadingSpinner className="inline" /> }
        <button disabled={busy} className="passive" onClick={close}>Cancel</button>
        <button disabled={busy} className="irreversible" onClick={onClickConfirm}>Confirm</button>
      </div>
    </div>
  };

  function SuccessHubTransfer(){
    return <React.Fragment>
      <StatusInline type="success">Successfully added "{installation.hub}" to location "{location.label}"</StatusInline>
      <div className="curb-submit">
        <button onClick={close}>Done</button>
      </div>
    </React.Fragment>;
  };

  function ConfirmNewHub(){
    const classes = ['confirm-new-hub'];
    if(busy){
      classes.push('busy');
    }
  
    const onClickConfirm = async e => {
      try {
        e.preventDefault();
        setBusy(true);
        await addInstallation(location.id, installation.hub);
        fetchInstallations(location.id, { silent: true, forceFetch: true });
        setView('successNewHub');
      }
      catch(err){
        console.error(err);
        setError(err.message);
      }
      setBusy(false);
    };

    return <div className={classes.join(' ')}>
      <p>Are you sure you wish to add hub "{installation.hub}" to location "{location.label}?"</p>
      <div className="curb-submit button-list">
        { busy && <LoadingSpinner className="inline" /> }
        <button disabled={busy} className="passive" onClick={close}>Cancel</button>
        <button disabled={busy} onClick={onClickConfirm}>Yes, add hub</button>
      </div>
      { ErrorMessage() }
    </div>;
  };

  function SuccessNewHub(){
    const { CONNECT_URL } = CONFIG.values;
    const { users=[] } = getDetail(location.id);
    const user = users[0];
    if(!user){
      return <LoadingSpinner />;
    }

    const impersonationParams = {
      host: CONNECT_URL,
      queryParams: {
        entry: `/locations/${location.id}/hubs/${installation.hub}`
      }
    };
      
    return <React.Fragment>
      <StatusInline type="success">Successfully added "{installation.hub}" to location "{location.label}"</StatusInline>
      <p>Would you like to configure this new hub?</p>
      <div className="curb-submit button-list">
        <button disabled={busy} className="passive" onClick={close}>Return to dashboard</button>
        <ImpersonationButton 
          className="button"
          userId={user.user.user_id}
          loadingSpinnerClassName="inline full tiny"        
          params={impersonationParams}>
          Open in config app
        </ImpersonationButton>
      </div>
      { ErrorMessage() }
    </React.Fragment>;
  };

  function AddHubForm(){  
    const onSubmit = async ({serial}) => {
      try {
        setError(null);
        setBusy(true);
        const response = await API.getInstallation(fleetId, serial);
        setInstallation(response);
        if(response.location){
          let loc = await API.getLocation(fleetId, response.location);
          setExistingLocation(loc);
          setView('confirmExistingHub');
        }
        else {
          setView('confirmNewHub');
        }
      }
      catch(err) {
        setError(err.message);
      }
      setBusy(false);  
    };
  
    return <Form
      onSubmit={onSubmit}
      className="curb-form"
      render={
        ({form, handleSubmit, pristine, invalid}) => {
          const classes = ['curb-form', 'add-hub-form'];
          if(busy){
            classes.push('busy');
          }
          return <form
              className={classes.join(' ')}
              onSubmit={handleSubmit}>
              <p>Add hub to location "{location.label}"</p>
              <Field
                name="serial"
                className="add-hub-field"
                placeholder="Hub serial #"
                autoComplete="off"
                validate={hubSerialValidator}
                maxLength={8}
                render={CurbInput} />
              <div className="curb-submit">
                { busy && <div className="curb-form-element">
                  <LoadingSpinner className="inline" />
                </div> }
                <button type="submit" disabled={pristine || invalid}>Add hub</button>
              </div>
              { ErrorMessage() }
            </form> 
  
        }
      } />
  };

  switch(view){
    case 'confirmNewHub':
      return <ConfirmNewHub />;
    case 'successNewHub':
      return <SuccessNewHub />; 
    case 'confirmExistingHub':
      return <ConfirmExistingHub />;    
    case 'compareLocations':
      return <CompareLocations />;
    case 'finalConfirmExistingHub':
      return <FinalConfirmExistingHub />;
    case 'successHubTransfer':
      return <SuccessHubTransfer />
    default:
      return <AddHubForm />;
  }
}