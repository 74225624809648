import { createSlice } from '@reduxjs/toolkit'
import { ILocation } from './interfaces';

export const paginationSize = 10;

export interface IDashboard {
    /** this dashboard's fleet */
    fleet: string;
    /** where in the pagination the set is */
    page?: number; // int
    /** how many pages to skip */
    skip?: number; // int
    /** which row is sorted on, a db value */
    sort?: string;
    /** rows populating this dashboard */
    rows: ILocation[];
    /** search term */
    searchTerm?: string;
    /** have all these locations been loaded */
    finishedLoading?: boolean;
    /** has started loading locations */
    initialLoad?: boolean;
}

class Dashboard implements IDashboard {
    fleet: string;
    searchTerm: string;
    finishedPaging: boolean = false;
    page: number;
    skip: number;
    sort: string;
    rows: ILocation[];
    finishedLoading: boolean;
    initialLoad: boolean;
    constructor({
      fleet="", 
      page=paginationSize,
      skip=0,
      sort="_id",
      rows=[],
      searchTerm="",
      finishedLoading=false,
      initialLoad=false
    }:IDashboard = {fleet:'curb',rows:[]}){
        this.fleet = fleet;
        this.page = page;
        this.skip = skip;
        this.sort = sort;
        this.finishedLoading = false;
        this.rows = rows.map((row) => {
          return {
            ...row,
            key: row.uuid
          }
        });
        this.searchTerm = searchTerm;
        this.initialLoad = false;
        this.finishedLoading = finishedLoading;
        this.initialLoad = initialLoad;
    }

    toJson = () => {
      return {
        fleet: this.fleet,
        page: this.page,
        skip: this.skip,
        sort: this.sort,
        rows: this.rows,
        searchTerm: this.searchTerm,
        finishedLoading: this.finishedLoading,
        initialLoad: this.initialLoad
      }
    }
    
}

export interface IDashboards {
    [key:string]: IDashboard;
}

const dashboards:IDashboards = {};


export const dashboardsSlice = createSlice({
    name: 'dashboards',
    initialState: {
      dashboards,
    },
    reducers: {
      addDashboard(state, action) {
        state.dashboards[action.payload.fleet] = new Dashboard(action.payload).toJson();
      },
      updateDashboard(state, action) {
        try {
          if(!state.dashboards){
            state.dashboards = {};
            state.dashboards[action.payload.fleet] = new Dashboard(action.payload).toJson();
          } else if(state.dashboards[action.payload.fleet] === undefined) {
            state.dashboards[action.payload.fleet] = new Dashboard(action.payload).toJson();
          } else {
            const rows = (state.dashboards[action.payload.fleet].rows || []).concat(action.payload.rows.map((row:any) => {return {...row,key:row.uuid}})).reduce((acc:ILocation[],cur:ILocation) => {
              const index = acc.findIndex(({uuid}) => uuid === cur.uuid);
              if (index === -1) {
                acc.push(cur);
              } else {
                acc[index] = { 
                  ...acc[index],
                  ...cur
                };
              }
              return acc;
            },[]);
            state.dashboards[action.payload.fleet] = {
                ...state.dashboards[action.payload.fleet],
                ...action.payload,
                rows
            }
          }
        } catch(e) {
          console.log(`[ERROR] DASHBOARD UPDATE `, e);
        }
      },
      setSearchTerm(state, action) {
        const { fleetId, searchTerm } = action.payload;
        state.dashboards[fleetId].searchTerm = searchTerm;
        state.dashboards[fleetId].rows.length = 0;
        state.dashboards[fleetId].finishedLoading = false;
        state.dashboards[fleetId].initialLoad = false;
        state.dashboards[fleetId].skip = 0;
      },
      expandRow(state, action) {
        const {fleetId, index} = action.payload;
        const {rows = []} = state.dashboards[fleetId];
        const { opened } = rows[index];
        state.dashboards[fleetId].rows[index].opened = !opened;
      },
      updateRow(state, action) {
        const rowIndex = state.dashboards[action.payload.fleetId].rows.findIndex(({uuid}) => uuid === action.payload.row.uuid);
        state.dashboards[action.payload.fleetId].rows[rowIndex] = {
          ...state.dashboards[action.payload.fleetId].rows[rowIndex],
          ...action.payload.row,
          key:state.dashboards[action.payload.fleetId].rows[rowIndex].uuid
        }
      },
      setPagination(state,action){
        const { page, skip, sort, fleet }:{page:number, skip:number, sort:string, fleet:string} = action.payload;
        state.dashboards[fleet].page = Math.round(page);
        state.dashboards[fleet].skip = Math.round(skip);
        state.dashboards[fleet].sort = `${sort}`;
      },
      resetPagination(state,action){
        state.dashboards[action.payload.fleet].page = paginationSize;
        state.dashboards[action.payload.fleet].skip = 0;
        state.dashboards[action.payload.fleet].sort = "";
      }
    }
  });

export const { 
  addDashboard,
  updateDashboard,
  setPagination,
  resetPagination,
  expandRow,
  updateRow,
  setSearchTerm
} = dashboardsSlice.actions;

export const getRows = (state:any) => {
  if(!state.fleet.fleet) {
    return [];
  }
  const {fleet: { fleet: {id=""}} } = state;
  const {dashboards: {dashboards: { [id]:{rows = []} = {}} = {}} = {}} = state;
  return rows;
}

export const getDashboard = (state:any) => {
  if(!state.fleet.fleet) {
    return new Dashboard();
  }
  const {fleet: { fleet: {id=""}} } = state;
  const d = state.dashboards.dashboards[id];
  if(d) return d;
  return new Dashboard(id);
}

export const getSearchTerm = (state:any) => {
  if(!state.fleet.fleet) {
    return "";
  }
  const {fleet: { fleet: {id=""}} } = state;
  const {dashboards: {dashboards: { [id]:{searchTerm = ""} = {}} = {}} = {}} = state;
  return searchTerm;
}

export const getPagination = (state:any) => {
  try {
    return { 
      sort: state.dashboards.dashboards[state.fleet.fleet.id].sort,
      page: state.dashboards.dashboards[state.fleet.fleet.id].page,
      skip: state.dashboards.dashboards[state.fleet.fleet.id].skip
    };
  } catch(e) {
    return {
      sort: "_id", page: paginationSize, skip:0
    }
  }

};


export default dashboardsSlice.reducer;


