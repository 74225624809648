import React, { useState } from 'react';
import { Field } from 'react-final-form';
import { OnChange } from 'react-final-form-listeners';
import moment from 'moment';
import CurbInput from '../../../../../shared/components/atoms/form/input.jsx';
import CurbDatePicker from '../../../../../shared/components/atoms/form/datepicker';
import { nonZeroNumber } from '../../../../../shared/utils/validators';
import EnergyProjections from '../../../../data-containers/energy-projections';

/* 
  <Field
    name={`${name}.date`}
    format={format}
    parse={parse}
    minDate={minDate}
    maxDate={maxDate}
    validate={required}
    dateFormat="MMM d"
    placeholderText="Select a custom holiday"
    component={CurbDatePicker} />
*/

function BillingField({form, i, disabled, last}){
  const min = moment().month(i).date(1);
  const max = min.clone().add(1, 'month').subtract(1, 'day');
  const name = `consumption[${i}].billing_end_day`;
  const [fieldFocused, setFieldFocused] = useState(null);

  const format = v => v._d;
  const parse = v => moment(v);

  return <React.Fragment>
    <Field
      disabled={disabled}
      format={format}
      parse={parse}
      dateFormat="MM/dd/yy"
      i={i}
      key={name}
      name={name}
      minDate={min._d}
      maxDate={max._d}
      onFocus={() => setFieldFocused(true)}
      onBlur={() => setFieldFocused(false)}
      popperPlacement={last ? 'bottom-end' : 'bottom-start'}
      component={CurbDatePicker} />  

    <OnChange name={name}>
      {
        (d) => {
          if(fieldFocused){
            let fields = form.getRegisteredFields();
            let index = i;
            let originalDate = d.date();

            let getNextField = () => fields.find(name => name === `consumption[${index+1}].billing_end_day`);
            while(getNextField()){
              let fieldName = getNextField();
              let { value } = form.getFieldState(fieldName);
              let daysInNextMonth = value.daysInMonth();

              form.change(
                fieldName,
                value.clone().date(
                  // make sure we don't set an out of bounds date (eg. feb 31)
                  Math.min(
                    daysInNextMonth,
                    originalDate
                  )
                )
              );
              index += 1;
            }
          }
        }
      }
    </OnChange>
  </React.Fragment>
}

export default function EnergyProjectionsConsumptionValues({values, form, disabled}){
  let { installation, loadingInstallation } = EnergyProjections.useContainer();
  disabled = disabled || (!installation || loadingInstallation);

  return <div className="consumption-values">
    <div className="form-row consumption-dates">
      <label>Billing End Date</label>
      {
        values.consumption.map(
          (month, i) => {
            const transferProps = { form, i, disabled };
            return <BillingField key={i} last={i === values.consumption.length-1} {...transferProps} />;
          }
        )
      }
    </div>
    <div className="form-row consumption-values">
      <label>Used kWh</label>
      {
        values.consumption.map(
          (month, i) => {
            const name = `consumption[${i}].used_kwh`;
            return <Field
              type="number"
              disabled={disabled}
              validate={nonZeroNumber({err: 'Required'})}
              parse={
                value => value.toString().length
                  ? Number(value)
                  : value  
              }
              key={name}
              name={name}
              render={CurbInput} />;
          }
        )
      }      
    </div>
  </div>;
}