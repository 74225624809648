import React from 'react';
import AdminUserForm from '../forms/admin-user';
// import { EulaContent } from '../modals/eula';
import Fleets from '../../data-containers/fleets';
import CONFIG from '../../lib/config';

const EulaButton = function(){
  // const modalProps = {
  //   className: "eula-modal",
  //   render: (modalProps) => <EulaContent {...modalProps} />
  // };

  return <a className="button link" target="_blank" rel="noopener noreferrer" href="https://energycurb.com/terms">View license agreement</a>;

  // return <ModalManager
  //   modalProps={modalProps}
  //   render={
  //     () => <button className="link">View license agreement</button>
  //   } />;
};

export default function(){
  const { currentFleet } = Fleets.useContainer();
  const { cobranding={} } = currentFleet;
  const { APP_URL } = CONFIG.values;  

  const logoSrc = cobranding.logo_path 
    ? (/^https?:\/\//.test(cobranding.logo_path))
      ? cobranding.logo_path
      : `${APP_URL}/static/${cobranding.logo_path}`
    : null;  

  return <section className="account-info">
    <div className="account-fleet-img">
      {
        logoSrc
          ? <img
            src={logoSrc}
            className="logo" />
          : null
      }      
    </div>
    {/* <section className="inset">
      <div className="button-list">
      </div>
    </section> */}
    <AdminUserForm>
      <React.Fragment>
        <div className="curb-form-element">
          <EulaButton />
        </div>
      </React.Fragment>
    </AdminUserForm>
  </section>;
}