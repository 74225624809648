import React from 'react';

export default class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { 
      hasError: true, 
      error 
    };
  }

  componentDidCatch(error) {
    // You can also log the error to an error reporting service
    console.error(error);
  }

  render() {
    const { error, hasError } = this.state;
    if (hasError) {
      let { className } = this.props;
      let classes = ['error-boundary'];
      if(className){
        classes.push(className);
      }

      // You can render any custom fallback UI

      return <div className={classes.join(' ')}>
        <div className="error-title">An error occurred</div>
        <div className="error-content">
          { typeof error.message === 'string' && <React.Fragment>
            <br /><br />
            { error.message }
          </React.Fragment> }          
          { typeof error.stack === 'string' && <React.Fragment>
            <br /><br />
            {/* { error.stack } */}
          </React.Fragment> }
        </div>
        <p className="support-message">Please contact Curb support at <a className="support-email" href="mailto:support@energycurb.com">support@energycurb.com</a> if this problem persists.  Screenshots of the above error can help us resolve the issue.</p>
      </div>
    }

    return this.props.children; 
  }
}
