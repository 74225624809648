import React from 'react';
import { Field } from 'react-final-form';
import CurbInput from '../../../../../shared/components/atoms/form/input.jsx';
import { nonZeroNumber } from '../../../../../shared/utils/validators';
import EnergyProjections from '../../../../data-containers/energy-projections';

export default function({values, disabled}){
  let { installation, loadingInstallation } = EnergyProjections.useContainer();
  disabled = disabled || (!installation || loadingInstallation);

  return <div className="production-values">
    <div className="form-row production-values">
      <label>Month</label>
      {
        values.consumption.map(
          (month, i) => {
            return <div 
              key={i} 
              className="curb-form-element month-label">
              { month.billing_end_day.format('MMM') }
            </div>;
          }
        )
      }      
    </div>    
    <div className="form-row production-values">
      <label>Solar kWh</label>
      {
        values.consumption.map(
          (month, i) => {
            const name = `production[${i}].projected_solar_production`;
            return <Field
              type="number"
              disabled={disabled}
              validate={nonZeroNumber({err: 'Required'})}
              parse={
                value => value.toString().length
                  ? Number(value)
                  : value  
              }
              key={name}
              name={name}
              render={CurbInput} />
          }
        )
      }      
    </div>
  </div>;
}