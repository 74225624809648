import React from 'react';
import moment from 'moment-timezone';
import { Form, Field } from 'react-final-form';
import CurbDatePicker from '../../../shared/components/atoms/form/datepicker';
import CurbSelect from '../../../shared/components/atoms/form/select';
import { downloadFile } from '../../../shared/utils/file';
import * as API from '../../lib/api';
import URLState from '../../data-containers/url-state';

class DataDownload extends React.Component {
  constructor(props){
    super(props);
    const { location } = this.props;
    if(location.timezone){
      this.state = {
        initialValues: {
          resolution: 'h',
          start: moment().tz(location.timezone).subtract(30, 'days').startOf('day')._d,
          end: moment().tz(location.timezone).endOf('day')._d  
        }
      };
    }
  }
  async onSubmit(values){
    try {
      this.setState({
        error: null,
        loading: true
      });
      const { location, fleet } = this.props;
      let { start, end, resolution } = values;
      const format = 'MMM D YYYY';

      start = moment.tz(start, location.timezone).startOf('day');
      end = moment.tz(end, location.timezone).endOf('day');

      const csv = await API.locationGetCSV(
        fleet,
        location.id,
        {
          start: Math.floor(start.clone().utc().valueOf()/1000),
          end: Math.floor(end.clone().utc().valueOf()/1000),
          resolution
        }
      );

      await downloadFile({
        contents: csv,
        type: 'text/csv',
        filename: `${location.label}_${start.format(format)}-${end.format(format)}.csv`
      });
    }
    catch(err) {
      console.error(err);
      this.setState({
        error: err
      })
    }
    this.setState({
      loading: false
    })
  }
  render(){
    const { initialValues, loading } = this.state;
    const { location } = this.props;
    if(!location.timezone){
      return (
        <p>Location has no timezone information</p>
      );
    }

    const classes = ['curb-form data-download-form'];
    if(loading){
      classes.push('busy');      
    }

    return (
      <Form
        onSubmit={this.onSubmit.bind(this)}
        initialValues={initialValues}
        render={
          ({handleSubmit, valid, values}) => {
            return (
              <form
                className={classes.join(' ')}
                onSubmit={handleSubmit}>
                <section className="form-row">

                  <Field
                    name="start"
                    label="Start date"
                    maxDate={new Date()}
                    autoComplete="off"
                    validate={
                      (v, allValues) => {
                        if(v > allValues.end){
                          return 'Start date must be before end date';
                        }
                        if(!v) return 'Start date is required'
                      }
                    }
                    component={CurbDatePicker} />

                  <Field
                    name="end"
                    label="End date"
                    minDate={typeof values.start === 'object' &&  values.start ? values.start._d : '' }
                    maxDate={new Date()}
                    autoComplete="off"
                    component={CurbDatePicker} />

                  <Field
                    name="resolution"
                    label="Resolution"
                    id="historical-resolution"
                    component={CurbSelect}>
                    <option value="m">Minute</option>
                    <option value="5m">Five minute</option>
                    <option value="h">Hour</option>
                  </Field>

                </section>

                <div className="actions curb-form-element">
                  <button 
                    disabled={!valid}
                    type="submit">
                      {loading ? 'Downloading' : 'Generate'}
                  </button>
                </div>
              </form>
            );
          }
        } />
    );
  }
}


export default function(props){
  const { location } = props;
  const { fleetId } = URLState.useContainer();
  return <React.Fragment>
    <h2>Data download for {location.label}</h2>
    <DataDownload {...props} fleet={fleetId} />
  </React.Fragment>
}