import React from 'react';
import PageHeader from '../header';
import ErrorBoundary from '../atoms/error-boundary';

export default function(WrappedComponent){
  return (props) => {
    const { className } = props;
    const classes = ['app-inner'];
    if(className){
      classes.push(className);
    }

    return <div className={classes.join(' ')}>
      <ErrorBoundary className="top-level-error">
        <PageHeader {...props} />
        <main className="app-main">
          <WrappedComponent {...props} />
        </main>
      </ErrorBoundary>
    </div>;
  }
};