import React, { useState, useEffect } from 'react';
import queryString from 'query-string';
import { createContainer } from 'unstated-next';
import Fleets from './fleets';

function useURLState(props){
  const { location, match, history } = props;
  const { setCurrentFleetId } = Fleets.useContainer();
  const [ fleetId, setFleetId ] = useState();
  const [ includeAllSubfleets, setIncludeAllSubfleets ] = useState();

  // capture fleetId change in URL
  useEffect(
    // () => {
    //   setFleetId(match.params.fleetId);
    //   setCurrentFleetId(match.params.fleetId);
    // },
    // [match.params.fleetId]
  );

  useEffect(
    // () => {
    //   let pathSegments = match.url.replace(/^\//, '').split('/');      
    //   setIncludeAllSubfleets(
    //     pathSegments[1] === 'all'
    //       ? 1
    //       : undefined
    //   );
    // },
    // [match.url]
  );

  return {
    location,
    history,
    fleetId,
    includeAllSubfleets,
    queryParams: queryString.parse(location.search)
  };
};

export default createContainer(useURLState);
