import React from "react";
import { components } from "react-select";
import Arrow from "../img/arrow-down.png";

export const white = '#fff';
export const defaultColor = "#3475d4";
export const defaultBorderColor = "#c7c8ca";

export const customStyles = (props) => {
  return {
    control: (provided) => ({
      ...provided,
      border: "none !important",
      borderBottom: "2px solid #80c0ff !important",
      borderRadius: "0",
      transform: "translateY(-4px)",
      boxShadow: "none",
      "&:hover": {
        cursor: "pointer"
      }
    }),
    indicatorSeparator: (provided) => ({
      ...provided,
      display: "none",
    }),
    dropdownIndicator: (provided) => ({
      ...provided,
      color: defaultColor,
    }),
    placeholder: (provided) => ({
      ...provided,
      color: props?.hidePlaceholder ? "transparent" : defaultColor,
      fontSize: "14px",
    }),
    singleValue: (provided) => ({
      ...provided,
      fontSize: "13px",
      color: defaultColor,
      transform: "translate(-8px,3px)",
      marginLeft: "0px",
    }),
    menu: (provided) => ({
      ...provided,
      backgroundColor: white,
      borderRadius: "0"
    }),
    option: (provided, { isSelected }) => ({
      ...provided,
      backgroundColor: "transparent",
      color: defaultColor,
      ":active": {
        fontWeight: isSelected
          ? "bold"
          : "normal"
      },
      ":hover": {
        ...provided[':active'],
        cursor: "pointer",
        backgroundColor: isSelected
          ? "#eee"
          : "transparent"
      }
    })
  };
};

export const DropdownIndicator = (props) => {
  const { menuIsOpen } = props.selectProps;
  return (
    <components.DropdownIndicator {...props}>
      <img
        src={Arrow}
        alt="arrow"
        style={
          menuIsOpen
            ? { width: "14px", transform: "rotate(180deg)" }
            : { width: "14px" }
        }
      />
    </components.DropdownIndicator>
  );
};
