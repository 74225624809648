'use strict';

const encodeText = s => {
  if(!s){
    return '';
  }
  return s.replace(
    /[*#%;\'\0\r\t\b\n\\]|(\-\-)/g,
    c => {
      const r = [];
      for(var i=0; i<c.length; i++){
        r.push(
          `~${c.substr(i, 1).charCodeAt(0).toString()}_`
        );
      }
      return r.join('');
    }
  );
};

const decodeText = s => {
  if(!s){
    return '';
  }
  return s.replace(
    /(\~(.){1,4}\_)/g,
    c => {
      const decoded = String.fromCharCode(c.substr(1,c.length-2));
      return decoded ? decoded : c;
    }
  );
};

const extractSampleCircuitData = (sample, raw) => {
  // raw samples are in watts, so multiply by 3600 to get watt hours
  const multiplier = raw ? 3600 : 1
  return sample.g.map(g => g.c.map(c => {
    return {
      w: c.w * 3600,
      i: c.i,
      p: c.p
    }
  }))
};

module.exports = {
  encodeText: encodeText,
  decodeText: decodeText,
  extractSampleCircuitData: extractSampleCircuitData
};