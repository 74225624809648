import React, { useState, useEffect } from 'react';
import { Form } from 'react-final-form';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import LoadingSpinner from '../../../atoms/loading-spinner';
import URLState from '../../../../data-containers/url-state';
import EnergyProjections from '../../../../data-containers/energy-projections';
import Consumption from './consumption';
import Production from './production';
import * as API from '../../../../lib/api';
import PrintError from '../../../atoms/print-error';
import StatusInline from '../../../atoms/status-inline';

// https://dev.to/chromiumdev/sure-you-want-to-leavebrowser-beforeunload-event-4eg5
let latestRenderProps = {};

const CACHE = {};

export default function(){
  const { fleetId } = URLState.useContainer();
  const { installation={}, getDefaultSettings, transformIncoming, transformOutgoing, settings, fetchSettings, loadingSettings } = EnergyProjections.useContainer();
  const [ error, setError ] = useState(null);
  const [ success, setSuccess ] = useState(null);

  const valuesStorageKey = `energy_projections_values_${installation.hub}`;  

  const onSubmit = async (values) => {
    try {
      setSuccess(null);
      setError(null);
      await API.upsertEnergyProjectionsForHub(fleetId, installation.hub, transformOutgoing(values));
      setSuccess(true);
      await fetchSettings(installation.hub);
      CACHE[valuesStorageKey] = null;
      setSuccess(true);
    }
    catch(err) {
      const { body={} } = err;
      const { message } = body;
      setError(message || err.message);
      setSuccess(false);
    }
  }

  useEffect(
    () => {
      return () => {
        const { dirty, submitting, values } = latestRenderProps;
        if(dirty || submitting){
          CACHE[valuesStorageKey] = values;
        }
      }
    }
  );

  useEffect(
    () => {
      if(!loadingSettings && installation.hub){
        fetchSettings(installation.hub);
      }
    },
    [installation.hub]
  );

  const getUnsavedValues = () => {
    try {
      let values = CACHE[valuesStorageKey];
      if(!values){
        throw new Error();
      }
      return values;
    }
    catch(err){
      return null;
    }
  };

  const unsavedValues = getUnsavedValues();

  const initialValues = unsavedValues
    ? unsavedValues
    : transformIncoming({
      ...getDefaultSettings(),
      ...settings,
    });

  const beforeUnloadHandler = (e) => {
    const { dirty, submitting } = latestRenderProps;
    if(dirty || submitting || CACHE[valuesStorageKey]){
      e.returnValue = 'Your changes have not been saved.  Are you sure you wish to leave?';
    }
  }

  if(!installation){
    return null;
  }

  return <Form
    onSubmit={onSubmit} 
    // this prevents weird state when reloading the settings on submit
    keepDirtyOnReinitialize={loadingSettings}
    initialValues={initialValues}
    render={
      renderProps => {
        const { handleSubmit, submitting, invalid, form, pristine, values } = renderProps;

        latestRenderProps = renderProps;

        useEffect(
          () => {
            window.addEventListener('beforeunload', beforeUnloadHandler);
            return () => {
              window.removeEventListener('beforeunload', beforeUnloadHandler)
            };
          },
          []
        );

        const classes = ['curb-form', 'energy-projections-values-form'];
        if(loadingSettings || submitting){
          classes.push('busy');
        }

        return <form 
          className={classes.join(' ')}
          onSubmit={handleSubmit}>

          <section className="inset highlight">
            <section className="inner">
              <div className="section-title">
                <h3>Consumption History</h3>
              </div>
              <Consumption {...renderProps} />
            </section>
          </section>

          <section className="inset highlight">
            <section className="inner">
              <div className="section-title">
                <h3>Projected Solar Production</h3>
              </div>
              <Production values={values} />
            </section>
          </section>

          <section className="curb-submit primary-section">
            <div className="curb-form-element">
              { submitting && <LoadingSpinner className="inline" /> }
              { !!success && <FontAwesomeIcon icon="check" className="success" /> }
              { error && <StatusInline type="error">
                <PrintError error={error} />
              </StatusInline> }
            </div>
            { (loadingSettings && !submitting) && <LoadingSpinner className="inline" /> }
            <button 
              type="button" 
              className="passive" 
              disabled={pristine}
              onClick={e => { e.preventDefault(); form.reset(initialValues); }}>
              Revert
            </button>
            <button className="irreversible" type="submit" disabled={submitting || invalid}>
              Apply Changes
            </button>
          </section>

        </form>;
      }
    } />
}