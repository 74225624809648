import 'regenerator-runtime/runtime'
import WebFont from 'webfontloader';
import React from 'react';
import { render } from 'react-dom';
import { Provider } from 'react-redux'

import Tooltip from '../shared/components/atoms/Tooltip.js';
import Routes from './routes.js';
import store from './reducers/store'
import './lib/icons.js';

import { checkEnvVars } from './config';

import { Auth0Provider } from '@auth0/auth0-react';

WebFont.load({
  google: {
    families: ['Lato:400,400i,700']
  }
});

checkEnvVars();

// even if fonts fail to load, add wf-active class
setTimeout(
  () => {
    document.documentElement.classList.add('wf-active');
  },
  5000
);

render(
    <Auth0Provider
      connection={process.env.REACT_APP_USERS_CONNECTION_NAME}
      domain={process.env.REACT_APP_AUTH_DOMAIN}
      clientId={process.env.REACT_APP_FLEETADMIN_CLIENT_ID}
      audience={process.env.REACT_APP_PUBLIC_API_IDENTIFIER}
      redirectUri={`${window.location.origin}`}
      scope="openid" 
      useRefreshTokens={true}
    >
      <div className="app-container">
        <Provider store={store}>
          <Routes />
          <Tooltip />
        </Provider>
      </div>
    </Auth0Provider>,
  document.getElementById('app-wrapper')
);
