import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Form, Field } from 'react-final-form';
import CurbInput from '../../../shared/components/atoms/form/input.jsx';
import PrintError from '../atoms/print-error';
import LoadingSpinner from '../atoms/loading-spinner';
import User from '../../data-containers/user';
import * as API from '../../lib/api';

export default function(props){
  const { userDetail } = User.useContainer();
  const [ busy, setBusy ] = useState(null);
  const [ error, setError ] = useState(null);
  const [ success, setSuccess ] = useState(null);

  const { name, email_address } = userDetail;

  const onSubmit = async (values) => {
    try {
      setSuccess(null);
      setError(null);
      setBusy(true);
      await API.updateAdminUserDetail(values);
      setSuccess(true);
    }
    catch(err) {
      console.error(err);
      setSuccess(null);
      setError(err.message);
    }
    setBusy(false);  
  };

  return <Form
    onSubmit={onSubmit}
    initialValues={{name}}
    keepDirtyOnReinitialize={true}
    className="curb-form"
    render={
      (formProps) => {
        const { form, handleSubmit, invalid, pristine } = formProps;
        const classes = ['curb-form', 'admin-user-info-form'];
        if(busy){
          classes.push('busy');
        }
        return <React.Fragment>
          <form
            className={classes.join(' ')}
            onSubmit={handleSubmit}>
            <Field
              name="name"
              label="Name"
              placeholder="Name"
              autoComplete="off"
              required={true}
              render={CurbInput} />

            <div className="curb-form-element">
              <label>Email</label>
              <div className="value">{email_address}</div>
            </div>

            { props.children }

            <div className="curb-form-element curb-submit button-list">
              { busy && <div className="curb-form-element">
              <LoadingSpinner className="inline" />
              </div> }
              { success && <div className="curb-form-element">
                <FontAwesomeIcon icon="check" className="success" /> 
              </div> }
              { error && <PrintError error={error} /> }
              <button disabled={(invalid || pristine)} type="submit" className="irreversible">Save</button>
            </div>

          </form> 
        </React.Fragment>
      }
    } />
}