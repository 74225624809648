import React, { useEffect, useLayoutEffect, useRef } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ModalManager from "../../../../atoms/modal-manager";
import LoadingSpinner from "../../../../atoms/loading-spinner";
import ImpersonationButton from "./impersonation-button";
// import Explorer from "../../../../../data-containers/explorer";
import Fleets from "../../../../../data-containers/fleets";
// import LocationDetails from "../../../../../data-containers/location-details";
import Address from "../../../../atoms/address";
import DataDownloadModal from "../../../../modals/data-download";
import DynamicCell from "../../../../atoms/table/dynamic-cell";
import ErrorWithTooltip from "../../../../atoms/error-with-tooltip";
import CONFIG from "../../../../../lib/config";
import Arrow from "../../../../../../img/arrow-down.png";
import AppliancesInventoryImage from "../../../../../../img/appliance-inventory.png";
import gearIcon from "../../../../../../img/gear.png";
import mobileIcon from "../../../../../../img/mobile.png";
import webIcon from "../../../../../../img/web.png";
import AppliancesInventoryIcon from "../../../../../../img/appliance-inventory-icon.png";
import { clearItemsFromAPIQueuesByLocation } from '../../../../../lib/api-utils';
import { useSelector, useDispatch } from "react-redux";

import { getDashboard, expandRow } from '../../../../../reducers/dashboard';
import { getFleet } from "../../../../../reducers/fleet";



function LocationExpandToggle(props) {

  const {
    rows,
    rowData = { get: null },
    rowIndex,
    toggleExpand,
    adjustRowHeight,
    selectedMapLocation,
    setSelectedMapLocation,
  } = props;
  let dashboard = {rows:[{opened:false,label:''}]};
  try {
    dashboard = useSelector(getDashboard);
  } catch (e) {
    //
  }
  const opened = rows[rowIndex].opened || false;
  const label = rows[rowIndex].label;
  const {id:fleetId} = useSelector(getFleet);
  const classes = ["location-expand-toggle"];
  if (opened) {
    classes.push("expanded");
  }

  useEffect(
    function onMount(){
      return function onUnmount() {
        clearItemsFromAPIQueuesByLocation(rowData.id);
      }
    },
    []
  );

  useLayoutEffect(() => {
    // this ensures row height is set properly when expanded/collapsed
    if (typeof adjustRowHeight === "function") {
      adjustRowHeight();
    }
  }, [opened]);

  // if you use the map
  // useLayoutEffect(() => {
  //   if (
  //     typeof toggleExpand === "function" &&
  //     selectedMapLocation?.uuid === rowData?.uuid
  //   ) {
  //     toggleExpand(!opened);
  //   }
  // }, [selectedMapLocation]);



  return (
    <div className={classes.join(" ") + ' elevateclickitem'} onClick={() => {
        console.log('Arrow was clicked!');
        toggleExpand({rowIndex, fleetId});
      }} >
      <div className={opened ? "arrow-expand" : "arrow-collapse"}>
        <img src={Arrow} alt={"arrow"} />
      </div>
    </div>
  );
}

function ButtonsList(props) {
  const { rowData, showDownload, inProcess, impersonationUser, detail } = props;
  const { usersLoading = true } = detail;
  // const { fleetPermissions } = Fleets.useContainer();
  // const { location_can_download_data } = fleetPermissions;
  const location_can_download_data = false;
  // const { location_can_preview_dashboard } = fleetPermissions;
  // const impersonationButtonContent = (function () {
  //   if (usersLoading) {
  //     return <LoadingSpinner className="inline" />;
  //   }
  //   if (!usersLoading && !impersonationUser) {
  //     return null;
  //   }

  //   const params = {};
  //   params.queryParams = {
  //     entry: `/dash/${rowData.id}`,
  //   };

  //   return (
  //     <ImpersonationButton
  //       className="button"
  //       userId={impersonationUser.user.user_id}
  //       params={params}
  //     >
  //       {inProcess ? "Dashboard" : "Preview"}
  //     </ImpersonationButton>
  //   );
  // })();
  return (
    <div className="button-list">
      {/* {location_can_preview_dashboard && impersonationButtonContent} */}
      {location_can_download_data && showDownload && (
        <ModalManager
          render={() => (
            <button className="download-data-button">Download</button>
          )}
          modalProps={{
            className: "download-data-modal overflow-visible",
            render: () => <DataDownloadModal location={rowData} />,
          }}
        />
      )}
    </div>
  );
}

function AppliancesInventory({ mobile }) {
  return mobile ? (
    <div>
      <h1 style={{ textAlign: "center", margin: "0px" }}>Mobile Support!</h1>
      <h2 style={{ textAlign: "center" }}>COMING SOON!</h2>
    </div>
  ) : (
    <div className="appliance-inventory-modal-content">
      <img src={AppliancesInventoryImage} alt="appliance-inventory-image" />
    </div>
  );
}

function LabelContent(props) {
  const labelRef = useRef(null);
  const { rowIndex, rows, setRowHeight } = props;
  // const { getDetail, getImpersonationUser } = LocationDetails.useContainer();
  const {id:fleetId} = useSelector(getFleet);
  // const {rows} = useSelector(getDashboard);
  const detail = rows[rowIndex]||{};
  // console.log('LABEL CONTENT', detail);
  // const { passiveFetchLocation, selectedMapLocation } = Explorer.useContainer();
  const passiveFetchLocation = () => void(0);
  const selectedMapLocation = rows[0];
  // const { fleetPermissions } = Fleets.useContainer();
  // const { location_can_preview_dashboard, location_can_edit_name } =
  //   fleetPermissions;
  const location_can_preview_dashboard = false, location_can_edit_name = false;

  const { rowData={}, adjustRowHeight, inProcess } = props;
  const { id="", loading=true, error=false, index=0 } = rowData;

  const { users = [],label="", opened:expanded=false } = detail;
  const { DEMO, DEMO_LOCATION_ID, CONNECT_URL } = CONFIG.values;

  const hasLocation = true; //rowData.hasOwnProperty("dt_created");

  const getImpersonationUser = () => void(0);

  const impersonationUser = getImpersonationUser(id);

  const impersonationLocationId = DEMO ? DEMO_LOCATION_ID : id;

  // const params = {
  //   queryParams: {
  //     entry: `/dash/${impersonationLocationId}/menu/${impersonationLocationId}/info`,
  //   },
  // };

  // const paramsForStatusHub = {
  //   host: CONNECT_URL,
  //   queryParams: {
  //     entry: `/locations/${impersonationLocationId}/`,
  //   },
  // };

  // const paramsForAddressEdit = {
  //   host: CONNECT_URL,
  //   queryParams: {
  //     entry: `/locations/${impersonationLocationId}/edit`,
  //   },
  // };

  // const paramsForWebDashboard = {
  //   queryParams: {
  //     entry: `/dash/${rowData.id}`,
  //   }
  // };

  // useEffect(() => {
  //   if (!hasLocation) {
  //     passiveFetchLocation(id);
  //   }
  //   adjustRowHeight();
  // }, [hasLocation]);

  useEffect(() => {
    if (labelRef.current) {
      if(expanded){
        setRowHeight(rowIndex, labelRef.current.clientHeight);
      }
    }
    // eslint-disable-next-line
  }, [labelRef]);

  if (!Array.isArray(detail.users)) {
    return (<div style={{width:"100%"}}>
      <LoadingSpinner className="inline" />
    </div>);
  }

  if (error) {
    return <ErrorWithTooltip error={error} />;
  }

  const openrow = () => {
    props.toggleExpand({rowIndex, fleetId})
    if(expanded){
      // console.log("xxxSETTING ROW HEIGHT is EXPANDED", labelRef.current.clientHeight, expanded )
      setRowHeight(rowIndex, 30);
    }
  }

  return (
    <div className="location-row-label" ref={labelRef}>
      <div className="location-label">
        <LocationExpandToggle
          {...props}
          expanded={expanded}
          toggleExpand={openrow}
          selectedMapLocation={selectedMapLocation}
        />
        <span data-testid={`location-label-${rowIndex}`} className="label-main" atr={id} onClick={() => { openrow(); }}>
          {label || "Unnamed location"}
        </span>
        {/* {expanded &&
          users.length > 0 &&
          // !inProcess &&
          // location_can_preview_dashboard &&
          // location_can_edit_name &&
          (
            <ImpersonationButton
              className="location-edit-link"
              loadingSpinnerClassName="inline full tiny"
              userId={
                impersonationUser ? impersonationUser?.user?.user_id : null
              }
              params={paramsForAddressEdit}
            >
              <FontAwesomeIcon
                className="link location-edit"
                icon="pencil-alt"
              />
            </ImpersonationButton>
          )} */}
      </div>
      {expanded && (
        <div className="row-expanded-section row-left-align">
          <Address {...detail} />
          <ButtonsList
            impersonationUser={impersonationUser}
            detail={detail}
            {...props}
          />
          <div className="row-expand-icon-section">
            {/* <ImpersonationButton
              userId={impersonationUser?.user?.user_id}
              loadingSpinnerClassName="inline full tiny"
              params={paramsForStatusHub}
            >
              <div className="row-expand-icons" style={{ marginLeft: "0rem" }}>
                <img src={gearIcon} alt="status-hub" />
              </div>
            </ImpersonationButton> */}
            {/* <ModalManager
              modalProps={{
                className: "appliance-inventory-modal-container",
                render: () => <AppliancesInventory mobile={true} />,
              }}
              render={() => (
                <div className="row-expand-icons" style={{ opacity: ".3" }}>
                  <img src={mobileIcon} alt="mobile-dahboard" />
                </div>
              )}
            /> */}
            <a href={`${process.env.REACT_APP_WEBAPP_URL}/?lid=${detail.uuid}`} target="_blank" rel="noopener noreferrer" data-testid={`impersonate-link-${rowIndex}`} >
              <div className="row-expand-icons">
                <img src={webIcon} alt="web-dahboard" />
              </div>
            </a>

            <ModalManager
              modalProps={{
                className: "appliance-inventory-modal-container",
                render: () => <AppliancesInventory />,
              }}
              render={() => (
                <div className="row-expand-icons">
                  <img
                    src={AppliancesInventoryIcon}
                    alt="appliances-inventory"
                  />
                </div>
              )}
            />
          </div>
        </div>
      )}
    </div>
  );
}

export default LabelContent;
// export default DynamicCell(LabelContent);
