import React, { useState } from 'react';
import { Form, Field } from 'react-final-form';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import range from 'lodash.range';
import CurbInput from '../../../shared/components/atoms/form/input.jsx';
import CurbSelect from '../../../shared/components/atoms/form/select.jsx';
import InputGroup from '../../../shared/components/atoms/form/input-group';
import currencies from '../../../shared/lib/currencies';
import AutoSave from '../forms/autosave';
import LoadingSpinner from '../atoms/loading-spinner';
import EnergyProjections from '../../data-containers/energy-projections';
import LocationDetails from '../../data-containers/location-details';
import StatusInline from '../atoms/status-inline';

export default function({location}){
  const { 
    possibleTariffs=[],
    loadingPossibleTariffs,
  } = EnergyProjections.useContainer();
  const { getDetail, updateLocationBilling, updateLocationTariff } = LocationDetails.useContainer();
  const [ isSubmitting, setIsSubmitting ] = useState(null);
  const [ success, setSuccess ] = useState(null);
  const [ error, setError ] = useState(null);

  const { billing, tariff, billingLoading, tariffLoading } = getDetail(location.id);

  const { day_of_month, type, simple_kwh_price, simple_currency_code } = billing || {};
  const { lseId, lseName, masterTariffId, tariffName } = tariff || {};
  const initialValues = {
    day_of_month, 
    type, 
    lseId, 
    lseName, 
    masterTariffId, 
    tariffName, 
    simple_kwh_price, 
    simple_currency_code
  };

  const loading = billingLoading || tariffLoading;

  const lses = possibleTariffs
    // filter out duplicate possibleTariffs by id
    .reduce(
      (acc, value) => {
        if(!acc.find((v) => v.lseId === value.lseId)){
          acc.push(value);
        }
        return acc;
      }, 
      []
    );

  const getTariffsForLseId = lseId => possibleTariffs.filter(t => t.lseId === lseId);

  const validatePlan = (v, {lseId, type}) => {
    if(loadingPossibleTariffs || loadingPossibleTariffs === null || loading || type === 'simple'){
      return;
    }
    return getTariffsForLseId(lseId).find(t => t.masterTariffId === v)
      ? undefined
      : 'Required';
  };

  const validateKWHPrice = (v, {type}) => {
    if(type === 'utility'){
      return;
    }

    if(!v){
      return 'Required';
    }
    
  };

  const onSubmit = async values => {
    try {
      setSuccess(null);
      setError(null);
      setIsSubmitting(true);
      let { 
        masterTariffId, 
        type, 
        day_of_month, 
        simple_kwh_price, 
        simple_currency_code 
      } = values;
      // update location_billing

      // coerce to number
      simple_kwh_price = Number(simple_kwh_price);
      
      // if masterTariffId has changed, set it as the location tariff
      const t = possibleTariffs.find(t => t.masterTariffId === masterTariffId);
      if(t && t.masterTariffId !== tariff.masterTariffId){
        await updateLocationTariff(location.id, t);
      }

      const newBilling = await updateLocationBilling(location.id, { type, day_of_month, simple_kwh_price, simple_currency_code });
      values.simple_kwh_price = newBilling.simple_kwh_price;
      setSuccess(true);
    } 
    catch(err){
      setError(err.message);
    }
    setIsSubmitting(false);
    return values;
  };

  if(!location.postcode){
    return <StatusInline type="error">Please select a zip code under "Location Information" before selecting an energy provider</StatusInline>;
  }

  return <Form
    initialValues={initialValues}
    keepDirtyOnReinitialize={true}
    onSubmit={() => {}}
    render={
      (renderProps) => {
        const { handleSubmit, form, values, invalid, pristine } = renderProps;
        const { lseId, type, masterTariffId } = values;
        const plans = getTariffsForLseId(lseId);

        const classes = ['curb-form', 'billing-form', 'energy-projections-associated-form'];
        if(!billing || !tariff){
          classes.push('busy');
        }
      
        return <form 
          className={classes.join(' ')} 
          onSubmit={handleSubmit}>
          <Field
            label="Billing day of month"
            name="day_of_month"
            parse={v => Number(v)}
            placeholder="Select a utility provider"
            render={CurbSelect}>
            {range(1,32).map(day => {
              return (<option value={day} key={day}>{day}</option>);
            })}
          </Field>

          <InputGroup
            label="Billing Type"            
            name="type"
            options={[
              {
                label: 'Utility Rate',
                value: 'utility'
              },
              {
                label: 'Simple Rate',
                value: 'simple'
              }
            ]} />

          {
            type === 'utility'  
              ? <React.Fragment>
                <Field
                  label="Utility Provider"
                  name="lseId"
                  disabled={loading || loadingPossibleTariffs}
                  parse={v => Number(v)}
                  render={CurbSelect}>
                  {
                    lses.map(
                      (lse, i) => <option key={i} value={lse.lseId}>{lse.lseName}</option>
                    )
                  }
                </Field>

                <Field
                  label="Utility Plan"
                  name="masterTariffId"
                  parse={v => Number(v)}
                  validate={validatePlan}
                  disabled={loading || loadingPossibleTariffs}
                  activeError={true}
                  render={CurbSelect}>
                  <option value="" disabled={!!plans.find(p => p.masterTariffId === masterTariffId)}>Select a plan</option>
                  {
                    plans
                      .map(t => <option value={t.masterTariffId} key={t.masterTariffId}>{t.tariffName}</option>)
                  }
                </Field>
              </React.Fragment>
            : <React.Fragment>
                <Field
                  label="Price per kWh"
                  name="simple_kwh_price"
                  validate={validateKWHPrice}
                  type="number"
                  step="0.1"
                  disabled={type !== "simple"}
                  render={CurbInput} />
                <Field
                  label="Currency"
                  name="simple_currency_code"
                  render={CurbSelect}>
                  {
                    Object.keys(currencies).map(
                      currencyId => {
                        const currencyGroup = currencies[currencyId];
                        return <option value={currencyId} key={currencyId}>{currencyGroup.symbol} ({currencyGroup.name_plural})</option>;
                      }
                    )
                  }
                </Field>

            </React.Fragment>
          }

          <div className="curb-form-element curb-submit">
            &nbsp;
            <AutoSave
              invalid={invalid || pristine}
              interval={300}
              save={
                async values => {
                  const { simple_kwh_price } = await onSubmit(values);
                  // reinitialize after save
                  form.initialize({
                    ...values,
                    simple_kwh_price
                  });
                }
              }              
              values={values}
              render={() => {
                if(!isSubmitting && !loading && !loadingPossibleTariffs){
                  return null;
                }
                return <LoadingSpinner className="inline" />;
              }} />
              {
                (pristine && success) && <FontAwesomeIcon icon="check" className="success" /> 
              }
              {
                error && <StatusInline type="error">{error}</StatusInline>
              }
          </div>
        </form>
      }
    }    
    />
}