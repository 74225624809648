import React, { useReducer, useEffect } from 'react';
import ResizableTable from '../../../atoms/table/resizable-table';
import LoadingSpinner from '../../../atoms/loading-spinner';

class LocationsExplorerTableInner extends React.Component {
  constructor(props){
    super(props);
    
    this.cellMeasurerCacheProps = {
      // because these rows can be expanded, add the expanded flag to the cache key
      // otherwise an expanded state could be used as a cache for the default state
      // so effectively there can be two cache entries per row
      keyMapper: (rowIndex, columnIndex)=>{
        const { opened } = this._resizableTable.rowGetter({index: rowIndex}) || {opened: false};
        return `${rowIndex}_${columnIndex}_expanded_${opened ? 1 : 0}`;
      }
    };
  }

  _setResizableTableRef(ref){
    if(ref){
      this._resizableTable = ref;
    }
  }

  noRowsRenderer(){
    const { loading } = this.props;
    const content = (function(){
      if(!loading){
        return <LoadingSpinner />;
      }
      return <h2>No results found</h2>;  
    })();
    return <div className="explorer-status">{ content }</div>
  }

  render(){
    return <ResizableTable
      ref={this._setResizableTableRef.bind(this)}
      noRowsRenderer={this.noRowsRenderer.bind(this)}
      cellMeasurerCacheProps={this.cellMeasurerCacheProps}
      {...this.props}   />;
  }
}

export default function(props){
  const { rows=[], generateColumns, toggleExpand } = props;
  const columns = generateColumns({toggleExpand});;
  const transferProps = {
    columns,
    list:rows,
    ...props,
  };

  return <LocationsExplorerTableInner {...transferProps} />
}