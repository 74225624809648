// https://fontawesome.com/cheatsheet
import { library } from "@fortawesome/fontawesome-svg-core";
// https://fontawesome.com/icons?d=gallery&m=free
import {
  faBars,
  faCaretDown,
  faCheck,
  faTimes,
  faSort,
  faSortUp,
  faSortDown,
  faTimesCircle,
  faSearch,
  faEdit,
  faExternalLinkAlt,
  faPencilAlt,
  faMinus,
  faMinusCircle,
  faPlus,
  faPlusCircle,
  faEnvelope,
  faExclamationCircle,
  faQuestionCircle,
} from "@fortawesome/free-solid-svg-icons";
// https://fontawesome.com/icons?d=gallery&s=regular&m=free
// import * as regulars from '@fortawesome/free-regular-svg-icons';

library.add(
  faBars,
  faCaretDown,
  faCheck,
  faTimes,
  faSort,
  faSortUp,
  faSortDown,
  faTimesCircle,
  faSearch,
  faEdit,
  faExternalLinkAlt,
  faPencilAlt,
  faMinus,
  faMinusCircle,
  faPlus,
  faPlusCircle,
  faEnvelope,
  faExclamationCircle,
  faQuestionCircle
  //
  // faSortEmpty
);

export const arrowUpDown = "↓↑";
export const arrowUp = "↑";
export const arrowDown = "↓";
