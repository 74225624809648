import React, { useState } from 'react';
import omit from 'lodash.omit';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Form, Field } from 'react-final-form';
import CurbInput from '../../../shared/components/atoms/form/input.jsx';
import PrintError from '../atoms/print-error';
import * as API from '../../lib/api';

const validate = function(values){
  var errors = {};

  if(values.new_password !== values.confirm_new_password){
    errors.confirm_new_password = 'Passwords must match';
  }

  if(values.new_password && values.new_password.length < 8){
    errors.new_password = 'New password must be at least 8 characters';
  }

  if(!values.password){
    errors.password = 'Required';
  }
  if(!values.new_password){
    errors.new_password = 'Required';
  }
  if(!values.confirm_new_password){
    errors.confirm_new_password = 'Required';
  }

  return errors;
};

export default function(){
  const [ success, setSuccess ] = useState(null);
  const [ error, setError ] = useState(null);

  const onSubmit = async values => {
    try {
      setSuccess(null)
      setError(null);
      await API.updateAdminUser(omit(values, 'confirm_new_password')); 
      setSuccess(true);
    }
    catch(err){
      console.error(err);
      if(err.message && err.message.error_description){
        setError(new Error(err.message.error_description));
      }
      else {
        setError(err);
      }
    }
  };
  
  return <Form
    onSubmit={onSubmit}
    validate={validate}
    render={
      ({handleSubmit, invalid, submitting}) => {
        const classes = ['curb-form', 'password-reset-form'];
        if(submitting){
          classes.push('busy');
        }
        return <form 
          onSubmit={handleSubmit}
          className={classes.join(' ')}>

          <Field
            name="password"
            type="password"
            label="Current Password"
            autoComplete="off"
            required={true}
            render={CurbInput} />

          <Field
            name="new_password"
            type="password"
            label="New Password"
            autoComplete="off"
            required={true}
            render={CurbInput} />

          <Field
            name="confirm_new_password"
            type="password"
            label="Confirm New Password"
            autoComplete="off"
            required={true}
            render={CurbInput} />

          <div className="curb-form-element curb-submit">
            { success && <div className="curb-form-element">
              <FontAwesomeIcon icon="check" className="success" /> 
            </div> }
            <button disabled={invalid} type="submit">Apply</button>
          </div>
          { error && <div className="curb-form-element status-message error">
            <PrintError error={error} />
          </div> }
        </form>
      }
    }/>
};