import React, { useEffect, useState } from 'react';
import { createContainer } from 'unstated-next';
import cloneDeep from 'lodash.clonedeep';
import * as API from '../lib/api';

function useFleets(){
  const [ fleets, setFleets ] = useState([]);
  const [ nestedFleets, setNestedFleets ] = useState([]);
  const [ currentFleetId, setCurrentFleetId ] = useState();
  const [ currentFleet, setCurrentFleet ] = useState();
  const [ loadingPermissions, setLoadingPermissions ] = useState();
  const [ permissionsError, setPermissionsError ] = useState();
  const [ fleetPermissions, setFleetPermissions ] = useState({});

  const fetchPermissions = async () => {
    try {
      setPermissionsError(null);
      setLoadingPermissions(true);
      setFleetPermissions({});
      const permissions = await API.getPermissions(currentFleetId);
      if(permissions){
        setFleetPermissions(permissions);
      }
    }
    catch(err){
      setPermissionsError(err)
    }
    setLoadingPermissions(false);
  };

  const getNested = (fleets) => {
    return cloneDeep(fleets).reduce(
      (acc, v, i, src) => {
        let parent = v.parent_fleet 
          ? src.find(f => f.id === v.parent_fleet.id)
          : null;
        if(parent){
          parent.children = parent.children || [];
          parent.children.push(v);  
        }
        else {
          acc.push(v);
        }
        return acc;
      },
      []
    )
    .sort(
      (a, b) => {
        return (a.parent_fleet || '') < (b.parent_fleet || '')
          ? -1
          : 1;
      }
    );
  }

  // save current fleet as an effect
  useEffect(
    // () => {
    //   const matchingFleet = fleets.find(fleet => fleet.id === currentFleetId);
    //   if(matchingFleet){
    //     setCurrentFleet(matchingFleet);
    //   }
    // },
    // [fleets, currentFleetId]
  );

  useEffect(
    // () => {
    //   setNestedFleets(getNested(fleets));
    // },
    // [fleets]
  )

  // fetch permissions
  useEffect(
    // () => {
    //   if(currentFleetId){
    //     fetchPermissions();
    //   }
    // },
    // [currentFleet]
  );

  return {
    fleets,
    nestedFleets,
    currentFleet,
    fleetPermissions,
    loadingPermissions, 
    permissionsError,  
    getNested, 
    //
    setFleets,
    setCurrentFleet,
    setCurrentFleetId
  };
};

export default createContainer(useFleets);
