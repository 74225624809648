import React from 'react';

export default function(props){
  const {
    input,
    meta,
    label,
    //
    required,
    tag="input",
    id,
    disabled,
    step,
    pattern,
    maxLength,
    autoComplete,
    autoCapitalize,
    autoFocus,
    placeholder
  } = props;

  const Tag = tag;

  const { name } = input;
  const hasError = meta.touched && meta.error;
  let { className } = props;

  let additionalInputProps = {
    required,
    id,
    disabled,
    step,
    pattern,
    maxLength,
    autoComplete,
    autoCapitalize,
    autoFocus,
    placeholder
  };

  if(!className){
    className = `input-${name}`;
  }
  if(!Array.isArray(className)){
    className = [className];
  }
  if(hasError){
    className.push('error');
  }
  className = className.concat(['curb-input', 'curb-form-element']);

  return (
    <div className={className.join(' ')}>
      {
        label && <label htmlFor={id}>{label}</label>
      }
      <Tag {...input} {...additionalInputProps} />
      {hasError && <div className="error-msg">{meta.error}</div>}
    </div>
  );
}
