import React, { useEffect, useRef, useReducer } from 'react';
import merge from 'lodash.merge';
import { createContainer } from 'unstated-next';
// import { faker } from '@faker-js/faker';
import URLState from './url-state';
import ExplorerState from './explorer';
import * as API from '../lib/api';
import { mutexifyAsync, memoize } from '../lib/utils';
import CONFIG from '../lib/config';

const detailReducer = (state, { type, id, dateRangeId, hubSerial, payload={} }) => {
  if(!id){
    return state;
  }
  switch(type){
    case 'add':
      if(!state.find(m => m.id === id)){
        return state.concat([{
          id,
          ...payload
        }]);
      }
      return state;
    case 'update':
      return state.map(
        m => {
          if(m.id === id){     
            m = {
              ...m,
              ...payload
            }
          }
          return m;
        }
      );
    case 'updateHistorical':
      return state.map(
        m => {
          if(m.id === id){
            const historical = m.historical || {}
            const existing = historical[dateRangeId] || {};
            m = {
              ...m,
              historical: {
                ...historical,
                [dateRangeId]: {
                  ...existing,
                  ...payload
                }
              }
            }
          }
          return m;
        }
      );
    case 'updateInstallation':
      return state.map(
        m => {
          if(m.id === id){
            m = {
              ...m,
              installations: m.installations.map(
                inst => {
                  if(inst.hub === hubSerial){
                    inst = merge(inst, payload);
                  }
                  return inst;
                }
              )              
            };
          }
          return m;
        }
      );
    case 'clearAll':
      return [];  
    default:
      return state;
  }
}

export function useLocationDetails() {
  const { DEMO, DEMO_UTILITY_NAMES } = CONFIG.values;
  const { fleetId } = URLState.useContainer();
  const { 
    removeLocationFromInactive
  } = ExplorerState.useContainer();
  // using a reducer hook here allows multiple state updates to happen synchronously where subsequent updates depend on the previous
  const [ locationDetailsList, dispatch ] = useReducer(detailReducer, []);

  const temp = useRef(null);

  const getDetail = (id) => {
    const existing = locationDetailsList.find(l => l.id === id);
    if(existing){
      return existing;
    }
    dispatch({ type: 'add', id, payload: { id }});
  };

  const updateDetail = (id, payload) => {
    /*
    if(DEMO && !searchTerm){
      if(payload.users){
        payload.users = payload.users.map(
          u => {
            u.user = {
              ...u.user,
              name: `${faker.name.firstName()} ${faker.name.lastName()}`,
              email_address: faker.internet.email(),
              phone_number: faker.phone.phoneNumberFormat(1)
            };
            return u;
          }
        );
      }
      if(payload.tariff){
        payload.tariff = {
          ...payload.tariff,
          lseName: DEMO_UTILITY_NAMES[ Math.floor(Math.random() * DEMO_UTILITY_NAMES.length) ]
        };
      }
    }
    */

    dispatch({ type: 'update', id, payload });
  };

  const getInstallation = (id, hubSerial) => {
    const { installations=[] } = getDetail(id) || {};
    return installations.find(inst => inst.hub === hubSerial);
  }

  const addUserToLocationByEmail = async (location, email) => {
    const response = await API.addUserToLocationByEmail(fleetId, location.id, email);
    addLocationToActive(location);
    removeLocationFromInactive(location);
    fetchUsers(location.id, {}, { silent: true, forceFetch: true });
    return response;
  };

  const updateUserDetail = async(id, userId, userDetail) => {
    const { users=[] } = getDetail(id);
    const response = await API.updateUserDetail(fleetId, userId, userDetail);
    if(response){
      updateDetail(
        id, 
        {
          users: users.map(
            u => {
              if(u.user.user_id === response.user_id){
                u.user = response;
              }
              return u;
            }
          )
        }
      );
    }
    return response;
  };

  const removeUser = async (id, userAccessId) => {
    const { users=[] } = getDetail(id);
    updateDetail(
      id, 
      {
        users: users.filter(ua => ua.id !== userAccessId)
      }      
    );
  };

  const updateLocationBilling = async (id, billing) => {
    const response = await API.updateLocationBilling(fleetId, id, billing);
    updateDetail(
      id, {
        billing: response
      }
    );
    return response;
  };

  const updateLocationTariff = async (id, tariff) => {
    const { masterTariffId } = tariff;
    const response = await API.updateLocationTariff(fleetId, id, masterTariffId);
    updateDetail(id, {
      tariff
    });
    return response;
  };

  const getUsersFiltered = (id, options={}) => {
    const { allowUser } = options;
    const { users=[] } = getDetail(id) || {};
    return users.filter(
      // explicitly allow allowUser if used
      // otherwise, show non fleet and non installer users
      u => (allowUser && u.user.user_id === allowUser) || (u.user && ['fleet', 'installer'].indexOf(u.user.user_type) === -1)
    );
  };

  const getImpersonationUser = (id) => {
    const { users=[] } = getDetail(id) || {};
    return getUsersFiltered(id).concat(users)[0];
  }

  const fetchUsers = async (id, params, options={}) => {
    const { silent } = options;
    try {
      if(!silent){
        updateDetail(id, { 
          usersLoading: true,
          usersError: null
        });  
      }
      let users = await API.locationListUserAccesses(fleetId, id, params, options); 
      updateDetail(id, { 
        users,
        usersLoading: false
      });  
    } catch(err) {
      if (!err.cancelled) console.error(err);
      if(!silent){
        updateDetail(id, {
          usersLoading: false,
          usersError: err.cancelled ? null : err
        });  
      }
    }
  };

  const fetchTariff = async (id) => {
    try {
      updateDetail(id, { 
        tariffLoading: true,
        tariffError: null
      });
      let tariff = await API.getLocationTariff(fleetId, id); 
      updateDetail(id, { 
        tariff,
        tariffLoading: false
      });
    } catch(err) {
      updateDetail(id, {
        tariffLoading: false,
        tariffError: err.cancelled ? null : err
      });
    }
  };

  const fetchInstallations = async (id, options={}) => {
    const { silent, omitAddToActive } = options;
    try {
      if(!silent){
        updateDetail(id, {
          installationsLoading: true,
          installationsError: null
        });
      }
      let response = await API.locationListInstallations(fleetId, id, {}, options);
      let installations = response.results;
      
      updateDetail(id, { 
        installations,
        installationsLoading: false,
        fleet: installations.length
          ? installations[0].fleet
          : undefined
      }); 
    } 
    catch(err) {
      if (!err.cancelled) console.error(err);
      if(!silent){
        updateDetail(id, {
          installationsLoading: false,
          installationsError: err.cancelled ? null : err
        });
      } 
      else {
        updateDetail(id, {
          installations: []
        })
      }
    }
  };

  const fetchInstallationsExtended = async (id, query, options) => {
    try {
      updateDetail(id, {
        installationsLoading: true,
        installationsError: null
      });
      let response = await API.locationListInstallationsExtended(fleetId, id, query, options);
      let installations = response.results;
      
      updateDetail(id, { 
        installations,
        installationsLoading: false,
        fleet: installations.length
          ? installations[0].fleet
          : undefined
      });
    } 
    catch(err) {
      updateDetail(id, {
        installations: [],
        installationsError: err.cancelled ? null : err
      });
    }
    updateDetail(id, {
      installationsLoading: false
    });
  };

  const fetchHubConnectivity = async (id, hubSerial) => {
    const addPayload = payload => {
      dispatch({
        type: 'updateInstallation',
        id,
        hubSerial,
        payload
      });
    };

    const updateStatus = status => {
      addPayload({
        hub_connectivity_fetch_status: status
      })
    };

    try {
      updateStatus({
        error: null,
        loading: true
      });

      addPayload({        
        hub_connectivity: await API.getHubConnectivity(fleetId, hubSerial, {}, { locationId: id }),
      });
  
    }
    catch(err){
      if(!err.cancelled){
        updateStatus({
          error: err
        })
      }
    }
    updateStatus({
      loading: false
    });
  }

  const fetchHubObject = async (id, hubSerial) => {
    const addPayload = payload => {
      dispatch({
        type: 'updateInstallation',
        id,
        hubSerial,
        payload
      });
    };
    const updateStatus = status => {
      addPayload({
        hub_object_fetch_status: status
      })
    };
    try {
      updateStatus({
        error: null,
        loading: true
      });

      addPayload({        
        hub_object: await API.getHub(fleetId, hubSerial, {}, { locationId: id }),
      });
    }
    catch(err){
      if(!err.cancelled){
        updateStatus({
          error: err
        })
      }
    }
    updateStatus({
      loading: false
    });
  }

  // historical
  const updateHistorical = (id, dateRangeId, payload) => {
    dispatch({ type: 'updateHistorical', id, dateRangeId, payload });
  };

  const fetchHistoricalForLocation = async (id, params) => {
    const { dateRange: { id:dateRangeId, aggregateParams, timeseriesParams } } = params;
    try {
      updateHistorical(
        id,
        dateRangeId,
        {
          loading: true,
          error: null,
        }
      );
      
      let totals=[]; 
      let response;
      if(aggregateParams){
        response = await API.locationGetAggregate(fleetId, id, aggregateParams);
        const { results } = response;
        if(results?.totals){
          totals = [response.results.totals];
        }
      }
      else if(timeseriesParams) {        
        response = await API.locationGetTimeseries(fleetId, id, timeseriesParams);
        const { results=[], interval_bands } = response;

        totals = results
          .filter(
            (_,i) => interval_bands[i] && !interval_bands[i].incomplete
          )
          .map(({tot}) => {
            const totals = Object.entries(tot).map(
              ([k, v]) => ({
                type: k,
                ...v
              })
            );

            return totals;
          })
          .reverse();
      }

      // add offset if we have both production and consumption
      for(const total of totals){
        const prod = total.find(({type}) => type === 'prod');
        const cons = total.find(({type}) => type === 'cons');
        if(prod && cons){
          total.push({
            type: 'offset',
            percent: prod.kwh/cons.kwh
          });
        }
      }

      // compute deltas
      const [curr, prev] = totals;
      let deltas=[];
      if(prev && curr){
        for(const { type, ...rest} of curr){
          const prevTotal = prev.find((total) => total.type === type);
          const values = {};

          // k is "kwh" or other metric, v is a number
          for(const [k, v] of Object.entries(rest)){
            const diff = v - prevTotal[k];
            values[k] = diff;
            values[`${k}_percent`] = diff/prevTotal[k];
          }

          deltas.push({
            type,
            ...values
          });
        }
      }

      updateHistorical(
        id,
        dateRangeId,
        {
          loading: false,
          error: null,
          data: {
            totals,
            deltas,
            response
          }
        }
      );
    }
    catch(err){
      if (!err.cancelled) console.error(err);
      updateHistorical(
        id, 
        dateRangeId,
        (err?.status === 404 || err?.status === 500)
          ? {
            loading: false,
            error: null,
            data: null
          }
          : { 
            loading: false,
            error: err.cancelled ? null : err,
            data: null
          }        
      );
    }
  }

  const fetchAggregate = async (id, params) => {
    const { range } = params;
    const { kwh={} } = getDetail(id) || {};
    try {
      updateDetail(
        id, 
        {
          kwh: {
            ...kwh,
            [range]: {
              ...existing,
              error: null,
              loading: true
            }  
          }
        }
      );
      const aggregate = await API.locationGetAggregateToNearestInterval(fleetId, id, { range });
      updateDetail(
        id, 
        { 
          [range]: {
            ...existing,
            loading: false,
            aggregate
          }
        }
      );
    } catch(err) {
      if (!err.cancelled) console.error(err);
      updateDetail(
        id, 
        { 
          [range]: {
            ...existing,
            loading: false,
            error: err.cancelled ? null : err
          }
        }
      );
    }
  };

  const fetchMetadata = async (id, params) => {
    try {
      // updateDetail(id, {
      //   metadataLoading: true,
      //   metadataError: null
      // });
      // const metadata = await API.locationGetMetadata(fleetId, id, params, { locationId: id });
      // updateDetail(id, {
      //   metadata,
      //   metadataLoading: false
      // })
    }
    catch(err){
      if (!err.cancelled) console.error(err);
      updateDetail(id, {
        metadataError: err.cancelled ? null : err,
        metadataLoading: false
      });
    }
  };

  const fetchBilling = async (id) => {
    try {
      updateDetail(id, {
        billingLoading: true,
        billingError: null
      });
      const billing = await API.getLocationBilling(fleetId, id);
      updateDetail(id, {
        billing,
        billingLoading: false
      })
    }
    catch(err){
      if (!err.cancelled) console.error(err);
      updateDetail(id, {
        billingError: err.cancelled ? null: err,
        billingLoading: false
      });
    }
  };

  const addInstallation = async (id, hubSerial) => {
    return await API.updateInstallation(fleetId, hubSerial, { location: id, hub: hubSerial });
  };

  useEffect(
    () => {
      temp.current = {};
    },
    []
  );

  useEffect(
    () => {
      temp.current = {};   
      dispatch({ type: 'clearAll' });
    },
    [fleetId]
  );

  return {
    getDetail,
    updateDetail,
    locationDetailsList,
    addInstallation,
    fetchMetadata,
    fetchUsers,
    fetchInstallations,
    fetchTariff,
    removeUser,
    getUsersFiltered,
    getImpersonationUser,
    addUserToLocationByEmail,
    updateUserDetail,    
    updateLocationBilling,
    updateLocationTariff,
    passiveFetchMetadata: mutexifyAsync(async function(id){
      const { metadataLoading, metadata } = getDetail(id) || {};
      if(metadata || metadataLoading){
        return metadata;
      }
      return fetchMetadata.apply(null, arguments);
    }),
    passiveFetchUsers: mutexifyAsync(async function(id){
      const { usersLoading, users } = getDetail(id) || {};
      if(users || usersLoading){
        return users;
      }
      return fetchUsers.apply(null, arguments);
    }),
    passiveFetchInstallations: mutexifyAsync(async function(id){
      const { installationsLoading, installations } = getDetail(id) || {};

      if(installations || installationsLoading){
        return installations;
      }
      return fetchInstallations.apply(null, arguments);
    }),
    passiveFetchTariff: mutexifyAsync(function(id){
      const { tariffLoading, tariff } = getDetail(id) || {};
      if(tariff || tariffLoading){
        return tariff;
      }
      return fetchTariff.apply(null, arguments);
    }),
    passiveFetchBilling: mutexifyAsync(function(id){
      const { billingLoading, billing } = getDetail(id) || {};
      if(billing || billingLoading){
        return billing;
      }
      return fetchBilling.apply(null, arguments);
    }),
    passiveFetchAggregate: mutexifyAsync(function(id, params){
      const { range } = params;
      const detail = getDetail(id) || {};
      const { loading, aggregate } = detail[range] || {};
      if(aggregate || loading){
        return aggregate;
      }
      return fetchAggregate.apply(null, arguments);
    }),
    fetchHubConnectivity: memoize(fetchHubConnectivity),
    fetchHubObject: memoize(fetchHubObject),
    fetchHistoricalForLocation: memoize(fetchHistoricalForLocation),
    fetchInstallationsExtended: memoize(fetchInstallationsExtended)
  };
};

export default createContainer(useLocationDetails);