// https://medium.com/@david.gilbertson/modals-in-react-f6c3ff9f4701
import React, { Component } from 'react';
import { createPortal } from 'react-dom';
import throttle from 'lodash.throttle';

const possiblePlacements = [
  'top',
  'right',
  'bottom',
  'left'
];

const tooltipRoot = document.getElementById('tooltip-wrapper');

export default class Tooltip extends Component {
  constructor(props){
    super(props);
    const { eventEmitter } = props;

    // default state
    this.state = {
      render: props.render,
      target: props.target,
      placement: 'top',
      className: '',
      margin: 10,
      ...(props.initialState || {})
    };

    this.el = document.createElement('div');
    this.container = React.createRef();

    this.attemptedPlacements = [];

    this.clearTooltip = this.clearTooltip.bind(this);
    this.toggleTooltip = this.toggleTooltip.bind(this);
    this.updateTooltip = payload => this.setState(payload);
    this.getTooltipState = () => this.state;

    if(eventEmitter){
      // tooltips can be namespaced so multiple can be managed at once
      this.eventPrefix = props.namespace ? `${props.namespace}.` : '';
      // these are for programmatic control of the tooltip from components
      eventEmitter.on(`${this.eventPrefix}updateTooltip`, this.updateTooltip);
      eventEmitter.on(`${this.eventPrefix}clearTooltip`, this.clearTooltip);
      eventEmitter.on(`${this.eventPrefix}toggleTooltip`, this.toggleTooltip);
      eventEmitter.on(`${this.eventPrefix}getTooltipState`, this.getTooltipState);
    }
  }

  static getDerivedStateFromProps(props, state){
    const { initialState={} } = props;
    const newState = {};

    ['render', 'target', 'show', 'margin', 'placement'].forEach(
      key => {
        if(initialState.hasOwnProperty(key) && initialState[key] !== state[key]){
          newState[key] = initialState[key];
        }
      }
    )

    return Object.keys(newState).length
      ? newState
      : null;
  }

  bindEvents(props){
    // tooltip
    this.container.current.addEventListener(
      'click',
      e => {
        try {
          // prevent a click from bubbling up to document and closing tooltip
          // unless it's a link click in which case it does need to go to the document to get handled properly
          if(e.target.tagName.toUpperCase() !== 'A'){
            e.stopPropagation();
          }
        }
        catch(err){
          console.error(err);
        }
      }
    );
    this.container.current.addEventListener(
      'mouseenter',
      () => {
        this._isHovering = true;
      }
    );
    this.container.current.addEventListener(
      'mouseleave',
      () => {
        this._isHovering = false;
      }
    );

    this.onWindowResize = throttle(this.onWindowResize.bind(this), 500);
    window.addEventListener('resize', this.onWindowResize);
  }

  clearTooltip(){
    this.setState({
      show: false,
      render: null,
      margin: 10,
      className: ''
    });
  }

  toggleTooltip(params){
    this.setState({
      show: !this.state.show,
      ...params
    });
  }

  componentWillUnmount(){
    const { eventEmitter } = this.props;
    tooltipRoot.removeChild(this.el);
    window.removeEventListener('resize', this.onWindowResize);
    if(eventEmitter){
      eventEmitter.off(`${this.eventPrefix}updateTooltip`, this.updateTooltip);
      eventEmitter.off(`${this.eventPrefix}clearTooltip`, this.clearTooltip);
      eventEmitter.off(`${this.eventPrefix}toggleTooltip`, this.toggleTooltip);
      eventEmitter.off(`${this.eventPrefix}getTooltipState`, this.getTooltipState);
    }
  }
  componentDidMount(){
    tooltipRoot.appendChild(this.el);
    this.arrow = this.container.current.querySelector('.tooltip-arrow');
    this.place();
  }
  onWindowResize(){
    if(this.state.show){
      this.position();
    }
  }
  getTargetCoords(target, placement){
    const { margin } = this.state;
    if(Array.isArray(target) && target.length === 2){
      switch(placement){
        case 'top':
          return [
            target[0],
            target[1] - margin
          ];
        case 'right':
          return [
            target[0] + margin,
            target[1]
          ];
        case 'bottom':
          return [
            target[0],
            target[1] + margin
          ];
        case 'left':
          return [
            target[0] - margin,
            target[1]
          ];
      }
    }
    if(target instanceof HTMLElement || target instanceof SVGElement){
      let { top, right, bottom, left, width, height} = target.getBoundingClientRect();
      switch(placement){
        case 'top':
          return [
            left + width/2,
            top - margin
          ];
        case 'right':
          return [
            right + margin,
            top + height/2
          ];
        case 'bottom':
          return [
            left + width/2,
            bottom + margin
          ];
        case 'left':
          return [
            left - margin,
            top + height/2
          ];
      }
    }
  }

  position(placement=this.state.placement){
    const { target } = this.state;
    if(!target){
      return null;
    }
    var { x, y, arrowOffsetX, arrowOffsetY } = this.getTooltipCoords(target, placement);

    if(this.isOutsideViewport(x, y)){
      this.attemptedPlacements.push(placement);
      // try to get a fallback placement
      var fallbackPlacement = this.getFallbackPlacement(placement);
      // if the fallback placement has already been tried, then just cycle through the possible placements
      if(this.attemptedPlacements.indexOf(fallbackPlacement) > -1){
        for(var i=0; i<possiblePlacements.length; i++){
          let p = possiblePlacements[i];
          if(this.attemptedPlacements.indexOf(p) === -1){
            return this.position(p);
          }
        }
      }
      else {
        return this.position(fallbackPlacement);
      }
    }

    this.attemptedPlacements = [];

    this.container.current.classList.remove(...possiblePlacements);
    this.container.current.classList.add(placement);
    this.container.current.style.transform = `translate(${Math.round(x)}px, ${Math.round(y)}px)`;
    this.arrow.style.transform = `translate(${Math.round(arrowOffsetX)}px, ${Math.round(arrowOffsetY)}px)`;
  }

  getFallbackPlacement(placement){
    const { fallbackPlacement } = this.state;
    if(fallbackPlacement){
      return fallbackPlacement;
    }
    switch(placement){
      case 'top':
        return 'bottom';
      case 'right':
        return 'left';
      case 'bottom':
        return 'top';
      case 'left':
        return 'right';
    }
  }

  isOutsideViewport(x, y){
    const width = this.container.current.offsetWidth;
    const height = this.container.current.offsetHeight;
    return (
      x < window.scrollX
      ||
      y < window.scrollY
      ||
      x + width > window.scrollX + window.innerWidth
      ||
      y + height > window.scrollY + window.innerHeight
    );
  }

  getTooltipCoords(target, placement){
    var x = null,
        y = null,
        arrowOffsetX = 0,
        arrowOffsetY = 0;

    const width = this.container.current.offsetWidth;
    const height = this.container.current.offsetHeight;

    const [targetX, targetY] = this.getTargetCoords(target, placement);

    const checkForHorizontalOffset = () => {
      var offset = Math.min(x, window.scrollX);
      offset = offset ? offset : Math.max(x + width - (window.innerWidth + window.scrollX), 0);
      x -= offset;
      arrowOffsetX = offset;
    };

    const checkForVerticalOffset = () => {
      var offset = Math.min(y, window.scrollY);
      offset = offset ? offset : Math.max(y + height - (window.innerHeight + window.scrollY), 0);
      y -= offset;
      arrowOffsetY = offset;
    };

    switch(placement){
      case 'top':
        x = targetX - width / 2;
        y = targetY - height;
        checkForHorizontalOffset();
        break;
      case 'bottom':
        x = targetX - width / 2;
        y = targetY;
        checkForHorizontalOffset();
        break;
      case 'right':
        x = targetX;
        y = targetY - height/2;
        checkForVerticalOffset();
        break;
      case 'left':
        x = targetX - width;
        y = targetY - height/2;
        checkForVerticalOffset();
        break;
    }

    return {
      x: x + window.scrollX,
      y: y + window.scrollY,
      arrowOffsetX,
      arrowOffsetY
    };

  }

  place(){
    const { show, target } = this.state;
    if(show && target){
      this.position();
      this.bindEvents();
    }
  }

  componentDidUpdate(){
    this.place();
  }

  render(){
    let { className, show, render, namespace='global' } = this.state;

    let classes = ['tooltip'];

    if(className){
      classes.push(className);
    }

    if(namespace){
      classes.push(`tooltip-${namespace}`);
    }

    return createPortal(
      (
        <div
          ref={this.container}
          className={`${classes.join(' ')}`}
          style={
            {
              display: show && render ? 'block' : 'none'
            }
          }>
          {
            show && typeof render === 'function' && (
              <div
                className="tooltip-content">
                {render(this.props, this.state)}
              </div>
            )
          }
          <div className="tooltip-arrow" />
        </div>
      ),
      this.el
    );

  }
}