import React from "react";
import TabLayout from "../../../../shared/components/atoms/tab-layout";
import { clearQueues } from "../../../../shared/lib/api-queue";
// import URLState from "../../../data-containers/url-state";
// import Explorer from "../../../data-containers/explorer";
import LocationsExplorer from "./locations/";
// import InactiveLocationsExplorer from "./locations/inactive-locations";
// import AvailableExplorer from "./available/";

export default function ExplorerMain() {
  // const { includeAllSubfleets } = URLState.useContainer();
  const includeAllSubfleets = true;
  // const { setActiveTab } = Explorer.useContainer();
  const setActiveTab = () => "active";
  const tabs = [
    // {
    //   id: "available",
    //   render: () => <div>Inventory</div>,
    // },

    {
      id: "active",
      active: true,
      render: () => <div>Active</div>,
    },
    // {
    //   id: "inactive",
    //   render: () => <div>Inactive</div>,
    // }
  ];

  const getTableKey = (namespace) => {
    const keyComponents = [namespace];
    if (includeAllSubfleets) {
      keyComponents.push("includeAllSubfleets");
    }
    return keyComponents.join(":");
  };

  /* 
  adding the `key` prop to each explorer table is important to signal to React to construct a new instance of each
  otherwise, it will try to reuse component instances which are stateful and could cause problems
  */
  const renderContent = function (activeTab) {
    switch (activeTab.id) {
      case "active":
        return (
          <LocationsExplorer
            type={activeTab.id}
            key={getTableKey("active-locations-table")}
          />
        );
      // case "available":
      //   return (
      //     <AvailableExplorer
      //       type={activeTab.id}
      //       key={getTableKey("available-hubs-table")}
      //     />
      //   );
      // case "inactive":
      //   return (
      //     <InactiveLocationsExplorer
      //       type={activeTab.id}
      //       key={getTableKey("pending-locations-table")}
      //     />
      //   );
    }
  };

  return (
    <TabLayout
      className="explorer-main-content"
      tabs={tabs}
      onChange={() => {
        clearQueues();
      }}
      render={({ activeTab }) => {
        activeTab?.id && setActiveTab(activeTab?.id);
        return <div className="tab-inner">{renderContent(activeTab)}</div>;
      }}
    />
  );
}
