import React, { Component } from 'react';
import { createPortal } from 'react-dom';

const modalRoot = document.getElementById('modal-wrapper');

export default class Modal extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.el = document.createElement('div');
    this.onKeyPress = this.onKeyPress.bind(this);
    this.container = React.createRef();
  }

  componentDidMount() {
    // The portal element is inserted in the DOM tree after
    // the Modal's children are mounted, meaning that children
    // will be mounted on a detached DOM node. If a child
    // component requires to be attached to the DOM tree
    // immediately when mounted, for example to measure a
    // DOM node, or uses 'autoFocus' in a descendant, add
    // state to Modal and only render the children when Modal
    // is inserted in the DOM tree.
    modalRoot.appendChild(this.el);
    document.addEventListener('keyup', this.onKeyPress);
  }

  componentWillUnmount() {
    modalRoot.removeChild(this.el);
    document.removeEventListener('keyup', this.onKeyPress);
  }

  componentDidUpdate(){
    if(typeof this.props.onUpdate === 'function'){
      this.props.onUpdate(this.state);
    }
  }

  onKeyPress(e){
    if(e.which === 27){
      this.close();
    }
  }

  close(){
    this.setState({
      closed: true
    });
  }

  handleClickBackdrop(){
    const { sticky } = this.props;
    if(
        !sticky 
        && 
        // if there is a focused element in the modal, don't close
        (!document.activeElement || !this.container.current.contains(document.activeElement))
      ){
      this.close();
    }
  }

  render() {
    const { className, render, closeContent, omitClose } = this.props;
    const { closed } = this.state;
    const classes = ['modal'];
    if(closed){
      return null;
    }
    if(className){
      classes.push(className);
    }

    return createPortal(
      (<div
        ref={this.container}
        className={classes.join(' ')} 
        data-testid="modal-backdrop"
        onClick={() => this.handleClickBackdrop()}>
        <div className="modal-wrapper" data-testid="modal-wrapper" onClick={e => e.stopPropagation()}>
          { !omitClose && <div
            onClick={() => this.close()}
            data-testid="close-modal"
            className="close">
            { closeContent }
          </div> }          
          {
            render({ close: this.close.bind(this) })
          }
        </div>
      </div>),
      this.el
    );
  }
}