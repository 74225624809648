import React from 'react';
import Search from './search';
import LocationForm from './location-form';
import BillingForm from './billing-form';
import EnergyProviderValues from './values';

export default function(){
  return <React.Fragment>
    
  <section className="energy-projections-location-summary primary-section">

    <section className="sass-search">
      <section className="inner">
        <Search />
      </section>
    </section>

    <section className="sass-location inset">
      <section className="inner">
        <div className="section-title">
          <h3>Location</h3>
        </div>
        <LocationForm />
      </section>
    </section>

    <section className="sass-billing inset">
      <section className="inner">
        <div className="section-title">
          <h3>Energy Provider</h3>
        </div>
        <BillingForm />
      </section>
    </section>

  </section>

  <EnergyProviderValues />

</React.Fragment>;
}