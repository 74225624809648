import React from 'react';
import { Form, Field } from 'react-final-form';
import queryString from 'query-string';
import CurbInput from '../../../../shared/components/atoms/form/input.jsx';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import AutoSave from '../../forms/autosave';
import URLState from '../../../data-containers/url-state';
import EnergyProjections from '../../../data-containers/energy-projections';
import { hubSerialValidator } from '../../../lib/validators';

const SEARCH_FIELD_NAME = 'q';

export default function(){
  const { history } = URLState.useContainer();
  const { q, loadingInstallation } = EnergyProjections.useContainer();

  const setQueryInURL = q => {
    history.replace({
      search: q 
        ? `?${queryString.stringify({ q })}`
        : ''
    });
  }

  const onSearchSubmit = (values) => {
    const q = values[SEARCH_FIELD_NAME];
    setQueryInURL(q);
  }

  return <Form
    onSubmit={onSearchSubmit}
    initialValues={{
      [SEARCH_FIELD_NAME]: q
    }}
    render={
      (renderProps) => {
        const { handleSubmit, form, values, invalid } = renderProps;
        const q = values[SEARCH_FIELD_NAME];
        return <form 
          className="curb-form energy-projections-search-form" 
          onSubmit={handleSubmit}>
          <label htmlFor={SEARCH_FIELD_NAME}>Search by CURB serial number</label>
          <div className="search-input-wrap">
            <FontAwesomeIcon
              className="icon search-icon"
              htmlFor={SEARCH_FIELD_NAME}
              icon="search" />
            <Field
              autoFocus={true}
              name={SEARCH_FIELD_NAME}
              type="text"
              maxLength={8}
              className="search"
              placeholder="Serial Number"
              autoComplete="off"
              validate={hubSerialValidator}
              render={CurbInput} />
            { values[SEARCH_FIELD_NAME] && (
              <FontAwesomeIcon
                icon="times-circle"
                className="icon search-clear-icon"
                onClick={
                  () => {
                    form.reset();
                    setQueryInURL('');
                  }
                } />
            )}
          </div>
          <AutoSave
            invalid={q && invalid}
            keys={[SEARCH_FIELD_NAME]}
            interval={300}
            save={onSearchSubmit}
            values={values}
            render={() => {
              if(!loadingInstallation){
                return null;
              }
              return <span className="loading-spinner inline search-busy-indicator"></span>;
            }} />
        </form>
      }
    } />
}