import hardwareUtils from '../../shared/utils/hardware';

export const required = value => (value ? undefined : 'Required');
export const number = value => (isNaN(value) ? 'Must be a number' : undefined);
export const minLength = len => v => (v && v.length < len ? `Must be ${len} characters in length` : undefined);

export const hubSerialValidator = v => {
  return hardwareUtils.isValidHubSerial(v)
    ? undefined
    : 'Invalid hub serial';
}