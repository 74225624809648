import React from 'react';
import Modal from '../../../shared/components/atoms/Modal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export default function(props) {
  const { omitSectionWrap, render } = props;
  const closeContent = (<FontAwesomeIcon icon="times" />);

  const renderContent = (modalProps) => {
    let content = <React.Fragment>
      { props.title && <div className="section-title">
        <h3 className="section-title">{props.title}</h3>
      </div> }
      {render({ ...props, ...modalProps })}
    </React.Fragment>;

    if(!omitSectionWrap){
      content = <section className="inset">
        <section className="inner">
          {content}
        </section>
      </section>;
    }
    return content;
  };


  return <Modal
    closeContent={closeContent}
    {...props}
    render={modalProps => renderContent(modalProps)} />;
}
