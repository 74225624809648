export const defaultListState = {
  count: 0,
  results: [],
  next: [],
  loading: null,
  error: null
};

export const initListState = (initialState=defaultListState) => {
  return initialState;
}

export const getListReducer = (options={}) => {
  const { getId=v=>v.id, removePrecursorData=false } = options;
  return function (state={}, { type, id, payload, allowDupes, prepend }){
    switch(type) {
      case 'reset':
        return initListState(payload);
      case 'set':
        return {
          ...state,
          ...payload
        };
      case 'removeResults':
        if(!Array.isArray(payload) || !payload.length){
          return state;
        }
        let newResults = state.results.filter(
          r => !payload.map(getId).includes(getId(r))
        );
        return {
          ...state,
          count: state.count - (state.results.length - newResults.length),
          results: newResults
        };
      case 'addResults':
        let existingResults = state.overwriteOnNextUpdate
          ? []
          : state.results;

        if(removePrecursorData){
          existingResults = existingResults.map(e => {
            const completeData = payload.filter(p => getId(e) === getId(p))[0] || false;
            if(completeData && completeData.name){
              e = {...completeData};
            }
            return e;
          });
        }

        if(!allowDupes){
          payload = payload.filter(
            v => !existingResults.find(existing => getId(existing) === getId(v))
          );
        }

        let results = prepend
          ? payload.concat(existingResults)
          : existingResults.concat(payload);

        return {
          ...state,
          results,
          // if there is no next, results.length should be the true count
          count: state.next.length 
            ? Math.max(0,state.count, results.length)
            : results.length,
          overwriteOnNextUpdate: false
        };
      case 'updateItem':
        return {
          ...state,
          results: state.results.map(
            r => {
              if(r.id === id){
                r = {
                  ...r,
                  ...payload
                };
              }
              return r;
            }
          )
        }
      case 'addNext':
        return {
          ...state,
          next: (state.next || []).concat(payload).filter(r => r)
        };
      case 'passiveSetCount':
        return {
          ...state,
          count: Math.max(0, state.count, payload)
        };
      case 'incrementCount':
        return {
          ...state,
          count: (state.count || 0) + payload
        };
      default:
        throw new Error('Action type is undefined for listReducer');
    }
  };
}

export const simpleListReducer = function (state=[], { type, id, payload }){
  switch(type) {
    case 'reset':
      return [];
    case 'set':
      return payload;
    case 'updateItem':
      return state.map(
        r => {
          if(r.id === id){
            r = {
              ...r,
              ...payload
            };
          }
          return r;
        }
      );
    case 'batchUpdateItems':
      return state.map(
        r => {
          const matchingItem = payload.find(l => l.id === r.id);
          if(matchingItem){
            r = {
              ...r,
              ...matchingItem
            };
          }
          return r;
        }
      )
    default:
      throw new Error('Action type is undefined for simpleListReducer');
  }
};