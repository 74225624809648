import React, { useState } from "react";
// import ExplorerSearch from "./search/index";
// @ts-ignore
import ExplorerMain from "./main/index";
// @ts-ignore
import ExplorerSearchForm from "./search/form";
import AddLocation from "../AddLocation";
import MoveHub from "../MoveHub";
// import FleetByTime from "./fleet-by-time/FleetByTime";
// import Map from "./map/Map";

// @ts-ignore
import FleetSelectionAndTabSummary from "./fleet-selection-tab-summaries/FleetSelectionAndTabSummary";

export default function () {
  const [showModal, setShowModal] = useState(false);
  return (
    <section className="explorer">
      <AddLocation showModal={showModal} setShowModal={setShowModal} />
      <MoveHub />

      {/* <section className="explorer-header primary-section">
        <section className="section-search inset">
          <section className="inner">
            <ExplorerSearch />
          </section>
        </section>
        <section className="section-fleet inset">
          <section className="inner"></section>
        </section>
      </section> */}
      <section className="fleet-selection-and-map-container">
        <section className="fleet-selection-and-tab-summary-section">
          <FleetSelectionAndTabSummary />
        </section>
        {/* <section className="map-container">
          <Map />
        </section> */}
      </section>
      <section className="time-and-search-container">
        <section className="time-select-container">
          <button data-testid="add-location-button" onClick={()=>setShowModal(true)}>Add Location</button>
          {/* <FleetByTime /> */}
        </section>
        <section className="search-container">
          <ExplorerSearchForm />
        </section>
      </section>

      <section className="explorer-main primary-section">
        <section className="inset">
          <section className="inner">
            <ExplorerMain />
          </section>
        </section>
      </section>
    </section>
  );
}
