import { createSlice } from '@reduxjs/toolkit'

import { ILocation } from './interfaces';

export interface ILocations {
    [key:string]: ILocation
}

/** Note about State in Reducers w/ Redux
 *  Redux Toolkit allows us to write "mutating" logic in reducers. It
 *  doesn't actually mutate the state because it uses the immer library,
 *  which detects changes to a "draft state" and produces a brand new
 *  immutable state based off those changes
 * 
 */

const locations:ILocations = {};

export const locationsSlice = createSlice({
  name: 'locations',
  initialState: {
    locations,
    unfilledLocationsCount:0,
  },
  reducers: {
    setLocation(state, action) {
        state.locations[action.payload.uuid] = action.payload;
        state.unfilledLocationsCount = Object.values(state.locations).filter((l:any) => {
          const { users } = l;
          if(!users) return true;
        }).length;
    },
    updateLocation(state, action) {
      state.locations[action.payload.uuid] = { ...state.locations[action.payload.uuid], ...action.payload }
      state.unfilledLocationsCount = Object.values(state.locations).filter((l:any) => {
        const { users } = l;
        if(!users) return true;
      }).length;
    },
    pushLocations(state, action) {
      // for accepting UUIDs of Locations  
      for (let i = 0; i< action.payload.length; i++) {
        const uuid = action.payload[i];
        // @ts-ignore
        state.locations[uuid] = { uuid}
      }
      state.unfilledLocationsCount = Object.values(state.locations).filter((l:any) => {
        const { users } = l;
        if(!users) return true;
      }).length;
    }
  }
})

export const { setLocation, updateLocation, pushLocations } = locationsSlice.actions

export default locationsSlice.reducer

export const getLocations = (state:any):ILocation[] => state.locations.locations;

export const unfilledLocations = (state:any):any[] => 
{
  const ufl = Object.values(state.locations.locations)
  .filter((l:any) => {
    const { users } = l;
    if(!users) return true;
  }).map((l:any) => l);
  return ufl;
}

export const unfilledLocationsCount = (state:any):number => state.locations.unfilledLocationsCount;



