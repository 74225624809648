import mitt from 'mitt';
import { visibilityChangeEvent, documentHidden } from '../../shared/lib/feature-detection';

const eventEmitter = mitt();

document.addEventListener(
  visibilityChangeEvent,
  () => {
    eventEmitter.emit('visibilityChange', documentHidden());
  },
  false
);

export default eventEmitter;
