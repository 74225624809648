import React, { useState, useEffect } from 'react';
import range from 'lodash.range';
import { createContainer } from 'unstated-next';
import URLState from '../data-containers/url-state';
import LocationDetails from '../data-containers/location-details';
import * as API from '../lib/api';
import moment from 'moment';

function useEnergyProjections(l){
  const year = new Date().getFullYear();
  const { fleetId } = URLState.useContainer();
  const { passiveFetchInstallations, getDetail } = LocationDetails.useContainer();
  // eslint-disable-next-line no-unused-vars
  const [ location, setLocation ] = useState(l); 
  const [ installation, setInstallation ] = useState();
  const [ settings, setSettings ] = useState({});
  const [ possibleTariffs, setPossibleTariffs ] = useState([]);
  const [ loadingPossibleTariffs, setLoadingPossibleTariffs ] = useState(null);
  const [ loadingSettings, setLoadingSettings ] = useState(null);

  const { installations=[] } = getDetail(l.id) || {};

  const fetchSettings = async hubSerial => {
    try {
      setLoadingSettings(true);
      const s = await API.getEnergyProjectionsForHub(fleetId, hubSerial);
      setSettings(s);  
    }
    catch(err) {
      if(err.status !== 404){
        throw err;
      }
      setSettings(null);
    }
    setLoadingSettings(false);
  };

  const fetchPossibleTariffs = async (params) => {
    try {
      setLoadingPossibleTariffs(true);
      const results = await API.listTariffs(params);
      setPossibleTariffs(results);
    }
    catch(err){
      console.error(err);
    }
    setLoadingPossibleTariffs(false);
  };

/* 
how energy projections are structured:
```
{
	"2019": {
		"1": {
			"consumption_history": {
				"billing_end_day": "25",
				"used_kwh": 1228.06
			},
			"projected_solar_production": 524.46
		},
		"2": {
			"consumption_history": {
				"billing_end_day": "25",
				"used_kwh": 1073.68
			},
			"projected_solar_production": 375.51
		},
		"12": {
			"consumption_history": {
				"billing_end_day": "25",
				"used_kwh": 1253.49
			},
			"projected_solar_production": 405.08
		}
	}
}
 ```

however, numerical keys on objects are not supported by final-form (https://github.com/final-form/react-final-form/issues/103#issuecomment-356663733)
which honestly makes sense, but it means we have to structure the form data differently
and transform on the way out and in

the form data will be structured as such:

```
{
  year: 2019,
  consumption: [
    {
      "billing_end_day": 25,
      "used_kwh": 1228.06
    },
    {
      "billing_end_day": 25,
      "used_kwh": 1073.68
    },
    {
      "billing_end_day": 25,
      "used_kwh": 1253.49
    }
  ],
  production: [
    {
			"projected_solar_production": 524.46
    },
    {
			"projected_solar_production": 375.51
    },
    {
			"projected_solar_production": 405.08
    }
  ]
}
```

*/
  const getDefaultSettings = (billing) => {
    const values = {};
    const { day_of_month=1 } = billing || {};
    range(1,13).forEach(
      month => {
        values[month.toString()] = {
          consumption_history: {
            billing_end_day: day_of_month,
            used_kwh: ''
          },
          projected_solar_production: ''
        };
      }
    );
    return {
      [year.toString()]: values
    };
  };

  const transformIncoming = energyProjection => {
    const r = {};
    Object.keys(energyProjection).forEach(
      year => {
        year = parseInt(year, 10);
        r.year = year;
        const yearGroup = energyProjection[year];
        if(!Array.isArray(r.consumption)){
          r.consumption = [];
        }
        if(!Array.isArray(r.production)){
          r.production = [];
        }
        Object.keys(yearGroup).forEach(
          month => {
            const monthIndex = parseInt(month, 10) - 1;
            const { consumption_history={}, projected_solar_production } = yearGroup[month];
            const { billing_end_day, used_kwh } = consumption_history;
            const m = moment().year(year).month(monthIndex);
            const d = Math.min(
              m.daysInMonth(),
              parseInt(billing_end_day, 10)
            );
            r.consumption[monthIndex] = {
              billing_end_day: m.date(d),
              used_kwh
            };
            r.production[monthIndex] = {
              projected_solar_production
            };
          }
        )
      }
    );
    return r;
  }

  const transformOutgoing = formValues => {
    var { year, consumption, production } = formValues;
    year = year.toString();
    const r = {
      [year]: {}
    };
    const yearGroup = r[year];
    consumption.forEach(
      (m, i) => {
        const key = (i+1).toString();
        const { billing_end_day, used_kwh } = consumption[i];
        const { projected_solar_production } = production[i];
        yearGroup[key] = {
          consumption_history: {
            billing_end_day: billing_end_day.date().toString(),
            used_kwh
          },
          projected_solar_production
        };
      }
    );

    return r;
  }

  useEffect(
    () => {
      // init
      if(!loadingPossibleTariffs){
        fetchPossibleTariffs({ zipCode: location.postcode });
      }
    },
    [location.postcode]
  );

  useEffect(
    () => {
      //
      if(!location.id){
        passiveFetchInstallations(location.id);
      }
    },
    [location.id]
  );

  useEffect(
    () => {
      if(installations.length){
        setInstallation(installations[0]);
      }
    },
    [installations.length]
  )

  return {
    year,

    settings,
    getDefaultSettings,
    transformIncoming,
    transformOutgoing,
    location,
    installation,

    fetchSettings,
    fetchPossibleTariffs,
    
    possibleTariffs,
    loadingPossibleTariffs,
    loadingSettings
  };
};

export default createContainer(useEnergyProjections);
