import React from 'react';
import LoadingSpinner from '../../atoms/loading-spinner';
import EnergyProjections from '../../../data-containers/energy-projections';

function InstallationsContent(){
  const { installationsList, loadingInstallationsList, loadingInstallation } = EnergyProjections.useContainer();  

  if(loadingInstallation || loadingInstallationsList){
    return <LoadingSpinner className="inline" />
  }

  if(installationsList === null){
    return null;
  }

  return <React.Fragment>
    <div className="count">{installationsList.length} {installationsList.length === 1 ? 'hub' : 'hubs'} found in this location</div>
    <ul className="installation-serials">
      {
        installationsList.map(
          (inst, i) => <li key={i}>{inst.hub}</li>
        )
      }
    </ul>
  </React.Fragment>
}

export default function(props){
  return <div className="installations-list">
    <InstallationsContent {...props} />
  </div>;
}