import React from 'react';
import { Link } from 'react-router-dom';

export default function NotFoundPage(){
  return (
    <div className="basic-page not-found-page">
      <h1>Page not found :(</h1>
      <Link className="button" to="/">Take me to the dashboard &raquo;</Link>
    </div>
  )
}
