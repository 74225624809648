import React, { useState, useEffect } from 'react';
import { slugify } from '../../utils/string';

export default function TabLayout(props){
  let { className, onTabSelect, onTabDeselect, namespace='', tabs=[], render, onChange } = props;
  const [ activeTab, setActiveTab ] = useState(tabs.find(t => t.active) || tabs.filter(t => !t.disabled)[0] || tabs[0]);

  if(typeof render !== 'function'){
    return null;
  }

  tabs = tabs.map(
    (tab, i) => { 
      return {
        id: tab.label ? slugify(tab.label) : `tab-${i}`,
        ...tab,
      };
    }
  )

  const containerClasses = ['tab-layout'];
  if(className){
    containerClasses.push(className);
  }

  useEffect(
    () => {
      if(typeof onTabSelect === 'function'){
        onTabSelect(activeTab);
      }
      return () => {
        if(typeof onTabDeselect === 'function'){
          onTabDeselect(activeTab);
        }
      };
    },
    [activeTab]
  );

  return (
    <div className={containerClasses.join(' ')}>
      <div className="tabs-menu">
        {tabs.map(
          (tab, i) => {
            const content = typeof tab.render === 'function'
              ? tab.render(tab)
              : tab.label;

            const classes = ['tab', `${tab.id}`];

            if(tab.id === activeTab.id){
              classes.push('tab-active');
            }

            return (
              <div
                key={i}
                disabled={tab.disabled}
                onClick={() => {
                  if(activeTab.id !== tab.id && !tab.disabled){
                    setActiveTab(tab);
                    if(typeof onChange === 'function'){
                      onChange(tab, activeTab);
                    }
                  }
                }}
                className={classes.join(' ')}>
                {content}
              </div>
            );
          }
        )}
      </div>
      <section className="tabs-content">
        { render({ activeTab, tabs, setActiveTab }) }
      </section>
    </div>
  );
}
