import React, { useEffect, useLayoutEffect, useRef } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import LoadingSpinner from "../../../../atoms/loading-spinner";
import { IconLink } from "../../../../atoms/links";
import ModalManager from "../../../../atoms/modal-manager";
// import RemoveUserModal from "../../../../modals/remove-user";
import AddUserModal from "../../../../modals/add-user";
// import EditUserModal from "../../../../modals/edit-user";
// import LocationDetails from "../../../../../data-containers/location-details";
// import Fleets from "../../../../../data-containers/fleets";
import DynamicCell from "../../../../atoms/table/dynamic-cell";
import { parsePhoneNumberFromString } from "libphonenumber-js/index.es6.js";

import { useSelector, useDispatch } from "react-redux";
// import { mutexifyAsync, memoize } from '../../../../../lib/utils'

// import { updateLocation, getLocations } from '../../../../../reducers/locations';
import { getDashboard } from "../../../../../reducers/dashboard";

// import { setUser } from '../../../../../reducers/users';

function UsersContent(props) {
  // const { getDetail, passiveFetchUsers, getUsersFiltered } = LocationDetails.useContainer();
  // const { fleetPermissions } = Fleets.useContainer();
  // const { location_can_edit_user, location_can_add_user } = fleetPermissions;
  const usersRef = useRef(null); 
  const location_can_edit_user=false, location_can_add_user = true;
  const { setRowHeight, adjustRowHeight=()=>void(0), rowIndex=0,rows=[], refetchLocation,  } = props;

  // const {rows} = useSelector(getDashboard);
  const detail = rows[rowIndex]||{};


  const { users=[], hubs, opened:expanded=false } = detail;
  let usersLoading = true;
  if(hubs && Array.isArray(hubs)){
    usersLoading = false;
  }
  // const users = getUsersFiltered(id);

  const listClasses = ["explorer-list user-list list"];
  if (expanded) {
    listClasses.push("expanded-list");
  }
  if (users.length > 1) {
    listClasses.push("multi-user");
  }


  useLayoutEffect(() => {
    if(usersRef.current) {
      if(expanded) {
        setRowHeight(rowIndex, usersRef.current.clientHeight);
      }
    }
    if(!expanded) {
      setRowHeight(rowIndex, 30);
    }
  }, [usersRef]);

  if (usersLoading) {
    return <div style={{width:"100%"}}></div>
    // return <LoadingSpinner className="inline" />;
  }

  const getRemoveUserModalProps = (u) => {
    return {
      className: "remove-user-modal",
      render: (modalProps) => (
        <p>Remove User Modal</p>
        // <RemoveUserModal u={u} location={rowData} {...modalProps} />
      ),
    };
  };

  const addUserModalProps = {
    className: "add-user-modal",
    // render: (modalProps) => <div>Add User Modal</div>,
    render: (modalProps) => <AddUserModal refetchLocation={refetchLocation} location={detail} {...modalProps} />,
  };

  const getEditUserModalProps = (u) => {
    return {
      className: "edit-user-modal",
      title: "Edit User Info",
      render: (modalProps) => (
        <p>Edit User modal</p>
        // <EditUserModal u={u} location={rowData} {...modalProps} />
      ),
    };
  };

  const formatPhoneNumber = (num) => {
    try {
      return parsePhoneNumberFromString(num).formatNational();
    } catch (err) {
      return num;
    }
  };
  return (
    <div ref={usersRef}>
      {users.length ? (
        <div 
          
          style={{height:expanded?"auto":"16px",overflow:expanded?"visible":"hidden"}}
          className={listClasses.join(" ")}>
          {users.map((user, i) => {
            const name = user.name || user.email_address || "Name and Email not found";
              // u.user && u.user.name
              //   ? u.user.name
              //   : u.auth0User
              //   ? u.auth0User.name
              //   : "Name not found";
            return (
              <div className="explorer-item user-item list-item" key={i}>
                {expanded && users.length > 1 && location_can_edit_user && (
                  <ModalManager
                    modalProps={getRemoveUserModalProps(u)}
                    render={() => (
                      <a className="round-icon icon-link remove-user">
                        <FontAwesomeIcon icon="minus" />
                      </a>
                    )}
                  />
                )}
                <div className="user-row-label">
                  <span className="user-label">{name}</span>
                  {/* {expanded && location_can_edit_user && (
                    <ModalManager
                      modalProps={getEditUserModalProps(u)}
                      render={() => (
                        <span className="linked-icon edit-user-link">
                          <FontAwesomeIcon icon="pencil-alt" />
                        </span>
                      )}
                    />
                  )} */}
                </div>
                {expanded && (
                  <div className="row-expanded-section">
                    {name!==user.email_address && (<div className="email">{user.email_address}</div>)}
                    {user.phone_number && (
                      <div className="phone">
                        {formatPhoneNumber(user.phone_number)}
                      </div>
                    )}
                  </div>
                )}
              </div>
            );
          })}
        </div>
      ) : (
        <>{!usersLoading && <div className="empty">No users found</div>}</>
      )}
      {expanded && location_can_add_user && (
        <ModalManager
          modalProps={addUserModalProps}
          render={() => (
            <IconLink icon="plus" data-testid={`add-user-${rowIndex}`} className="add-user">
              Add User
            </IconLink>
          )}
        />
      )}

    </div>
  );
}

function Wrapper(props) {
  return (
    <div className="location-row-users">
      <UsersContent {...props} />
    </div>
  );
}

export default Wrapper;
// export default DynamicCell(Wrapper);
