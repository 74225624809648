import React, { useState, useEffect, useRef, useReducer } from "react";
import { createContainer } from "unstated-next";
import escapeRegExp from "lodash.escaperegexp";
import URLState from "./url-state";
import * as API from "../lib/api";
import { APICall } from "../lib/api-utils";
import { isValidHubSerial } from "../../shared/utils/hardware";



import {
  defaultListState,
  initListState,
  getListReducer,
  simpleListReducer,
} from "../lib/reducers";



import statesKeyValuePairs from "../../shared/lib/us-states.json";
import { mutexifyAsync } from "../lib/utils";
import { clearQueues } from "../../shared/lib/api-queue";

const states = Object.entries(statesKeyValuePairs);

const LOCATION_SEARCH_FIELDS = [
  "label",
  "address",
  "city",
  "state",
  "postcode",
];
const USER_SEARCH_FIELDS = ["email_address", "name"];

function useExplorer() {
  const getInstallationId = (inst) => inst.hub;
  const [activeTab, setActiveTab] = useState("");

  // general
  const { fleetId, includeAllSubfleets } = URLState.useContainer();

  // search
  const timeseriesRequestParams = { describe_intervals: true, include_totals: true, omit_registers: true, metrics: 'kwh', page_size: 1000 };
  const possibleDateRanges = [
    { 
      label: 'All time',
      id: 'all-time',
      aggregateParams: { from_now_range: '99y', include_totals: true } 
    },
    { 
      label: 'Yesterday',
      id: 'yesterday',
      isDefault: true,
      timeseriesParams: { from_now_range: '48h', interval_size: '24h', ...timeseriesRequestParams }
    },
    { 
      label: 'Last week',
      id: 'last-week',
      isDefault: true,
      timeseriesParams: { from_now_range: '14d', interval_size: '7d', ...timeseriesRequestParams }
    },
    { 
      label: 'Last month',
      id: 'last-month',
      isDefault: true,
      timeseriesParams: { from_now_range: '60d', interval_size: '30d', ...timeseriesRequestParams }
    },
    { 
      label: 'Last quarter',
      id: 'last-quarter',
      isDefault: true,
      timeseriesParams: { from_now_range: '180d', interval_size: '90d', ...timeseriesRequestParams }
    },
    { 
      label: 'Last year',
      id: 'last-year',
      isDefault: true,
      timeseriesParams: { from_now_range: '2y', interval_size: '1y', ...timeseriesRequestParams }
    }
  ];
  const [searchTerm, setSearchTerm] = useState("");
  const [dateRange, setDateRange] = useState(possibleDateRanges.find(({isDefault}) => isDefault));
  const [searchLoading, setSearchLoading] = useState(null);

  /* 
  ALL RESULTS (completely isolated from search results) 
  */

  // general installation properties
  const [totalsLoading, setTotalsLoading] = useState(null);
  const [totalsError, setTotalsError] = useState(null);

  // inactive locations
  const allInactiveLocationsReducer = useReducer(
    getListReducer(),
    defaultListState,
    initListState
  );
  const [allInactiveLocations, allInactiveLocationsDispatch] = allInactiveLocationsReducer;

  // available (not installed) hubs
  const allAvailableHubsReducer = useReducer(
    getListReducer({ getId: getInstallationId }),
    defaultListState,
    initListState
  );
  const [allAvailableHubs, allAvailableHubsDispatch] = allAvailableHubsReducer;

  // active locations
  const allActiveLocationsReducer = useReducer(
    getListReducer(),
    defaultListState,
    initListState
  );
  const [allActiveLocations, allActiveLocationsDispatch] = allActiveLocationsReducer;

  /* 
  SEARCH RESULTS 
  */

  // active locations
  const searchActiveLocationsReducer = useReducer(
    getListReducer(),
    defaultListState,
    initListState
  );
  const [searchActiveLocations, searchActiveLocationsDispatch] = searchActiveLocationsReducer;

  // inactive locations
  const searchInactiveLocationsReducer = useReducer(
    getListReducer(),
    defaultListState,
    initListState
  );
  const [searchInactiveLocations, searchInactiveLocationsDispatch] = searchInactiveLocationsReducer;

  // search available (not installed) hubs
  const searchAvailableHubsReducer = useReducer(
    getListReducer({ getId: getInstallationId }),
    defaultListState,
    initListState
  );
  const [searchAvailableHubs, searchAvailableHubsDispatch] = searchAvailableHubsReducer;

  const [selectedMapLocation, setSelectedMapLocation] = useState({
    id: "",
    index: undefined,
  });

  // this ref is used as an instance variable - https://reactjs.org/docs/hooks-faq.html#is-there-something-like-instance-variables
  // because our useEffect hook makes several API calls, they have to commit
  const temp = useRef(null);

  const getActiveLocations = () =>
    searchTerm ? searchActiveLocations : allActiveLocations;
  const getActiveLocationsDispatch = () =>
    searchTerm ? searchActiveLocationsDispatch : allActiveLocationsDispatch;
  const getInactiveLocations = () =>
    searchTerm ? searchInactiveLocations : allInactiveLocations;
  const getInactiveLocationsDispatch = () =>
    searchTerm ? searchInactiveLocationsDispatch : allInactiveLocationsDispatch;

  const getAvailableHubs = () =>
    searchTerm ? searchAvailableHubs : allAvailableHubs;
  const getAvailableHubsDispatch = () =>
    searchTerm ? searchAvailableHubsDispatch : allAvailableHubsDispatch;

  const getExplorerItems = (tab=activeTab) => {
    switch(tab){
      case "active":
        return getActiveLocations();
      case "inactive":
        return getInactiveLocations();
      default:
        return getAvailableHubs();
    }
  }

  const receiveListResponse = ({ count=0, next=null, results=[] }, dispatch) => {
    if(queryIsStillRelevant()){
      if(next && !Array.isArray(next)){
        next = [next];
      }
      dispatch({
        type: "passiveSetCount",
        payload: count
      })
      dispatch({
        type: "addNext",
        payload: next,
      });
            
      dispatch({
        type: "addResults",
        payload: results,
      });  
      dispatch({
        type: "set",
        payload: {
          loading: false
        }
      })
    }
  };

  function queryIsStillRelevant(){
    const { current } = temp;
    return (
      current.searchTerm === searchTerm &&
      current.fleetId === fleetId &&
      current.includeAllSubfleets === includeAllSubfleets
    );
  }

  const fetchTotals = async () => {
    try {
      allActiveLocationsDispatch({
        type: "set",
        payload: {
          loading: true,
          error: null,
        },
      });
      setTotalsError(null);
      setTotalsLoading(true);
      await Promise.all([
        (async function fetchActive(){
          const response = await API.searchFleet(fleetId, 'location', { includeAllSubfleets, installation__state: "active" });
          receiveListResponse(response, allActiveLocationsDispatch);
        })(),
        (async function fetchInActive(){
          const response = await API.searchFleet(fleetId, 'location', { includeAllSubfleets, installation__state__in: "in_process,pending" });
          receiveListResponse(response, allInactiveLocationsDispatch);
        })(),
        (async function fetchAvailable(){
          const response = await API.searchFleet(fleetId, "installation", { includeAllSubfleets, location__isnull: 1 }, { type: "installation_hub_expanded" });
          receiveListResponse(response, allAvailableHubsDispatch);
        })()
      ]);

    } catch (err) {
      console.error(err);
      setTotalsError(err);
    }
    setTotalsLoading(false);
  };

  const fetchNext = async ({next=[], results, loading}, dispatch, handleResponse) => {
    if (!next || !next.length || loading) {
      return;
    }

    // set loading to true and set 
    dispatch({
      type: "set",
      payload: {
        next: [],
        loading: true,
      }
    });

    await Promise.all(
      next.map(async (n) => {
        const response = await APICall({ url: n });
        if(typeof handleResponse === 'function'){
          return handleResponse({response, url: n});
        }
        receiveListResponse(response, dispatch);
      })
    );
  }

  const getLocationSearchConfigs = (q, params={}, installationParams={}) => {
    if (!q) {
      return;
    }
    const configs = [
      ...LOCATION_SEARCH_FIELDS.map((field) => {
        let queryParam = { [`${field}__icontains`]: q };

        // for state searching, allow matching either on the abbreviation or the state name
        if(field === "state"){
          let matchingStates = states.filter(([abbr, name]) => abbr.toLowerCase() === q || new RegExp(q, 'ig').test(name));
          if(matchingStates.length){
            queryParam = { 'state__in': matchingStates.map(([abbr]) => abbr).join(',') };
          }
        }

        return {
          model: "location",
          params: {...queryParam, ...params, includeAllSubfleets },
        };
      })
    ];

    if (isValidHubSerial(q)) {
      configs.push({
        model: "installation",
        params: {
          hub: q.toLowerCase(),
          location__isnull: 0,
          ...installationParams,
          includeAllSubfleets,
        },
        type: "location",
      });
    }

    return configs;
  };

  const getUserAccessSearchConfigs = (q, params={}) => USER_SEARCH_FIELDS.map(
    field => ({
      model: "user_access",
      params: { [`user__${field}__icontains`]: q, location__isnull: 0, ...params, includeAllSubfleets },
      type: "location_expanded"      
    })
  );

  const fetchNextAvailableHubs = () => searchTerm
    ? fetchNextSearch(...searchAvailbleHubsReducer)
    : fetchNext(...allAvailableHubsReducer);
  const fetchNextActiveLocations = () => searchTerm
    ? fetchNextSearch(...searchActiveLocationsReducer)
    : fetchNext(...allActiveLocationsReducer);
  const fetchNextInactiveLocations = () => searchTerm
    ? fetchNextSearch(...searchInactiveLocationsReducer)
    : fetchNext(...allInactiveLocationsReducer);

  // user locations can not be queried from the API directly, so the client is responsible for 
  // determining which results are active/inactive based on the returned embedded installations
  const receiveUserLocationsSearchResponse = locationResponse => {
    const active = [];
    const inactive = [];
    for(const result of locationResponse.results){      
      if(result?.installations?.find(inst => ["in_process", "pending"].includes(inst.state))){
        inactive.push(result);
      }
      else {
        active.push(result);
      }
    }
    receiveListResponse(
      { 
        count: locationResponse.count - inactive.length, 
        next: locationResponse.next,
        results: active
      }, 
      searchActiveLocationsDispatch
    );
    receiveListResponse(
      { 
        count: inactive.length, 
        next: locationResponse.next,
        results: inactive
      }, 
      searchInactiveLocationsDispatch
    );    
  }

  const fetchSearch = async (q) => {
    if (queryIsStillRelevant()) {
      searchActiveLocationsDispatch({ type: "reset" });
      searchInactiveLocationsDispatch({ type: "reset" });
      searchAvailableHubsDispatch({
        type: "reset",
        payload: {
          overwriteOnNextUpdate: true,
          next: [],
          count: 0
        },
      });
    }

    let locationsRequests = [
      ...getLocationSearchConfigs(q, { installation__state: "active" }, { state: "active" }).map(
        async ({ model, params, type }) => {
          const locationResponse = await API.searchFleet(fleetId, model, params, { type });
          receiveListResponse(locationResponse, searchActiveLocationsDispatch);
        }
      ),
      ...getLocationSearchConfigs(q, { installation__state__in: "in_process,pending" }, { state__in: "in_process,pending"}).map(
        async ({ model, params, type }) => {
          const locationResponse = await API.searchFleet(fleetId, model, params, { type });
          receiveListResponse(locationResponse, searchInactiveLocationsDispatch);
        }        
      ),
      ...getUserAccessSearchConfigs(q).map(
        async ({ model, params, type }) => {
          // because searching user locations 
          const locationResponse = await API.searchFleet(fleetId, model, params, { type });
          receiveUserLocationsSearchResponse(locationResponse);
        }        
      )
    ];

    let availableHubsRequests = [];
    // if search query looks like it could be a hub serial, search installations
    // both for available and active
    if (isValidHubSerial(searchTerm)) {
      // search for available hubs by serial
      availableHubsRequests = [
        (async function () {
          const installationResponse = await API.searchFleet(
            fleetId,
            "installation",
            { hub: q.toLowerCase(), location__isnull: 1, includeAllSubfleets },
            { type: "installation_hub_expanded" }
          );
          receiveListResponse(installationResponse, searchAvailableHubsDispatch);
        })(),
      ];
    }

    setSearchLoading(true);
    await Promise.all([
      // location requests
      Promise.all(locationsRequests),
      // available hubs requests
      Promise.all(availableHubsRequests)
    ]);
    setSearchLoading(false);
  };

  const fetchNextSearch = async (state, dispatch) => {
    return await fetchNext(state, dispatch, function handleResponse({response, url}){
      // user location searches have to be filtered on the client to determine which are active/inactive
      if(/\/user_access\/location_expanded/ig.test(url)){
        return receiveUserLocationsSearchResponse(response);
      }
      receiveListResponse(response, dispatch);
    });
  }

  const updateLocation = async (id, l, options = {}) => {
    const { local } = options;
    if (!local) {
      l = await API.updateLocation(fleetId, id, l);
    }

    [
      allActiveLocationsDispatch,
      searchActiveLocationsDispatch
    ].forEach((dispatch) =>
      dispatch({
        type: "updateItem",
        id,
        payload: l,
      })
    );

    return l;
  };

  const updateLocationDetail = (id, detail) => {
    [
      allActiveLocationsDispatch,
      searchActiveLocationsDispatch
    ].forEach((dispatch) =>
      dispatch({
        type: "updateItem",
        id,
        payload: {
          detail,
        },
      })
    );

    return detail;
  };

  const fetchLocation = async (id) => {
    try {
      updateLocation(id, { loading: true, error: null }, { local: true });
      const location = await API.getLocation(fleetId, id);
      updateLocation(id, location, { local: true });
    } catch (error) {
      updateLocation(id, { error }, { local: true });
    }
    updateLocation(id, { loading: false }, { local: true });
  };

  const removeLocationFromInactive = (id) => {
    allInactiveLocationsDispatch({
      type: "removeResults",
      payload: [{id}]
    });
  };

  const addLocationToActive = (location) => {
    allActiveLocationsDispatch({
      type: "addResults",
      payload: [location]
    });
  }

  const resetAll = () => {
    clearQueues();
    allActiveLocationsDispatch({ type: "reset" });
    allInactiveLocationsDispatch({ type: "reset" });
    allAvailableHubsDispatch({ type: "reset" });

    searchActiveLocationsDispatch({ type: "reset" });
    searchInactiveLocationsDispatch({ type: "reset" });
    searchAvailableHubsDispatch({ type: "reset" });

    setSearchTerm("");
  };

  // run search when searchTerm changes
  useEffect(() => {
    temp.current = temp.current || {};
    temp.current.searchTerm = searchTerm;
    clearQueues();
    if (searchTerm) {
      fetchSearch(searchTerm);
    }
  }, [searchTerm]);

  // on mount or when provider changes, fetch totals
  useEffect(() => {
    temp.current = temp.current || {};
    temp.current.fleetId = fleetId;
    temp.current.includeAllSubfleets = includeAllSubfleets;
    resetAll();
    window.requestAnimationFrame(fetchTotals);
  }, [fleetId, includeAllSubfleets]);

  return {
    possibleDateRanges,
    dateRange,
    setDateRange,
    searchTerm,
    setSearchTerm,
    getActiveLocations,
    getActiveLocationsDispatch,
    getAvailableHubs,
    getAvailableHubsDispatch,
    getInactiveLocations,
    getInactiveLocationsDispatch,

    getLocationSearchConfigs,
    fetchNextAvailableHubs,
    fetchNextActiveLocations,
    fetchNextInactiveLocations,
    removeLocationFromInactive,
    addLocationToActive,
    updateLocation,
    updateLocationDetail,
    passiveFetchLocation: mutexifyAsync(fetchLocation, "location"),

    /* 
    TOTALS 
    */
    totalsLoading,
    totalsError,
    
    allActiveLocations,
    allInactiveLocations,
    allAvailableHubs,

    /* 
    SEARCH RESULTS 
    */
    searchLoading,
    searchActiveLocations, searchActiveLocationsDispatch,
    searchInactiveLocations, searchInactiveLocationsDispatch,

    // available
    searchAvailableHubs, searchAvailableHubsDispatch,

    // active tab
    activeTab,
    setActiveTab,

    getExplorerItems,

    // selected map location (for map view)
    selectedMapLocation,
    setSelectedMapLocation,
  };
}

export default createContainer(useExplorer);
