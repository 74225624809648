import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector, useContext } from 'react-redux';

// import Explorer from '../../data-containers/explorer';
// import LocationDetails from '../../data-containers/location-details';
import FleetExplorer from '../explorer/fleet';
// @ts-ignore
import withPage from '.';
// import { setFleets, setFleet, setError, setLoading } from '../../reducers/fleet';
// import { setUser, setError as setUserError, setLoading as setUserLoading } from '../../reducers/user';
// @ts-ignore
import * as API from '../../lib/api';
// @ts-ignore
import LoadingSpinner from '../atoms/loading-spinner';

import DashboardContext from '../explorer/context';



/** 
 *  Being a dashboard, this is a good place
 *  to handle Dashbaord wide data calls. 
 **/


function DashboardPage(props){
  
  const [searchTerm, updateSearchTerm] = useState('');
  const setSearchTerm = (term) => {
    console.log("[NEW STATE] updating search term", term);
    updateSearchTerm(term);
  }

  const loading = useSelector((state) => state.fleet.loading);
  const error = useSelector((state) => state.fleet.error);
  const loadingUser = useSelector((state) => state.user.loading);
  const errorUser = useSelector((state) => state.user.error);

  if (error||errorUser) {
    return <div>{error}{errorUser}</div>;
  }

  if (loading||loadingUser) {
    return (<LoadingSpinner />)
  }

  return (
    <DashboardContext.Provider value={{searchTerm, setSearchTerm}}>
      <FleetExplorer />
    </DashboardContext.Provider>
    // <Explorer.Provider>
    //   <LocationDetails.Provider>
        // <FleetExplorer />
    //   </LocationDetails.Provider>
    // </Explorer.Provider>
  )
}

export default withPage(DashboardPage);
