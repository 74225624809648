import React, { useEffect, useState, useRef } from 'react';
// import LocationDetails from '../../../../../data-containers/location-details';
// import ErrorWithTooltip from '../../../../atoms/error-with-tooltip';
import Select from "react-select";
import { customStyles, DropdownIndicator } from "../../../../../constants";

// import DynamicCell from '../../../../atoms/table/dynamic-cell';
import FleetLink from '../../../../atoms/fleet-link';
import useAutoCloseOutSideClick from "../../../../atoms/useAutoCloseOutSideClick";


import { useSelector } from 'react-redux';
// import { getDashboard } from '../../../../../reducers/dashboard';
import { getFleets, findFleetByUuid } from '../../../../../reducers/fleet';
import * as api from '../../../../../lib/api';
import LoadingSpinner from '../../../../atoms/loading-spinner';



function FleetNavModal(props) {
  const includeAllSubfleets = true;
  const { location, currentFleet, nestedFleets, setExpandEditFleet, setUpdating, rowIndex } = props;


  let index = 0;
  const renderFleetLinks = (fleet) => {
    const { children = [] } = fleet;
    const hasChildren = !!children.length;
    const isCurb = fleet.id === "curb";

    const className = () => {
      const classes = [`fleet-item-${fleet.id}-${index}`, 'fleet-item-table'];
      if(hasChildren){
        classes.push("parent")
      }
      if (fleet.id === currentFleet.id && !isCurb) {
        classes.push("active");
      }
      return classes.join(" ");
    };
    const assignLocation = async (fleet) => {
      setUpdating(true);
      await api.updateLocationFleet(fleet.id, location.uuid);
      location.fleet = fleet.uuid;
      setUpdating(false);
    };

    return (
      <li key={`${fleet.id}_${index++}`} className={className()}>
      {
        isCurb
          ? <a data-testid={`location-fleet-select-${fleet.id}-${rowIndex}`} href={void(0)} onClick={() => assignLocation(fleet)}>{fleet.id} </a>//<small>(and subfleets)</small>
          : <a data-testid={`location-fleet-select-${fleet.id}-${rowIndex}`} href={void(0)} onClick={()=> assignLocation(fleet)}>{fleet.id}</a>
      }
      {
        hasChildren && <ul>
          {children.map((f) => renderFleetLinks(f))}
        </ul>
      }
    </li>
);
  };

  return <ul className="fleet-nav-list top-level">
    {nestedFleets.map((fleet) => renderFleetLinks(fleet))}
  </ul>;
}




// we are disabling the following line of code until we implement the v4 architecture fall 2022
// FleetNavModal = withRouter(FleetNavModal);

const FleetNav = function ({currentFleet, nestedFleets, location, setExpandEditFleet, setUpdating, rowIndex}) {
  // const { includeAllSubfleets } = URLState.useContainer();
  // const currentFleet = useSelector(getFleet);
  // const nestedFleets = useSelector(getFleets);
  // const { currentFleet, nestedFleets } = Fleets.useContainer();
  if (!currentFleet) {
    return <></>;
    // return null;
  }

  return (
    <nav>
      <FleetNavModal
        nestedFleets={nestedFleets}
        currentFleet={currentFleet}
        location={location}
        rowIndex={rowIndex}
        setExpandEditFleet={setExpandEditFleet}
        setUpdating={setUpdating}
      />
    </nav>
  );
};


const FleetSelection = ({location, currentFleet, fleets, setExpandEditFleet, updating, changeUpdating, rowIndex}) => {
  // const currentFleet = useSelector(getFleet);
  const { isOpen, setIsOpen, selectRef } = useAutoCloseOutSideClick(false);

  const setUpdating = (val) => {
    // if(val) setIsOpen(false);
    changeUpdating(val);
  }
  if(updating){
    return <LoadingSpinner className="inline" />;
  }

  return (
    <>
        <div className="fleet-selection-and-map-container" style={{width:"100%", height: "auto", marginTop:0}}>
        <div className="fleet-selection-and-tab-summary-section no-border" style={{width:"95%",marginTop:"0.1rm", height: "auto", border: "none"}}>
          <div className="fleet-selection-and-tab-summary-container  no-border">

      <div className="fleet-selection-container" style={{padding:0}} >
        <div className="dropdown-container" style={{background:"#FFFFFF"}}>
          <div
            className="dropdown-label-container"
            data-testid={`location-fleet-selection-container-${rowIndex}`}
            onClick={() => setIsOpen((prev) => !prev)}
          >
            <div
              className="dropdown-select"
              data-testid="fleet-selection"
            >
              <Select
                styles={customStyles({ hidePlaceholder: true })}
                components={{ DropdownIndicator }}
                menuIsOpen={false}
              />
            </div>
            <div
              className="fleet-label"
              data-testid={`location-fleet-selection-label-${rowIndex}`}
              style={{
                transform: "translateY(-30px)",
                fontSize: "1rem",
                textTransform: "uppercase",
              }}
            >
              {currentFleet?.id}
              {!currentFleet?.id && "Fleets not available"}
            </div>
          </div>
          <div
            className={"dropdown-content"}
            style={{ display: isOpen ? "block" : "none" }}
            ref={selectRef}
          >
            <FleetNav
              currentFleet={currentFleet}
              nestedFleets={fleets}
              location={location}
              setExpandEditFleet={setExpandEditFleet}
              rowIndex={rowIndex}
              setUpdating={setUpdating}
            />
          </div>
        </div>
      </div>
      </div> </div> </div>
    </>
  );
}

function FleetContent(props){
  const [updating, updateUpdating] = useState(false);
  const [expandEditFleet, setExpandEditFleet] = useState(false);
  const fleetRef = useRef(null);
  const fleets = useSelector(getFleets);
  const { adjustRowHeight, rowIndex, rows, updateFleetEditorOpen, fleetEditorOpen } = props; // rowData, omitAddToActive,
  const changeUpdating = (val) => {
    if(!val) {
      setExpandEditFleet(!expandEditFleet);
      updateFleetEditorOpen([...fleetEditorOpen.filter((val)=>val!==rowIndex)])
    }
    updateUpdating(val);
  }
  const detail = rows[rowIndex] || {}
  const { fleet="", opened=false, label="" } = detail;

  useEffect(() => {
    if (fleetRef.current) {
      if(expandEditFleet){
        adjustRowHeight(rowIndex, fleetRef.current.clientHeight);
      }
      adjustRowHeight(rowIndex, fleetRef.current.clientHeight);
    }
    // eslint-disable-next-line
  }, [fleetRef.current?fleetRef.current.clientHeight :null]);


  const subFleet = findFleetByUuid(fleets, fleet)||{};
  if(!subFleet.id){
    return <div style={{width:"100%"}}></div>;
  }
  if(opened && (!label || (label && !label.includes("manufacturing"))) ) {
    return (<div ref={fleetRef} className="no-list" style={{paddingRight:"2px"}}>
        {!expandEditFleet && <div style={{display:"inlne-block", maxWidth: "95%", overflow:"hidden"}}><FleetLink fleetId={subFleet.id} /><br /></div>}
        {expandEditFleet &&
          <FleetSelection
            updating={updating}
            changeUpdating={changeUpdating}
            rowIndex={rowIndex}
            setExpandEditFleet={setExpandEditFleet}
            location={detail}
            currentFleet={subFleet}
            fleets={fleets} />}

        {!expandEditFleet &&
          <small><button
            style={{minWidth:0, fontSize: "6px",padding: "4px"}}
            data-testid={`open-location-fleet-select-${subFleet.id}-${rowIndex}`}
            onClick={
              ()=>{
                setExpandEditFleet(!expandEditFleet);
                updateFleetEditorOpen([...fleetEditorOpen, rowIndex])
                }}>change</button></small>}
        {expandEditFleet &&
          <small><button
            data-testid={`close-location-fleet-select-${subFleet.id}-${rowIndex}`}
            style={{minWidth:0, fontSize: "6px",padding: "4px"}}
            onClick={()=>{
              setExpandEditFleet(!expandEditFleet);
              updateFleetEditorOpen([...fleetEditorOpen.filter((val)=>val!==rowIndex)])
              }}>close</button></small>}
      </div>
      );
  }

  return (<div style={{display:"inlne-block", maxWidth: "95%", overflow:"hidden"}}><FleetLink fleetId={subFleet.id} /></div>);
}

function Wrapper(props){
  return <div className="location-row-fleet">
    <FleetContent { ...props } />
  </div>;
}

export default Wrapper;
// export default DynamicCell(Wrapper);
