import React from 'react';

export function ErrorContents({error, pretty}){
  if(!error){
    return null;
  }
  let {status=NaN, message={}} = error;
  if(typeof message === "string"){
    message = error.message
      ? (typeof error.message === 'string')
        ? error.message
        : "there was an error fetching this locations data"
      : null;
  }
  if(typeof message === "object" && status === 400) {
    message = message.message || 'There was an error'
  }
  if(pretty){
    message = <pre>{message}</pre>;
  }
  
  if(error instanceof Error){
    return <React.Fragment>
      { message && <div className="message">{message}</div> }
      { error.stack && <div className="stack">{error.stack}</div> }
    </React.Fragment>
  }

  if(error.status){
    return <React.Fragment>
      <div className="status">{error.status}</div>
      { message && <div className="message">{message}</div> }
      { error.stack && <div className="stack">{error.stack}</div> }
    </React.Fragment>
  }
  return 'there was an error';
}

export default function PrintError(props){
  const {error, className} = props;
  if(!error){
    return null;
  }
  const classes = ['error'];
  if(className){
    classes.push(className);
  }
  return <div className={classes.join(' ')}>
    <ErrorContents {...props} />
  </div>;
}