/* 
this function accepts an async function that can not be invoked again with the same arguments before it has finished
the arguments are serialized and put in a set while the async is running, and removed when it is done running
*/
export const mutexifyAsync = function(fn){
  const temp = new Set();
  return async function(){
    const key = `${JSON.stringify(arguments)}`;
    // the function has already been called with these arguments, exit
    if(temp.has(key)){
      return;
    }
    temp.add(key);
    let r;
    try {
      r = await fn.apply(null, arguments);      
    }
    catch(e){
      // if the function throws an error, then be sure to delete the key before rethrowing
      temp.delete(key);
      throw e;
    }
    temp.delete(key);
    return r;
  }
};

/*
this function accepts a function and will return a cached result
even if the passed in function is an async function, this will cache 
the promise returned by that async function, so subsequent calls will
return that promise, not reinvoke the original function
*/
export function memoize(fn){
  const cache = {};
  return async function memoizedFunction(){
    const cacheKey = JSON.stringify(arguments);
    if(!cache[cacheKey]){
      cache[cacheKey] = fn.apply(null, arguments);
      cache[cacheKey].catch(
        err => {
          cache[cacheKey] = undefined;
          throw err;
        }
      )  
    }
    return cache[cacheKey];
  }
}