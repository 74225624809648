import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export default function({type, icon=null, children}){
  const classes = ['status-inline'];
  if(type){
    classes.push(type);
  }
  
  if(icon === null){
    switch(type){
      case 'error':
      case 'warning':
        icon = 'exclamation-circle';
        break;
      case 'success':
        icon = 'check';
    }  
  }
  return <div className={classes.join(' ')}>
    { icon && <FontAwesomeIcon icon={icon} style={{marginRight: '0.25rem'}} /> }
    <div className="status-inline-content">{ children }</div>    
  </div>
}