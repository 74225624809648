export default {
  _values: {
    // hardcode values here
    DEMO: false,
    DEMO_LOCATION_ID: '1c76f308-e1b6-4cae-abb8-34ba5c1dc5f9',
    DEMO_UTILITY_NAMES: [
      'Pacific Gas and Electric',
      'Austin Energy',
      'Florida Power and Light',
      'Duke Energy Carolinas',
      'Dominion Energy',
      'Georgia Power',
      'Consumers Energy',
      'Consolidated Edison'
    ]
  },
  get values(){
    return this._values;
  },
  set values(params){
    this._values = {
      ...this._values,
      ...params
    };
    return this._values;
  }
};
